import * as React from "react";
import { ExpandableFormSection, Summary, FormSectionHeading } from "components/form";
import Text from "primitiveComponents/form/Text/Text";
import { AccountType, UsernamePasswordAccountResource } from "client/resources/accountResource";
import AccountEditBase, { AccountEditModel } from "./AccountEditBase";
import { SensitiveValue } from "client/resources/propertyValueResource";
import Sensitive, { ObfuscatedPlaceholder } from "components/form/Sensitive/Sensitive";

interface UsernamePaswordModel extends AccountEditModel {
    username: string;
    password: SensitiveValue;
}

class UsernamePasswordAccountEdit extends AccountEditBase<UsernamePasswordAccountResource, UsernamePaswordModel> {
    getPartialModel(account?: UsernamePasswordAccountResource): Partial<UsernamePaswordModel> {
        if (!account) {
            return {
                username: "",
                password: {
                    HasValue: false,
                },
            };
        }

        return {
            username: account.Username,
            password: account.Password,
        };
    }

    getPartialResource(): Partial<UsernamePasswordAccountResource> & { AccountType: AccountType } {
        return {
            AccountType: AccountType.UsernamePassword,
            Username: this.state.model.username,
            Password: this.state.model.password,
        };
    }

    usernameSummary() {
        return this.state.model.username ? Summary.summary(this.state.model.username) : Summary.placeholder("No username provided");
    }

    passwordSummary() {
        return this.state.model.password && this.state.model.password.HasValue ? Summary.summary(ObfuscatedPlaceholder) : Summary.placeholder("No password provided");
    }

    customSecondaryAction(): React.ReactElement | null {
        return null;
    }

    customExpandableFormSections(): React.ReactElement[] {
        const baseElements: React.ReactElement[] = [];

        baseElements.push(
            <FormSectionHeading title="Credentials" key={"header"} />,
            <ExpandableFormSection key="username" errorKey="username" title="Username" summary={this.usernameSummary()} help="The username to use when authenticating against the remote host.">
                <Text value={this.state.model.username} onChange={(username) => this.setModelState({ username })} label="Username" error={this.getFieldError("Username")} />
            </ExpandableFormSection>,
            <ExpandableFormSection key="password" errorKey="password" title="Password" summary={this.passwordSummary()} help="The password to use to when authenticating against the remote host.">
                <Sensitive value={this.state.model.password} onChange={(password) => this.setModelState({ password })} label="Password" />
            </ExpandableFormSection>
        );

        return baseElements;
    }
}

export default UsernamePasswordAccountEdit;
