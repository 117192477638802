/* eslint-disable no-restricted-imports */

import * as React from "react";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import MaterialDialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { SFC } from "react";
import { dialogClass } from "uiTestClasses";
import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "theme";
import cn from "classnames";
import { useRequiredContext } from "hooks";
import { OctopusThemeContext } from "components/Theme";
import ErrorContextProvider from "../ErrorContext/ErrorContext";

interface DialogProps {
    open?: boolean;
    wide?: boolean;
    repositionOnUpdate?: boolean;
    onRequestClose?: () => void;
    fullScreen?: boolean;
    title?: React.ReactNode;
    actions?: React.ReactNode;
}

const DialogRaw: SFC<DialogProps> = (props) => {
    const themeContext = useRequiredContext(OctopusThemeContext, "OctopusThemeContext");

    const useStyles = makeStyles((theme: Theme) => ({
        paper: ({ wide }: DialogProps) => ({
            maxWidth: wide ? "1000px" : "600px",
            width: "100%",
            backgroundColor: themeContext.paper1,
        }),
    }));

    const { open, children, fullScreen, title, actions } = props;
    const classes = useStyles(props);

    // This is a hack to fix https://github.com/OctopusDeploy/Issues/issues/6073
    // Dialog doesn't play nicely with Menu when it comes to focus.
    // If we upgrade Menu to the latest material-ui component then we can remove this.
    const disableEnforceFocus = true;

    return (
        <MaterialDialog classes={{ paperWidthSm: classes.paper }} className={cn(dialogClass)} open={open || false} fullScreen={fullScreen} disableEnforceFocus={disableEnforceFocus}>
            {open && (
                <ErrorContextProvider>
                    {title && <DialogTitle>{title}</DialogTitle>}
                    {children}
                    {actions && <DialogActions>{actions}</DialogActions>}
                </ErrorContextProvider>
            )}
        </MaterialDialog>
    );
};

const Dialog = withMobileDialog<DialogProps>()(DialogRaw);
Dialog.displayName = "Dialog";

export default Dialog;
