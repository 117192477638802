import { VersionControlCompatibilityResponse } from "client/resources";
import * as React from "react";
import { Note, UnstructuredFormSection } from "components/form";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout/Callout";
import Markdown from "components/Markdown";

interface UnsupportedFeaturesCalloutProps {
    vcsCompatibilityReport: VersionControlCompatibilityResponse;
}

export const UnsupportedFeaturesSection: React.FC<UnsupportedFeaturesCalloutProps> = ({ vcsCompatibilityReport }) => {
    if (vcsCompatibilityReport.Errors.length < 1 && vcsCompatibilityReport.Warnings.length < 1 && vcsCompatibilityReport.Notices.length < 1) {
        return null;
    }

    const callouts: React.ReactNodeArray = [];
    callouts.push(
        <div>
            <h3>Version Control Compatibility</h3>
            <Note>We've run a check to see whether this project is capable of supporting version control and have listed some things for you to take into consideration below.</Note>
        </div>
    );
    if (vcsCompatibilityReport.Errors.length > 0) {
        callouts.push(
            <p>
                <Callout type={CalloutType.Danger} title={"Errors"}>
                    {vcsCompatibilityReport.Errors.map((x) => (
                        <Markdown markup={x} />
                    ))}
                </Callout>
            </p>
        );
    }
    if (vcsCompatibilityReport.Warnings.length > 0) {
        callouts.push(
            <p>
                <Callout type={CalloutType.Warning} title={"Warnings"}>
                    {vcsCompatibilityReport.Warnings.map((x) => (
                        <Markdown markup={x} />
                    ))}
                </Callout>
            </p>
        );
    }
    if (vcsCompatibilityReport.Notices.length > 0) {
        callouts.push(
            <p>
                <Callout type={CalloutType.Information} title={"Notices"}>
                    {vcsCompatibilityReport.Notices.map((x) => (
                        <Markdown markup={x} />
                    ))}
                </Callout>
            </p>
        );
    }
    return <UnstructuredFormSection>{callouts}</UnstructuredFormSection>;
};
