/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { Section } from "components/Section/Section";
import EventListing from "components/EventListing/EventListing";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import { repository } from "clientInstance";
import { session } from "clientInstance";
import { Permission } from "client/resources/permission";
import { ResourceCollection, EventResource, EventCategoryResource } from "client/resources";
import { Callout, CalloutType } from "primitiveComponents/dataDisplay/Callout/Callout";

interface UserRecentActivityProps {
    userId: string;
}

interface UserRecentActivityState extends DataBaseComponentState {
    events: ResourceCollection<EventResource>;
    eventCategories: EventCategoryResource[];
    userId: string;
}

export default class UserRecentActivity extends DataBaseComponent<UserRecentActivityProps, UserRecentActivityState> {
    constructor(props: UserRecentActivityProps) {
        super(props);
        this.state = {
            events: null!,
            eventCategories: null!,
            userId: null!,
        };
    }

    currentUserId(): string {
        return session && session.currentUser ? session.currentUser.Id : null!;
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const userId = this.props.userId || this.currentUserId();
            const eventsPromise = repository.Events.list({ users: [userId] });
            const eventCategoriesPromise = repository.Events.categories({});
            this.setState({
                events: await eventsPromise,
                eventCategories: await eventCategoriesPromise,
                userId,
            });
        });
    }

    render() {
        return (
            <PaperLayout title="My Recent Activity" busy={this.state.busy} errors={this.errors}>
                <PermissionCheck
                    permission={Permission.EventView}
                    wildcard={true}
                    alternate={
                        <Callout type={CalloutType.Information} title={"Permission required"}>
                            The {Permission.EventView} permission is required to view recent activity
                        </Callout>
                    }
                >
                    <Section>
                        {this.state.events && (
                            <EventListing
                                data={this.state.events}
                                eventCategories={this.state.eventCategories}
                                onPageSelected={async (skip: number, p: number) => {
                                    const result = await repository.Events.list({ users: [this.state.userId], skip });
                                    return result;
                                }}
                                noDataMessage={"There's no activity to show"}
                            />
                        )}
                    </Section>
                </PermissionCheck>
            </PaperLayout>
        );
    }
}
