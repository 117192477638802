import CertificateVariableSelect from "components/form/CertificateSelect/CertificateVariableSelect";
import { FormFieldProps } from "components/form";
import React from "react";

interface CertificateVariableInputProps extends FormFieldProps<string> {
    projectId: string;
    label: string;
    defaultValueIndicator: JSX.Element | undefined;
    allowClear: boolean;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    validate?: ((value: string) => string) | ((value: string | null) => string);
    onValidate?(value: string): void;
}

const CertificateVariableInput: React.FC<CertificateVariableInputProps> = (props) => {
    const { value, projectId, label, defaultValueIndicator, allowClear, doBusyTask, ...rest } = props;
    const formProps = { ...rest, label };

    return (
        <React.Fragment>
            <CertificateVariableSelect value={value} projectId={projectId} doBusyTask={doBusyTask} allowClear={allowClear} {...formProps} />
            {defaultValueIndicator}
        </React.Fragment>
    );
};

export default CertificateVariableInput;
