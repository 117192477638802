import * as React from "react";
import SvgImage, { SvgImageProps } from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
const styles = require("../../../primitiveComponents/dataDisplay/SvgImage/styles.less");

const PollingTarget = (props: Partial<SvgImageProps>) => (
    <SvgImage viewBox="0 0 158 65" title="Polling Target" {...props}>
        <g fillRule="evenodd" strokeWidth="1" className={styles.imgLight}>
            <path
                className={styles.imgDark}
                d="M101.91 21.167H69v1.503h32.91l-6.792 4.689c-.537.571-.627 1.042-.27 1.413.356.371.828.371 1.416 0L105 22.67c.481-.344.701-.677.66-.998-.041-.322-.345-.638-.91-.95l-8.125-5.402c-.648-.427-1.15-.427-1.507 0-.356.427-.356.865 0 1.314l6.792 4.533z"
                transform="rotate(-180 87.332 22.025)"
            />
            <rect className={styles.imgDark} width="51.63" height="43.48" x="106.18" y="2" fillRule="nonzero" rx="2.72" />
            <path
                id="fill"
                fillRule="nonzero"
                d="M148.19 14.77a1.47 1.47 0 0 1-1 .34 2.81 2.81 0 0 1-1.94-.94c-.94-1-1.1-2.16-.38-2.79a1.47 1.47 0 0 1 1-.34 2.88 2.88 0 0 1 1.95.93c.375.376.64.845.77 1.36a1.42 1.42 0 0 1-.4 1.44m-4.36-4.48c-.06.22-.32.73-1.39.73a3.83 3.83 0 0 1-.93-.12c-1.19-.29-1.9-.95-1.72-1.6.06-.22.31-.73 1.39-.73.314.002.626.043.93.12 1.19.29 1.9.95 1.72 1.6m-7.19.61a2.7 2.7 0 0 1-1.08.26.92.92 0 0 1-.89-.45c-.27-.53.15-1.17 1.06-1.58a2.66 2.66 0 0 1 1.08-.26.91.91 0 0 1 .89.45c.27.53-.16 1.16-1.06 1.58m-4 18.12a2.68 2.68 0 0 1-.62.07c-.66 0-1.11-.25-1.21-.68a.83.83 0 0 1 .47-.91c.28-.18.593-.306.92-.37.204-.04.412-.06.62-.06.66 0 1.12.25 1.21.67.09.42-.42 1.08-1.39 1.28m-5.17-1.88c-.08.53-.7.85-1.66.85h-.47a2.57 2.57 0 0 1-1.67-.68.76.76 0 0 1-.19-.6c.08-.53.7-.85 1.66-.85h.48a2.6 2.6 0 0 1 1.67.68.76.76 0 0 1 .18.6m-8-2.78a1.1 1.1 0 0 1-1 .5 3.62 3.62 0 0 1-1.58-.43c-1.19-.59-1.77-1.45-1.41-2.08a1.07 1.07 0 0 1 1-.5 3.73 3.73 0 0 1 1.58.42c1.19.6 1.77 1.45 1.42 2.09m-7.6-3.7a1.08 1.08 0 0 1-.82.32 3.77 3.77 0 0 1-2.2-1c-1.38-1.2-1.49-2.28-1-2.77a1.07 1.07 0 0 1 .82-.32 3.72 3.72 0 0 1 2.19 1c1.39 1.2 1.5 2.28 1 2.77m40-4.47c-5.7-14.72-23-8.11-25.49-.25-1.43 4.45 2.12 3.68 5.27 1.48 5.39-3.74 11.66-2.49 10.71 3.08-1 6-9.57 6.22-18.4 2.84a54.77 54.77 0 0 1-17.69-11V32s21.53 11.51 38.28.85c5.08-3.23 9.59-10.75 7.32-16.61"
            />
            <circle className={styles.imgCyan} cx="22.5" cy="22.5" r="22" fillRule="nonzero" />
            <path
                className={styles.imgWhite}
                fillRule="nonzero"
                d="M10.37 29.87c2.14-1.38 4.66-3.75 3.71-6.54a16.28 16.28 0 0 1-1.34-4.46 10 10 0 0 1 .61-4.09 10.36 10.36 0 0 1 12.8-6.05c4.78 1.44 8.07 7 6.09 11.83-1.14 2.79-1.66 4.95.9 7.12.69.59 2.37 1.47 2.36 2.52 0 1.37-2.69-.29-3-.53.34.6 3.72 4.12 1.57 4.37-2 .23-3.73-2.53-4.92-3.72-2-2-1.66 2.42-1.67 3.33 0 1.44-1 4.36-2.85 2.46-1.51-1.57-.94-4.07-2-5.82-1.13-1.91-3.04 1.91-3.52 2.61s-3.2 4.56-4.26 2.55c-.87-1.64.51-4.2 1.19-5.68-.25.54-2 1.33-2.51 1.59a6.87 6.87 0 0 1-3.63.84c-2.69-.2-.62-1.62.47-2.33z"
            />
            <path className={styles.imgCyan} d="M63.77 21.736H50.358C49.793 19.912 48.594 19 46.76 19 44.01 19 43 21.142 43 22.76c0 1.618.785 3.718 3.56 3.718 1.849 0 3.115-.892 3.798-2.675H63.77v-2.067z" transform="rotate(-180 53.385 22.739)" />
            <path
                className={styles.imgDark}
                fillRule="nonzero"
                d="M131.208 41v11.752c-1.169.432-2.155 1.04-2.958 1.826-.803.786-1.345 1.673-1.625 2.66h-12.438c-.791.341-1.187.846-1.187 1.514s.396 1.165 1.188 1.491h12.437c1.162 3.006 3.141 4.51 5.938 4.51 2.796 0 4.736-1.504 5.819-4.51h12.243c1-.264 1.5-.761 1.5-1.49 0-.73-.5-1.235-1.5-1.515h-12.243c-.272-1.098-.735-1.985-1.389-2.66-.653-.675-1.598-1.243-2.833-1.702V41h-2.952z"
            />
        </g>
    </SvgImage>
);
export { PollingTarget };
