import * as React from "react";
import { IconButton, IconButtonProps } from "@material-ui/core";
import { PropsWithChildren } from "react";

//eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SimpleIconButtonProps extends IconButtonProps {}

const SimpleIconButton: React.FC<PropsWithChildren<SimpleIconButtonProps>> = ({ children, ...props }) => {
    return (
        <IconButton {...props}>
            <>{children}</>
        </IconButton>
    );
};

export default SimpleIconButton;
