import { DevToolsTab } from "../DevTools/DevToolsContext";
import FeaturesLayout from "areas/configuration/components/FeaturesLayout/FeaturesLayout";
import * as React from "react";

export function FeaturesDevToolsTabRegistration() {
    return (
        <DevToolsTab name={"Features"}>
            <FeaturesLayout fullWidth={true} dirtyTrackingDisabled={true} />
        </DevToolsTab>
    );
}
