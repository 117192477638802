import { AnalyticFields, AnalyticsEnvironment, AnalyticSession } from "./AnalyticSession";

export class NullSession implements AnalyticSession {
    constructor(public readonly environment: AnalyticsEnvironment) {}

    track(name: string, event: AnalyticFields): void {
        // Do nothing
    }

    end(): void {
        // Do nothing
    }
}
