import * as React from "react";
import SvgImage, { SvgImageProps } from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
const styles = require("../../../primitiveComponents/dataDisplay/SvgImage/styles.less");

const SshConnection = (props: Partial<SvgImageProps>) => (
    <SvgImage viewBox="0 0 58 70" title="SSH Connection" {...props}>
        <g fillRule="nonzero" strokeWidth="1">
            <path
                className={styles.imgDark}
                d="M27.333 45.748V57.5c-1.169.431-2.155 1.04-2.958 1.826-.803.786-1.345 1.673-1.625 2.66H10.312c-.791.341-1.187.846-1.187 1.514s.396 1.165 1.188 1.491H22.75c1.162 3.006 3.141 4.509 5.938 4.509 2.796 0 4.736-1.503 5.819-4.509H46.75c1-.264 1.5-.761 1.5-1.491 0-.73-.5-1.234-1.5-1.514H34.507c-.272-1.098-.735-1.985-1.389-2.66-.653-.675-1.598-1.243-2.833-1.702V45.748h-2.952z"
            />
            <rect className={styles.imgCyan} width="57" height="48" x=".5" y=".5" rx="3" />
            <path
                className={styles.imgWhite}
                d="M20.85 26.13a3.28 3.28 0 0 0 0-6.56 3 3 0 0 0-2.26 1 3.28 3.28 0 0 0 0 4.6 3 3 0 0 0 2.26.96zm9.2-6.56H46.9v6.56h-3.28v6.47h-6.47v-6.47h-7.1a9.26 9.26 0 0 1-3.51 4.68 9.78 9.78 0 0 1-14.13-3.07 9.78 9.78 0 0 1 0-9.78 9.82 9.82 0 0 1 14.13-3.07 9.26 9.26 0 0 1 3.51 4.68z"
            />
        </g>
    </SvgImage>
);
export { SshConnection };
