import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonLibraryStepTemplateOverview, CommonLibraryStepTemplateTopics } from "../../CommonComponents/CommonLibraryStepTemplateViews";

export const LibraryStepTemplateRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.library.stepTemplate.root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryStepTemplateOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryStepTemplateTopics />;
    },
};
