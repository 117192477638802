import Permission from "client/resources/permission";
import { client } from "clientInstance";
import { pageId } from "utils/pageId";
import configurationRouteLinks from "./configurationRouteLinks";

const configurationLinks = configurationRouteLinks(client.spaceId ?? "");

export default () => {
    return {
        root: pageId({
            area: "Configuration",
            id: "Configuration",
            name: "Configuration",
            routeLink: configurationLinks.root,
            routePermission: {
                permission: [Permission.AdministerSystem, Permission.EventView, Permission.TeamView, Permission.UserView, Permission.UserRoleView, Permission.SpaceView, Permission.SpaceEdit, Permission.SpaceCreate, Permission.SpaceDelete],
                wildcard: true,
            },
        }),
        userInvites: pageId({ area: "Configuration", id: "Configuration.UserInvites", name: "Invite Users", routeLink: configurationLinks.userInvites, routePermission: { permission: [Permission.AdministerSystem, Permission.UserInvite] } }),
        auditBaseRoute: pageId({ area: "Configuration", id: "Configuration.Audit", name: "Audit", routeLink: configurationLinks.auditBaseRoute, routePermission: { permission: [Permission.AdministerSystem, Permission.EventView], wildcard: true } }),
        audit: pageId({ area: "Configuration", id: "Configuration.Audit", name: "Audit", routeLink: null }),
        machineEventsForUser: pageId({ area: "Configuration", id: "Configuration.AuditMachine", name: "Audit Machine", routeLink: null }),
        eventsForProject: pageId({ area: "Configuration", id: "Configuration.AuditProject", name: "Audit Project", routeLink: null }),
        eventsForTenant: pageId({ area: "Configuration", id: "Configuration.AuditTenant", name: "Audit Tenant", routeLink: null }),
        eventsForEnvironment: pageId({ area: "Configuration", id: "Configuration.AuditEnvironment", name: "Audit Environment", routeLink: null }),
        eventsForUser: pageId({ area: "Configuration", id: "Configuration.AuditUser", name: "Audit User", routeLink: null }),
        eventsRegardingAny: pageId({ area: "Configuration", id: "Configuration.AuditRegardingAny", name: "Audit Any Document", routeLink: null }),
        deploymentProcessEventsForProject: pageId({ area: "Configuration", id: "Configuration.AuditDeploymentProcess", name: "Audit Deployment Process", routeLink: null }),
        variableSetEventsForProject: pageId({ area: "Configuration", id: "Configuration.AuditVariableSet", name: "Audit Variable Set", routeLink: null }),
        libraryVariableSetEventsRegardingAny: pageId({ area: "Configuration", id: "Configuration.AuditLibraryVariableSet", name: "Audit Library Variable Set", routeLink: null }),
        backup: pageId({ area: "Configuration", id: "Configuration.Backup", name: "Backup", routeLink: configurationLinks.backup, routePermission: { permission: Permission.AdministerSystem } }),
        features: pageId({ area: "Configuration", id: "Configuration.Features", name: "Features", routeLink: configurationLinks.features, routePermission: { permission: [Permission.AdministerSystem, Permission.ConfigureServer] } }),
        license: pageId({ area: "Configuration", id: "Configuration.License", name: "License", routeLink: configurationLinks.license, routePermission: { permission: Permission.AdministerSystem } }),
        smtp: pageId({ area: "Configuration", id: "Configuration.Smtp", name: "SMTP", routeLink: configurationLinks.smtp, routePermission: { permission: [Permission.AdministerSystem, Permission.ConfigureServer] } }),
        nodes: {
            root: pageId({ area: "Configuration", id: "Configuration.Nodes", name: "Nodes", routeLink: configurationLinks.nodes.root, routePermission: { permission: Permission.AdministerSystem } }),
            serverSettings: pageId({ area: "Configuration", id: "Configuration.NodesServerSettings", name: "Node Server Settings", routeLink: configurationLinks.nodes.serverSettings, routePermission: { permission: Permission.AdministerSystem } }),
            config: pageId({ area: "Configuration", id: "Configuration.NodesConfig", name: "Nodes Configuration", routeLink: null }),
        },
        thumbprint: pageId({
            area: "Configuration",
            id: "Configuration.Thumbprint",
            name: "Thumbprint",
            routeLink: configurationLinks.thumbprint,
            routePermission: { permission: [Permission.AdministerSystem, Permission.MachineEdit], wildcard: true },
        }),
        maintenance: pageId({ area: "Configuration", id: "Configuration.Maintenance", name: "Maintenance", routeLink: configurationLinks.maintenance, routePermission: { permission: Permission.AdministerSystem } }),
        letsEncrypt: {
            root: pageId({ area: "Configuration", id: "Configuration.LetsEncrypt", name: "Let's Encrypt", routeLink: configurationLinks.letsEncrypt.root, routePermission: { permission: Permission.AdministerSystem } }),
            configure: pageId({ area: "Configuration", id: "Configuration.LetsEncryptConfigure", name: "Configure Let's Encrypt", routeLink: configurationLinks.letsEncrypt.configure, routePermission: { permission: Permission.AdministerSystem } }),
        },
        testPermissions: pageId({ area: "Configuration", id: "Configuration.TestPermissions", name: "Test Permissions", routeLink: configurationLinks.testPermissions, routePermission: { permission: [Permission.TeamEdit, Permission.UserView] } }),
        testPermission: pageId({ area: "Configuration", id: "Configuration.TestPermission", name: "Test Permission", routeLink: null }),
        subscriptions: {
            root: pageId({ area: "Configuration", id: "Configuration.Subscriptions", name: "Subscriptions", routeLink: configurationLinks.subscriptions.root, routePermission: { permission: [Permission.SubscriptionView] } }),
            create: pageId({ area: "Configuration", id: "Configuration.SubscriptionsNew", name: "Add New Subscription", routeLink: configurationLinks.subscriptions.create, routePermission: { permission: [Permission.SubscriptionCreate] } }),
        },
        subscription: pageId({ area: "Configuration", id: "Configuration.Subscription", name: "Subscription", routeLink: null }),
        users: {
            root: pageId({ area: "Configuration", id: "Configuration.Users", name: "Users", routeLink: configurationLinks.users.root, routePermission: { permission: [Permission.AdministerSystem, Permission.UserView] } }),
            create: pageId({ area: "Configuration", id: "Configuration.UsersNew", name: "Add New User", routeLink: configurationLinks.users.create, routePermission: { permission: [Permission.AdministerSystem, Permission.UserEdit] } }),
        },
        user: pageId({ area: "Configuration", id: "Configuration.User", name: "User", routeLink: null }),
        spaces: {
            root: pageId({ area: "Configuration", id: "Configuration.Spaces", name: "Spaces", routeLink: configurationLinks.spaces.root, routePermission: { permission: [Permission.AdministerSystem, Permission.SpaceView] } }),
            create: pageId({ area: "Configuration", id: "Configuration.SpacesNew", name: "Add New Space", routeLink: configurationLinks.spaces.create, routePermission: { permission: [Permission.AdministerSystem, Permission.SpaceCreate] } }),
        },
        space: pageId({ area: "Configuration", id: "Configuration.Space", name: "Space", routeLink: null }),
        teams: {
            root: pageId({ area: "Configuration", id: "Configuration.Teams", name: "Teams", routeLink: () => configurationLinks.teams.root(), routePermission: { permission: [Permission.TeamEdit, Permission.TeamView] } }),
            redirect: pageId({ area: "Configuration", id: "Configuration.TeamsRedirect", name: "Teams Redirect", routeLink: null }),
        },
        team: pageId({ area: "Configuration", id: "Configuration.Team", name: "Team", routeLink: null }),
        roles: {
            root: pageId({ area: "Configuration", id: "Configuration.Roles", name: "User Roles", routeLink: configurationLinks.roles.root, routePermission: { permission: [Permission.AdministerSystem, Permission.UserRoleView] } }),
            create: pageId({ area: "Configuration", id: "Configuration.RolesNew", name: "Add New User Role", routeLink: configurationLinks.roles.create, routePermission: { permission: [Permission.AdministerSystem, Permission.UserRoleEdit] } }),
        },
        role: pageId({ area: "Configuration", id: "Configuration.Role", name: "User Role", routeLink: null }),
        diagnostics: {
            root: pageId({ area: "Configuration", id: "Configuration.Diagnostics", name: "Diagnostics", routeLink: configurationLinks.diagnostics.root, routePermission: { permission: Permission.AdministerSystem } }),
            logs: {
                root: pageId({ area: "Configuration", id: "Configuration.DiagnosticLogs", name: "Diagnostic Logs", routeLink: configurationLinks.diagnostics.logs.root, routePermission: { permission: Permission.AdministerSystem } }),
                autoDeploy: pageId({
                    area: "Configuration",
                    id: "Configuration.DiagnosticLogsAutoDeploy",
                    name: "Auto Deploy Diagnostic Logs",
                    routeLink: configurationLinks.diagnostics.logs.autoDeploy,
                    routePermission: { permission: Permission.AdministerSystem },
                }),
                scheduledDeploy: pageId({
                    area: "Configuration",
                    id: "Configuration.DiagnosticLogsScheduledDeploy",
                    name: "Scheduled Deploy Diagnostic Logs",
                    routeLink: configurationLinks.diagnostics.logs.scheduledDeploy,
                    routePermission: { permission: Permission.AdministerSystem },
                }),
                machineCleanup: pageId({
                    area: "Configuration",
                    id: "Configuration.DiagnosticLogsMachineCleanup",
                    name: "Machine Cleanup Diagnostic Logs",
                    routeLink: configurationLinks.diagnostics.logs.machineCleanup,
                    routePermission: { permission: Permission.AdministerSystem },
                }),
                subscription: pageId({
                    area: "Configuration",
                    id: "Configuration.DiagnosticLogsSubscription",
                    name: "Subscription Diagnostic Logs",
                    routeLink: configurationLinks.diagnostics.logs.subscription,
                    routePermission: { permission: Permission.AdministerSystem },
                }),
            },
        },
        settings: {
            root: pageId({ area: "Configuration", id: "Configuration.Settings", name: "Settings", routeLink: configurationLinks.settings.root, routePermission: { permission: [Permission.AdministerSystem, Permission.ConfigureServer] } }),
        },
        setting: (settingId: string) => {
            return {
                root: pageId({ area: "Configuration", id: `Configuration.Setting.${settingId}`, name: "Setting", routeLink: null }),
            };
        },
        performance: pageId({ area: "Configuration", id: "Configuration.Performance", name: "Performance", routeLink: configurationLinks.performance, routePermission: { permission: Permission.ConfigureServer } }),
    };
};
