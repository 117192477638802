/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import * as _ from "lodash";
import { repository } from "clientInstance";
import { ChannelResource, ProjectResource, DeploymentProcessResource } from "client/resources";
import { Select, Note } from "components/form";
import { required } from "components/form/Validators";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { DeploymentActionPackageResource, displayName } from "../../../../client/resources/deploymentActionPackageResource";

interface EditAutoReleaseCreationProps {
    channels: ChannelResource[];
    actionPackages: DeploymentActionPackageResource[];
    project: ProjectResource;
    deploymentProcess: DeploymentProcessResource;
    onProjectSaved(project: ProjectResource): void;
}

interface EditAutoReleaseCreationState extends DataBaseComponentState {
    channelId: string;
    releaseCreationPackageIndex: number;
}

export default class EditAutoReleaseCreation extends DataBaseComponent<EditAutoReleaseCreationProps, EditAutoReleaseCreationState> {
    constructor(props: EditAutoReleaseCreationProps) {
        super(props);
        const currentReleaseCreationPackage = props.project.ReleaseCreationStrategy.ReleaseCreationPackage;
        this.state = {
            channelId: props.project.ReleaseCreationStrategy.ChannelId!,
            releaseCreationPackageIndex: !currentReleaseCreationPackage
                ? null!
                : _.findIndex(this.props.actionPackages, (pkg) => {
                      // With our TinyType implementation, server will convert the release creation package
                      // reference from "" to null, and breaks this lookup (the package list keeps them as ""
                      // as this is a meaningful value in the server code). Convert back to empty string so
                      // we can do the lookup.
                      const nullAsEmptyPackageReferenceIndex = pkg.PackageReference ?? "";
                      const nullAsEmptyReleaseCreationPackageReference = currentReleaseCreationPackage.PackageReference ?? "";

                      return pkg.DeploymentAction === currentReleaseCreationPackage.DeploymentAction && nullAsEmptyPackageReferenceIndex === nullAsEmptyReleaseCreationPackageReference;
                  }),
        };
    }

    render() {
        return (
            <SaveDialogLayout title="Setup automatic release creation" busy={this.state.busy} errors={this.errors} onSaveClick={() => this.save()}>
                <Select
                    value={String(this.state.releaseCreationPackageIndex)}
                    onChange={(releaseCreationPackageIndex) => this.setState({ releaseCreationPackageIndex: Number(releaseCreationPackageIndex) })}
                    items={this.props.actionPackages.map((pkg, idx) => ({ value: String(idx), text: displayName(pkg), disabled: this.stepIsDisabled(pkg.DeploymentAction) }))}
                    label="Package step"
                    validate={required("Select a package step")}
                    autoFocus
                    fixedLabel={true}
                />
                <Note>Create a new release when this steps package is pushed to the built-in repository.</Note>
                {this.props.channels.length > 1 && (
                    <Select
                        value={this.state.channelId}
                        onChange={(channedId) => this.setState({ channelId: channedId! })}
                        items={this.props.channels.map((pg) => ({ value: pg.Id, text: pg.Name }))}
                        label="Channel"
                        validate={required("Select a channel")}
                        fixedLabel={true}
                    />
                )}
                {this.props.channels.length > 1 && <Note>The release created will use this channel and the pushed package must satisfy the selected channel's versioning rules (if any).</Note>}
            </SaveDialogLayout>
        );
    }

    private stepIsDisabled(deploymentAction: string) {
        const action = _.chain(this.props.deploymentProcess.Steps)
            .flatMap((step) => step.Actions)
            .find((x) => x.Name === deploymentAction)
            .value();
        return action!.IsDisabled;
    }

    private save() {
        return this.doBusyTask(async () => {
            const project = {
                ...this.props.project,
                AutoCreateRelease: true,
                ReleaseCreationStrategy: {
                    ReleaseCreationPackage: this.props.actionPackages[this.state.releaseCreationPackageIndex],
                    ChannelId: this.state.channelId || this.props.channels[0].Id,
                },
            };

            const result = await repository.Projects.save(project);
            this.props.onProjectSaved(result);
            return true;
        });
    }
}
