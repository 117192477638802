import * as React from "react";
import * as _ from "lodash";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import { RouteComponentProps, withRouter } from "react-router";
import { RunbookContextProvider, RunbookContextProps } from "./RunbookContext";
import { isEqual } from "lodash";

//eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RunbookContextLayoutState extends DataBaseComponentState {}

interface RunbookContextLayoutRouteProps {
    runbookId: string;
}

type RunbookContextLayoutProps = RouteComponentProps<RunbookContextLayoutRouteProps> & {
    children: (context: RunbookContextProps) => React.ReactNode;
};

class RunbookContextLayout extends DataBaseComponent<RunbookContextLayoutProps, RunbookContextLayoutState> {
    shouldComponentUpdate(nextProps: RunbookContextLayoutProps) {
        return !isEqual(nextProps.match.params.runbookId, this.props.match.params.runbookId);
    }
    render() {
        return (
            <RunbookContextProvider id={this.props.match.params.runbookId} doBusyTask={this.doBusyTask}>
                {(runbookContext) => {
                    return this.props.children(runbookContext);
                }}
            </RunbookContextProvider>
        );
    }
}

export default withRouter(RunbookContextLayout);
