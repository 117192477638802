/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable no-restricted-imports */

import { flatten } from "lodash";
import MaterialMenuItem from "material-ui/MenuItem";
import { ThirdPartyIcon, ThirdPartyIconType } from "primitiveComponents/dataDisplay/Icon";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import InternalLink from "../Navigation/InternalLink/InternalLink";
import { isUrlActive } from "../Navigation/isUrlActive";
import { withTheme } from "components/Theme";

export interface MenuNodeItem {
    url: string;
    text: string;
    title?: string;
    icon?: JSX.Element;
    exact?: boolean;
    accessoryView?: React.ReactNode;
    linkClassName?: string;
}

export interface MenuNodeGroup {
    label: string | JSX.Element;
    children: MenuNode[];
}

export interface MenuNodePopper {
    icon: JSX.Element;
    url: string;
    exact?: boolean;
    text?: string;
    title?: string;
    onClick: (e: HTMLElement) => void;
}

export type MenuNode = MenuNodeGroup | MenuNodeItem | MenuNodePopper;

interface MenuNodeComponentProps {
    node: MenuNode;
    onClick: () => void;
}

type MenuNodeProps = MenuNodeComponentProps & RouteComponentProps<{ spaceId: string }>;

const MenuNodeComponent: React.SFC<MenuNodeProps> = (props) =>
    withTheme((theme) => {
        const activeStyle = { color: theme.primary };

        if (isMenuGroup(props.node)) {
            return (
                <MaterialMenuItem
                    style={hasActiveDescendants(props.location.pathname, props.match.params.spaceId, props.node) ? activeStyle : {}}
                    primaryText={props.node.label}
                    rightIcon={<ThirdPartyIcon iconType={ThirdPartyIconType.ArrowRight} />}
                    menuItems={props.node.children.map((c, index) => (
                        <RoutedMenuNodeComponent node={c} key={index} onClick={props.onClick} />
                    ))}
                />
            );
        }

        if (isMenuPopper(props.node)) {
            return (
                <InternalLink to={props.node.url}>
                    <MaterialMenuItem leftIcon={props.node.icon} onClick={() => props.onClick()} />
                </InternalLink>
            );
        }

        return (
            <InternalLink to={props.node.url}>
                <MaterialMenuItem style={isUrlActive(props.location.pathname, props.match.params.spaceId, props.node.url, props.node.exact!) ? activeStyle : {}} primaryText={props.node.text} onClick={() => props.onClick()} />
            </InternalLink>
        );
    });

const RoutedMenuNodeComponent = withRouter(MenuNodeComponent);

export { RoutedMenuNodeComponent as MenuNodeComponent };

export function isMenuGroup(node: MenuNode): node is MenuNodeGroup {
    return (node as MenuNodeGroup).children !== undefined;
}

export function isMenuPopper(node: MenuNode): node is MenuNodePopper {
    return (node as MenuNodePopper).onClick !== undefined;
}

export function hasActiveDescendants(pathname: string, spaceId: string, group: MenuNodeGroup): boolean {
    const allDescendantLinks = (_: MenuNodeGroup): Array<MenuNodeItem | MenuNodePopper> =>
        flatten(
            _.children.map((c: MenuNode) => {
                if (isMenuGroup(c)) {
                    return allDescendantLinks(c);
                }
                return [c];
            })
        );

    return allDescendantLinks(group).some((l) => isUrlActive(pathname, spaceId, l.url, l.exact!));
}
