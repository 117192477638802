import * as React from "react";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout";
import InternalLink from "components/Navigation/InternalLink";
import routeLinks from "routeLinks";
import ExternalLink from "components/Navigation/ExternalLink";

interface SmtpNeedsConfigurationCalloutProps {
    configured: boolean;
    hasPermissions: boolean;
}

export const SmtpNeedsConfigurationCallout: React.FC<SmtpNeedsConfigurationCalloutProps> = ({ configured, hasPermissions }) => {
    if (configured) {
        return null;
    }

    const text = hasPermissions ? (
        <>
            You will need to <InternalLink to={routeLinks.configuration.smtp}>configure SMTP</InternalLink> before emails can be sent.
        </>
    ) : (
        <>
            SMTP needs to be configured in this instance before emails can be sent. Contact your Octopus Administrator for more information. <ExternalLink href="SMTPConfig">Learn More</ExternalLink>.
        </>
    );

    return (
        <Callout type={CalloutType.Warning} title="SMTP is not configured">
            {text}
        </Callout>
    );
};
