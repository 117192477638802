import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonDashboardOverview, CommonDashboardTopics } from "../../CommonComponents/CommonDashboardViews";

export const DashboardConfigureRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.dashboard.configure,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonDashboardOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonDashboardTopics />;
    },
};
