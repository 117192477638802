/* eslint-disable @typescript-eslint/no-explicit-any */

import { createReducer, createAction, ActionType } from "typesafe-actions";
import { createSelector } from "reselect";

export type VisibilityState = {
    [key: string]: boolean;
};

export const createVisibilityReducer = (name: string) => {
    const actions = {
        open: createAction(`VISIBILITY_OPEN`, (resolve) => (key: string) => resolve(key, name)),
        openOnly: createAction(`VISIBILITY_OPEN_ONLY`, (resolve) => (key: string) => resolve(key, name)),
        close: createAction(`VISIBILITY_CLOSE`, (resolve) => (key: string) => resolve(key, name)),
        closeOthers: createAction(`VISIBILITY_CLOSE_OTHERS`, (resolve) => (key: string) => resolve(key, name)),
        closeAll: createAction(`VISIBILITY_CLOSE_ALL`, (resolve) => () => resolve(undefined, name)),
    };

    const getSelectors = <T extends any = VisibilityState>(sliceSelector: (state: T) => VisibilityState) => {
        const createVisibleSelector = (key: string) => createSelector(sliceSelector, (state) => state.hasOwnProperty(key) && !!state[key]);

        return {
            createVisibleSelector,
        };
    };

    type VisibilityActionType = ActionType<typeof actions>;

    const INITIAL_STATE: VisibilityState = {};

    const reducer = createReducer<VisibilityState, VisibilityActionType>(INITIAL_STATE)
        .handleAction(actions.close, (state, { payload: key, meta }) => {
            if (meta !== name || !state.hasOwnProperty(key)) {
                return state;
            }

            const result = { ...state };
            delete result[key];
            return result;
        })
        .handleAction(actions.closeAll, (state, { meta }) => {
            if (meta !== name) {
                return state;
            }
            return {};
        })
        .handleAction(actions.closeOthers, (state, { payload: key, meta }) => {
            if (meta !== name) {
                return state;
            }
            if (!state.hasOwnProperty(key)) {
                return {};
            }
            return { [key]: state[key] };
        })
        .handleAction(actions.open, (state, { payload: key, meta }) => {
            if (meta !== name) {
                return state;
            }
            if (state.hasOwnProperty(key) && state[key]) {
                return state;
            }
            return { ...state, [key]: true };
        })
        .handleAction(actions.openOnly, (state, { payload: key, meta }) => {
            if (meta !== name) {
                return state;
            }
            return { [key]: true };
        });

    return { actions, reducer, getSelectors, INITIAL_STATE };
};
