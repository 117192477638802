import * as React from "react";
import { useRequiredContext } from "hooks/useRequiredContext";
import { useDoBusyTaskEffect, DoBusyTask } from "components/DataBaseComponent";
import { repository } from "clientInstance";
import { RunbookResource, RunbookSnapshotResource, ProcessType } from "client/resources";
import { useProjectContext } from "areas/projects/context";
import { useSelector } from "react-redux";
import { isVersionControlledProcess } from "../Process/Common/CommonProcessHelpers";

export type RunbookContextProps = ReturnType<typeof useRunbookSetup>;

const RunbookContext = React.createContext<RunbookContextProps | undefined>(undefined);

interface RunbookContextState {
    runbook: RunbookResource | undefined;
}

interface RunbookContextProviderProps {
    id: string;
    doBusyTask: DoBusyTask;
    children: (props: RunbookContextProps) => React.ReactNode;
}

const getStateUpdaters = (setState: React.Dispatch<React.SetStateAction<RunbookContextState>>) => {
    return {
        onRunbookUpdated: (runbook: RunbookResource) => {
            setState((current) => ({ ...current, runbook }));
        },
    };
};

const isConfigurationAsCodeForRunbooksEnabledSelector = (state: GlobalState) => state.configurationArea.features.isConfigurationAsCodeForRunbooksEnabled;

const useRunbookSetup = (id: string, doBusyTask: DoBusyTask) => {
    const [state, setState] = React.useState<RunbookContextState>({
        runbook: undefined,
    });
    const projectContext = useProjectContext();

    const isConfigurationAsCodeForRunbooksEnabled = useSelector(isConfigurationAsCodeForRunbooksEnabledSelector);
    const isVersionControlled = isVersionControlledProcess(projectContext.state.model.IsVersionControlled, ProcessType.Runbook, !!isConfigurationAsCodeForRunbooksEnabled);

    const updaters = React.useMemo(() => getStateUpdaters(setState), [setState]);

    const projectContextRepository = projectContext.state.projectContextRepository;
    const refreshRunbook = useDoBusyTaskEffect(
        doBusyTask,
        async () => {
            const runbook = isVersionControlled ? await projectContext.state.projectContextRepository.Runbooks.get(id) : await repository.Runbooks.get(id);
            updaters.onRunbookUpdated(runbook);
        },
        [id, projectContextRepository]
    );

    const publishSnapshot = React.useCallback(
        (snapshot: RunbookSnapshotResource) => {
            if (!snapshot) {
                return;
            }

            return doBusyTask(async () => {
                const result = await repository.Runbooks.get(id);
                result.PublishedRunbookSnapshotId = snapshot.Id;
                updaters.onRunbookUpdated(await repository.Runbooks.modify(result));
            });
        },
        [doBusyTask, id, updaters]
    );

    const supportedActions = {
        refreshRunbook,
        publishSnapshot,
        ...updaters,
    };

    return {
        actions: supportedActions,
        state,
        setState,
    };
};

export const RunbookContextProvider: React.FC<RunbookContextProviderProps> = ({ children, id, doBusyTask }) => {
    const value = useRunbookSetup(id, doBusyTask);
    return <RunbookContext.Provider value={value}>{children(value)}</RunbookContext.Provider>;
};

export interface WithRunbookContextInjectedProps {
    runbookContext: RunbookContextProps;
}

export type WithOptionalRunbookContextInjectedProps = Partial<WithRunbookContextInjectedProps>;

export const withRunbookContext = <T extends unknown>(Component: React.ComponentType<T & WithRunbookContextInjectedProps>) => {
    const WithRunbookContext: React.FC<T> = (props) => {
        const context = useRunbookContext();
        return <Component runbookContext={context} {...props} />;
    };
    return WithRunbookContext;
};

export const withOptionalRunbookContext = <T extends unknown>(Component: React.ComponentType<T & WithOptionalRunbookContextInjectedProps>) => {
    const WithRunbookContext: React.FC<T> = (props) => {
        const context = useOptionalRunbookContext();
        return <Component runbookContext={context} {...props} />;
    };
    return WithRunbookContext;
};

export const useRunbookContext = () => {
    return useRequiredContext(RunbookContext, "Runbook");
};

export const useOptionalRunbookContext = () => {
    return React.useContext(RunbookContext);
};
