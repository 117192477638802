import * as React from "react";
const styles = require("./style.less");
import cn from "classnames";
import IconButton from "components/IconButton";
import { Icon } from "components/IconButton/IconButton";

interface CardTitleProps {
    title: JSX.Element;
    onClick?: (e: React.MouseEvent) => void;
    showExpandableButton?: boolean;
    isExpanded?: boolean;
    forceMobileBehaviour?: boolean;
    useCardTitleContainerStyle?: boolean;
}

class CardTitle extends React.Component<CardTitleProps> {
    render() {
        return (
            <div onClick={this.props.onClick} className={cn({ [styles.clickable]: this.props.showExpandableButton })}>
                <div className={cn({ [styles.cardTitleContainer]: this.props.showExpandableButton || this.props.useCardTitleContainerStyle }, { [styles.cardTitleContainerForceMobile]: this.props.forceMobileBehaviour })}>{this.props.title}</div>
                <div className={styles.cardExpander}>
                    {this.props.showExpandableButton && (
                        <IconButton
                            className={cn(styles.expandIcon, this.props.isExpanded ? styles.expandOpen : styles.expandClosed)}
                            onClick={this.props.onClick}
                            aria-expanded={this.props.isExpanded}
                            aria-label="expand"
                            icon={Icon.Expand}
                            style={{ marginRight: "1rem" }}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default CardTitle;
