import React = require("react");
import { DefaultTopics } from "./DefaultViews";
import { OverviewContainer, OverviewHighlightText, TermsText, CommonNeedHelpInformation } from "./CommonViews";
import { Note } from "components/form";
import CustomDialog from "components/Dialog/CustomDialog";
import { FundamentalsGuideDialogLayout } from "components/GettingStarted/FundamentalsGuide/FundamentalsGuideDialogLayout";
import ExternalLink from "components/Navigation/ExternalLink";

interface CommonDashboardOverviewState {
    showFundamentals: boolean;
}

export class CommonDashboardOverview extends React.Component<{}, CommonDashboardOverviewState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            showFundamentals: false,
        };
    }

    render() {
        return (
            <OverviewContainer>
                <CustomDialog open={this.state.showFundamentals} close={this.closeFundamentalsDialog} render={(renderProps) => <FundamentalsGuideDialogLayout {...renderProps} />} />

                <OverviewHighlightText>
                    <p>
                        The <b>dashboard</b> is where you can see what's happening with your deployments. Use the 'Configure' button to include only the projects or environments you're interested in.
                    </p>
                    <TermsText>
                        <Note heading={"Getting started"}>
                            <div>
                                <a href="#" onClick={this.showFundamentalsDialog}>
                                    Quick guide
                                </a>
                            </div>
                            <div>
                                <ExternalLink href="GettingStarted">Overview</ExternalLink>
                            </div>
                            <div>
                                <ExternalLink href="GettingStartedVideos">Video tutorials</ExternalLink>
                            </div>
                        </Note>
                        <Note heading={"Need Help?"}>
                            <CommonNeedHelpInformation />
                        </Note>
                    </TermsText>
                </OverviewHighlightText>
            </OverviewContainer>
        );
    }

    private showFundamentalsDialog = (e: React.MouseEvent) => {
        e.preventDefault();
        this.setState({ showFundamentals: true });
    };

    private closeFundamentalsDialog = () => {
        this.setState({ showFundamentals: false });
    };
}

export const CommonDashboardTopics: React.StatelessComponent<{}> = (props) => {
    return <DefaultTopics />;
};
