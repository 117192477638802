import * as React from "react";
import { createContext } from "react";

export interface FocusContextState {
    current?: React.ReactElement | HTMLDivElement | null;
}

const { Provider, Consumer } = createContext<FocusContextState | undefined>(undefined);

export { Provider as FocusContextProvider, Consumer as FocusContextConsumer };
