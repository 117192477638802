import React from "react";
import { GitRefChip } from "../GitRefChip/GitRefChip";
import { ReleaseResource } from "client/resources";
import { FormSectionHeading, Note } from "components/form";
import Section from "components/Section";
import { useOptionalProjectContext } from "areas/projects/context";
import { IVersionControlReference, VcsRef } from "client/resources/versionControlledResource";

const ProcessSnapshot: React.FC<{ vcsRef: VcsRef }> = ({ vcsRef }) => {
    return (
        <>
            When this release was created, a snapshot of the deployment process was taken from <GitRefChip vcsRef={vcsRef} />
        </>
    );
};

export const ProcessSnapshotSection: React.FC<{ resource: IVersionControlReference }> = ({ resource }) => {
    const projectContext = useOptionalProjectContext();
    return (
        (projectContext?.state.model?.IsVersionControlled && resource && resource.VersionControlReference && (
            <div>
                <h4>Process Snapshot</h4>
                <ProcessSnapshot vcsRef={resource.VersionControlReference} />
            </div>
        )) ||
        null
    );
};

export const ProcessSnapshotFormSection: React.FC<{ release: ReleaseResource }> = ({ release }) => {
    const projectContext = useOptionalProjectContext();
    return (
        (projectContext?.state.model?.IsVersionControlled && release && release.VersionControlReference && (
            <>
                <FormSectionHeading title="Process Snapshot" />
                <Section>
                    <Note>
                        <ProcessSnapshot vcsRef={release.VersionControlReference} />
                    </Note>
                </Section>
            </>
        )) ||
        null
    );
};
