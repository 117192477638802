import * as React from "react";
import cn from "classnames";

const styles = require("./style.less");

interface DataTableProps {
    className?: string;
    title?: string;
}

export class DataTable extends React.Component<DataTableProps> {
    render() {
        const title = this.props.title ? { ["aria-label"]: this.props.title } : null;
        return (
            <table {...title} className={cn(this.props.className, styles.dataTable)}>
                {this.props.children}
            </table>
        );
    }
}
