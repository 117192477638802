import * as React from "react";
import { MultiSelect } from "components/MultiSelect";
import { FormFieldProps } from "components/form";
import ContextualMissingChip, { ChannelChip, MissingChip } from "components/Chips";
import ReferenceDataItem from "client/resources/referenceDataItem";
import { ChipIcon } from "components/Chips";
import Lookup from "../Lookup";
import { SelectItem } from "../VirtualListWithKeyboard/SelectItem";
import { useKeyedItemAccess } from "../KeyAccessProvider/KeyedItemAccessProvider";

interface ChannelMultiSelectProps extends FormFieldProps<string[]> {
    items: ReferenceDataItem[];
    openOnFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
}

const ChannelTypedMultiSelect = MultiSelect<ReferenceDataItem>();

const ChannelMultiSelect: React.FC<ChannelMultiSelectProps> = (props) => {
    const itemKey = useKeyedItemAccess();

    const chipRenderer = (r: ReferenceDataItem | SelectItem, onRequestDelete: () => void) => {
        return (
            <Lookup
                lookupCollection={props.items}
                lookupId={r[itemKey]}
                getIdFromElement={(element) => element[itemKey]}
                render={(item: ReferenceDataItem) => <ChannelChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} channelName={item.Name} />}
                renderFallback={<ContextualMissingChip lookupKey={r[itemKey]} type={ChipIcon.Channel} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete} />}
            />
        );
    };

    return <ChannelTypedMultiSelect fieldName="channels" renderChip={chipRenderer} itemKey={itemKey} {...props} />;
};

export default ChannelMultiSelect;
