import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { OverviewContainer, OverviewHighlightText } from "../../CommonComponents/CommonViews";
import { DefaultTopics } from "../../CommonComponents/DefaultViews";

export const TenantTasksRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.tenant.tasks,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return (
            <OverviewContainer>
                <OverviewHighlightText>
                    <p>
                        The <b>tasks</b> section is where you can check on the status and history of the background tasks <b>associated with this tenant</b> that your Octopus Server is managing.
                    </p>
                </OverviewHighlightText>
            </OverviewContainer>
        );
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <DefaultTopics />;
    },
};
