import * as React from "react";
import SvgImage, { SvgImageProps } from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
const styles = require("../../../primitiveComponents/dataDisplay/SvgImage/styles.less");

const ProjectVariableTemplate = (props: Partial<SvgImageProps>) => (
    <SvgImage viewBox="0 0 178 148" title="Project Variable Template" {...props}>
        <g fillRule="nonzero" strokeWidth="1">
            <path className={styles.imgDark} d="M178 133.3c0 8.1-6.6 14.7-14.7 14.7H14.7C6.6 148 0 141.4 0 133.3V14.7C0 6.6 6.6 0 14.7 0h148.7c8.1 0 14.7 6.6 14.7 14.7v118.6h-.1z" />
            <path
                className={styles.imgLight}
                d="M137 49.2c-4.3-7.6-10.6-13.8-18.1-18.1-15.9-9.3-35.6-9.3-51.5 0A50.8 50.8 0 0 0 42.2 75c0 9 2.4 17.9 7 25.7 4.3 7.6 10.6 13.9 18.1 18.2 7.8 4.6 16.7 7 25.8 7 9.1.1 18-2.4 25.8-7 7.6-4.3 13.8-10.6 18.1-18.1 4.6-7.8 7-16.7 7-25.8.1-9.1-2.4-18-7-25.8zm-57.1-2.1c1.4-2.3 3.3-4.3 5.6-5.6 4.7-2.8 10.5-2.8 15.2 0 2.3 1.4 4.3 3.3 5.6 5.6 2.8 4.7 2.8 10.5 0 15.2-1.4 2.3-3.3 4.3-5.7 5.6-4.7 2.8-10.5 2.8-15.2 0-2.3-1.4-4.3-3.3-5.6-5.6-2.7-4.7-2.7-10.5.1-15.2zm30.7 60.1c-5.3 3.1-11.4 4.7-17.5 4.6-6.1 0-12.2-1.6-17.5-4.7-5.3-2.8-9.8-6.9-13.1-11.9.1-3.1 2-6 5.8-8.6 3.2-2.2 7.3-4 12.4-5.4 4-1.1 8.2-1.8 12.4-1.8 4.2.1 8.4.7 12.4 1.8 4.4 1.1 8.6 2.9 12.5 5.4 3.8 2.6 5.7 5.5 5.8 8.6-3.4 5.1-7.9 9.2-13.2 12z"
            />
        </g>
    </SvgImage>
);
export { ProjectVariableTemplate };
