import * as React from "react";
import { Callout, CalloutType } from "primitiveComponents/dataDisplay/Callout";
import ExternalLink from "components/Navigation/ExternalLink";

export interface ImportExportCalloutProps {
    type?: ImportExportCalloutType;
}
export enum ImportExportCalloutType {
    Eap,
    PermissionRequired,
}

export default class ImportExportCallout extends React.Component<ImportExportCalloutProps> {
    render() {
        switch (this.props.type) {
            case ImportExportCalloutType.Eap:
                return (
                    <Callout type={CalloutType.Warning} title={"Early Access Feature"}>
                        The Import/Export feature copies projects and the shared resources they reference (see the <ExternalLink href="ProjectExportImport">Import/Export docs</ExternalLink> for more info) into a zip file to be imported into another
                        space or instance. We'd love to hear <ExternalLink href={"ImportExportFeedbackForm"}> your feedback</ExternalLink> after using the Import/Export feature.
                    </Callout>
                );
            case ImportExportCalloutType.PermissionRequired:
                return (
                    <Callout title="Permission Required" type={CalloutType.Information}>
                        The Space Manager permission is required to import/export projects from this space.
                    </Callout>
                );
        }
    }
}
