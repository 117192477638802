/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { each } from "lodash";
import { MachineModelHealthStatus, MachineModelHealthStatusResource } from "client/resources";

export default class MachineHealthStatusHelper {
    public static getFriendlyName(status: MachineModelHealthStatus) {
        if (status === MachineModelHealthStatus.Healthy) {
            return "Healthy";
        } else if (status === MachineModelHealthStatus.Unavailable) {
            return "Unavailable";
        } else if (status === MachineModelHealthStatus.Unknown) {
            return "Unknown";
        } else if (status === MachineModelHealthStatus.HasWarnings) {
            return "Healthy with warnings";
        } else if (status === MachineModelHealthStatus.Unhealthy) {
            return "Unhealthy";
        }
        return status;
    }

    public static getMachineModelHealthStatusResources(): MachineModelHealthStatusResource[] {
        // Could hardcode this, but if we add other enums, this future proofs it.
        const statuses: MachineModelHealthStatusResource[] = [];
        const objValues = Object.keys(MachineModelHealthStatus).map((k) => MachineModelHealthStatus[k as MachineModelHealthStatus]);
        const names = objValues.filter((v) => typeof v === "string") as string[];
        each(names, (statusText) => {
            const statusEnum = statusText as MachineModelHealthStatus;
            const name = MachineHealthStatusHelper.getFriendlyName(statusEnum);
            const status: MachineModelHealthStatusResource = {
                Id: statusEnum,
                Name: name,
            };
            statuses.push(status);
        });
        return statuses;
    }
}
