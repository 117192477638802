/* eslint-disable no-eq-null */

import * as React from "react";
import { repository } from "../../../clientInstance";
import { UpgradeNotificationMode } from "../../../client/resources/upgradeConfigurationResource";
import { NotificationType } from "../notifications.redux";
import { NotificationPoller } from "./NotificationPoller";

export default class UpdateAvailableNotificationPoller extends NotificationPoller {
    notificationId: string = "Notification/UpgradeAvailable";

    notificationCheck = async () => {
        const status = await repository.ServerStatus.getServerStatus();
        const { version } = repository.getServerInformation();
        // No upgrade available
        if (!status.IsUpgradeAvailable) {
            return this.dismissNotification();
        }

        // User has configured this notification to not show
        const config = await repository.UpgradeConfiguration.get();
        if (config.NotificationMode === UpgradeNotificationMode.NeverShow || (config.NotificationMode === UpgradeNotificationMode.ShowOnlyMajorMinor && !status.IsMajorMinorUpgrade)) {
            return this.dismissNotification();
        }

        const canUpgradeFreeOfCharge = status.MaximumAvailableVersion === status.MaximumAvailableVersionCoveredByLicense;
        const expiryMessage = status.MaintenanceExpires != null ? `expired on ${status.MaintenanceExpires}` : "has expired";

        const description = `Version ${status.MaximumAvailableVersion} of Octopus has been released
             ${
                 canUpgradeFreeOfCharge
                     ? " and you can upgrade free of charge!"
                     : `. Your license ${expiryMessage} and covers updates through to ${status.MaximumAvailableVersionCoveredByLicense}.
             Please renew your license to upgrade to ${status.MaximumAvailableVersion}.`
             }
             You currently have version ${version} installed.`;
        this.raiseNotification({
            id: this.notificationId,
            value: status.MaximumAvailableVersion,
            text: "New release available.",
            type: NotificationType.info,
            description,
            links: [
                {
                    type: "External",
                    href: `https://octopus.com/downloads/compare?from=${version}&to=${status.MaximumAvailableVersion}`,
                    label: "What's new?",
                },
                {
                    type: "External",
                    href: "ProductDownloadPage",
                    label: "Download now",
                },
            ],
        });
    };
}
