import * as React from "react";
import { RadioButton } from "components/form";
import RadioButtonGroup from "primitiveComponents/form/RadioButton/RadioButtonGroup";
import Text from "primitiveComponents/form/Text/Text";
import ParseHelper from "utils/ParseHelper/ParseHelper";
import ComponentRow from "components/ComponentRow/ComponentRow";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
const styles = require("./style.less");

interface ChangePhaseProgressionProps {
    environmentCount: number;
    isOptionalPhase: boolean;
    minimumEnvironmentsBeforePromotion: number;
    onOk(isOptionalPhase: boolean, minimumEnvironmentsBeforePromotion: number): void;
}

interface ChangePhaseProgressionState extends DataBaseComponentState {
    minimumEnvironmentsBeforePromotion: number;
    minimumType: MinimumType;
}

enum MinimumType {
    All,
    None,
    Some,
}

class MinimumTypeRadioButtonGroup extends RadioButtonGroup<MinimumType> {}

export default class ChangePhaseProgression extends DataBaseComponent<ChangePhaseProgressionProps, ChangePhaseProgressionState> {
    constructor(props: ChangePhaseProgressionProps) {
        super(props);

        let minType = MinimumType.All;
        let minEnvCount = this.props.minimumEnvironmentsBeforePromotion;

        if (this.props.minimumEnvironmentsBeforePromotion <= 0) {
            minType = MinimumType.All;
            minEnvCount = this.props.environmentCount;
        }

        if (this.props.isOptionalPhase) {
            minType = MinimumType.None;
        } else if (this.props.environmentCount > 1 && this.props.minimumEnvironmentsBeforePromotion > 0) {
            minType = MinimumType.Some;
        }

        this.state = {
            minimumType: minType,
            minimumEnvironmentsBeforePromotion: minEnvCount,
        };
    }

    onOk() {
        const isOptionalPhase = this.state.minimumType === MinimumType.None;
        const minimumEnvironmentsBeforePromotion = this.state.minimumType === MinimumType.Some ? this.state.minimumEnvironmentsBeforePromotion : 0;
        this.props.onOk(isOptionalPhase, minimumEnvironmentsBeforePromotion);
        return true;
    }

    render() {
        return (
            <MinimumTypeRadioButtonGroup
                value={this.state.minimumType}
                onChange={(val) =>
                    this.setState({ minimumType: val }, () => {
                        this.onOk();
                    })
                }
            >
                <RadioButton value={MinimumType.All} isDefault={true} label="All must complete" />
                <RadioButton value={MinimumType.Some} label={this.someLabel()} />
                <RadioButton value={MinimumType.None} label="Optional phase" />
            </MinimumTypeRadioButtonGroup>
        );
    }

    private someLabel() {
        return (
            <ComponentRow className={styles.minCompleteLabel}>
                <span>A minimum of</span>
                <div>
                    <Text
                        id="minimumEnvText"
                        className={styles.minimumEnvText}
                        type="number"
                        value={this.state.minimumEnvironmentsBeforePromotion.toString()}
                        disabled={this.state.minimumType !== MinimumType.Some}
                        min={1}
                        max={this.props.environmentCount}
                        onChange={(val) => this.handleMinValueChange(val)}
                    />
                </div>
                <span>must complete</span>
            </ComponentRow>
        );
    }

    private handleMinValueChange = (val: string) => {
        this.setState(
            {
                minimumEnvironmentsBeforePromotion: ParseHelper.safeParseInt(val),
            },
            () => this.onOk()
        );
    };
}
