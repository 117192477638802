import * as React from "react";
import { manualPackagingInstruction } from "../../Instructions";
import { CommonManualPackagingTextForNuGet, CommonManualPackagingTextForZip } from "../CommonRegistrationText";
import ExternalLink from "components/Navigation/ExternalLink";

export const ManualPackaging: React.SFC<{}> = () => (
    <div>
        <p>
            When packaging Node.js applications, we recommend using <ExternalLink href="ExternalToolOctopackJs">Octopack.js</ExternalLink>.
        </p>
        <p>
            However, if you wish to package your Node.js applications manually (on your build output), creating <i>NuGet</i> or <i>Zip</i> packages yourself is an easy way to get started.
        </p>
        {CommonManualPackagingTextForNuGet}
        {CommonManualPackagingTextForZip}
    </div>
);

export default manualPackagingInstruction(() => <ManualPackaging />);
