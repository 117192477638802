import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonEnvironmentOverview, CommonEnvironmentTopics } from "../../CommonComponents/CommonEnvironmentViews";

export const InfrastructureEnvironmentsNewRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.infrastructure.environments.create,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonEnvironmentOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonEnvironmentTopics />;
    },
};
