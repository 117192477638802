import React = require("react");
import ExternalLink from "components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
import { TopicsContainer, LearnMoreResources } from "./CommonViews";

export const CommonLibraryStepTemplateOverview: React.StatelessComponent<{}> = (props) => {
    return (
        <OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="CustomStepTemplates">Custom step templates</ExternalLink> are pre-configured steps that can be reused in multiple projects.
                </p>
                <p>
                    <ExternalLink href="CommunityContributedStepTemplates">Community step templates</ExternalLink> are contributed by the Octopus Community. If the Community library is disabled, visit the community library website to browse and
                    import step templates manually.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>
    );
};

export const CommonLibraryStepTemplateTopics: React.StatelessComponent<{}> = (props) => {
    return (
        <TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="CustomStepTemplates">Custom Step Templates</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>
    );
};
