import * as React from "react";
import { match } from "react-router";
import { ResourceCollection, PackageResource, OctopusPackageVersionBuildInformationMappedResource } from "client/resources";
import BaseComponent from "components/BaseComponent";
import InternalLink from "components/Navigation/InternalLink";
import PagingDataTable from "components/PagingDataTable";
import routeLinks from "../../../../routeLinks";
import ExternalLink from "components/Navigation/ExternalLink";
const styles = require("./style.less");

class BuildInformationResourceDataTable extends PagingDataTable<OctopusPackageVersionBuildInformationMappedResource> {}

interface LatestBuildInformationListProps {
    match: match<{}>;
    initialPackages: ResourceCollection<OctopusPackageVersionBuildInformationMappedResource>;
}

export class LatestBuildInformationList extends BaseComponent<LatestBuildInformationListProps> {
    render() {
        return (
            !!this.props.initialPackages && (
                <BuildInformationResourceDataTable
                    initialData={this.props.initialPackages}
                    onRow={(item) => this.buildRow(item)}
                    onFilter={this.filter}
                    filterSearchEnabled={true}
                    apiSearchParams={["filter"]}
                    headerColumns={["Package ID", "Highest version", "Source", "Branch", "Build"]}
                    headerColumnClassNames={["", ""]}
                    onEmpty={this.handleOnEmpty}
                />
            )
        );
    }

    private handleOnEmpty = () => {
        return <div>No build information found</div>;
    };

    private filter(filter: string, resource: OctopusPackageVersionBuildInformationMappedResource) {
        return !filter || filter.length === 0 || (resource.PackageId ? resource.PackageId.toLowerCase().includes(filter.toLowerCase()) : false);
    }

    private buildRow = (resource: OctopusPackageVersionBuildInformationMappedResource) => {
        return [
            <InternalLink to={routeLinks.library.buildInformationRepository.versions(resource.PackageId)}>{resource.PackageId}</InternalLink>,
            <div className={styles.row}>
                <InternalLink to={routeLinks.library.buildInformationRepository.package(resource.Id)}>{resource.Version}</InternalLink>
            </div>,
            resource.BuildEnvironment,
            resource.Branch,
            <ExternalLink href={resource.BuildUrl}>{resource.BuildNumber}</ExternalLink>,
        ];
    };
}

export default LatestBuildInformationList;
