import * as React from "react";
import SvgImage, { SvgImageProps } from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
const styles = require("../../../primitiveComponents/dataDisplay/SvgImage/styles.less");

const AzureWebApp = (props: Partial<SvgImageProps>) => (
    <SvgImage viewBox="0 0 58 70" title="Azure Web App" {...props}>
        <g fillRule="nonzero" strokeWidth="1">
            <path
                className={styles.imgDark}
                d="M27.333 45.748V57.5c-1.169.431-2.155 1.04-2.958 1.826-.803.786-1.345 1.673-1.625 2.66H10.312c-.791.341-1.187.846-1.187 1.514s.396 1.165 1.188 1.491H22.75c1.162 3.006 3.141 4.509 5.938 4.509 2.796 0 4.736-1.503 5.819-4.509H46.75c1-.264 1.5-.761 1.5-1.491 0-.73-.5-1.234-1.5-1.514H34.507c-.272-1.098-.735-1.985-1.389-2.66-.653-.675-1.598-1.243-2.833-1.702V45.748h-2.952z"
            />
            <g>
                <rect className={styles.imgCyan} width="56.78" height="47.81" x=".72" y=".77" rx="2.99" />
                <path
                    className={styles.imgWhite}
                    d="M23.58 18.32a28.08 28.08 0 0 1 6.13-2.5 43.5 43.5 0 0 1-1.59-7.26A16.77 16.77 0 0 0 22.49 10v.09A20.69 20.69 0 0 0 21 17.57a4.5 4.5 0 0 1 1.68.31 5.1 5.1 0 0 1 .9.44zm-7.02 5.54a4.66 4.66 0 0 1 1.87-5.54 23.2 23.2 0 0 1 .66-6.32 16.76 16.76 0 0 0-6.2 16.83 28.68 28.68 0 0 1 3.67-4.97zm15.27-8.53a27.59 27.59 0 0 1 11 .05A16.72 16.72 0 0 0 30.3 8.54a41.2 41.2 0 0 0 1.53 6.79zm1.53 24.25a24.38 24.38 0 0 1-3.48-1.7 3.18 3.18 0 0 1-4.74-3.74 23.67 23.67 0 0 1-4.81-7.2 5 5 0 0 1-1.06-.26 5.45 5.45 0 0 1-.68-.35 25.48 25.48 0 0 0-4.23 6.58c4.084 8.051 13.817 11.413 22 7.6a24 24 0 0 1-3-.93zm11.57-20.39a24.18 24.18 0 0 0-12-.86 40.31 40.31 0 0 0 4.67 8.42 3.12 3.12 0 0 1 2.15.05 3.17 3.17 0 0 1 1.81 4.12 2.06 2.06 0 0 1-.17.33c.8.82 1.62 1.62 2.48 2.37A16.88 16.88 0 0 0 45 31.4a16.7 16.7 0 0 0-.07-12.21zM39.8 32.71a3.18 3.18 0 0 1-4.17-4.1 3.26 3.26 0 0 1 .26-.53 42.56 42.56 0 0 1-5.11-9.27 24.87 24.87 0 0 0-5.29 2.08A4.67 4.67 0 0 1 23 26.53a20.8 20.8 0 0 0 4 5.73 3.15 3.15 0 0 1 2.32 0 3.2 3.2 0 0 1 2 3.4 21.27 21.27 0 0 0 3 1.48 20.63 20.63 0 0 0 5.41 1.31 16.77 16.77 0 0 0 3-3 39.16 39.16 0 0 1-2.93-2.74z"
                />
            </g>
        </g>
    </SvgImage>
);
export { AzureWebApp };
