/* eslint-disable no-restricted-imports */

import { OctopusTheme } from "components/Theme";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import { MuiTheme } from "material-ui/styles";

export function createLegacyMuiTheme(theme: OctopusTheme): MuiTheme {
    const config = {
        textField: {
            textColor: theme.primaryText,
        },
        paper: {
            textColor: theme.primaryText,
            backgroundColor: theme.paper0,
        },
        menuItem: {
            hoverColor: theme.hover,
        },
        stepper: {
            iconColor: theme.success,
            hoverBackgroundColor: theme.hover,
            inactiveIconColor: theme.secondaryText,
            textColor: theme.primaryText,
            disabledTextColor: theme.secondaryText,
            connectorLineColor: theme.secondaryText,
        },
        palette: {
            primary1Color: theme.primary,
            textColor: theme.primaryText,
            canvasColor: theme.paper0,
        },
        datePicker: {
            headerColor: theme.cyanConstant,
            color: theme.primary,
            selectColor: theme.cyanConstant,
        },
        flatButton: {
            primaryTextColor: theme.primary,
        },
    };

    return getMuiTheme(config);
}
