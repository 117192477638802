import BasicRepository, { ListArgs } from "./basicRepository";
import Client from "../client";
import { RunbookRunResource } from "client/resources";
import { TaskState } from "../resources/taskState";

type RunbookRunListArgs = {
    projects?: string[];
    environments?: string[];
    tenants?: string[];
    runbooks?: string[];
    channels?: string[];
    taskState?: TaskState;
} & ListArgs;

class RunbookRunRepository extends BasicRepository<RunbookRunResource, RunbookRunResource, RunbookRunListArgs> {
    constructor(client: Client) {
        super("RunbookRuns", client);
    }
}

export default RunbookRunRepository;
