/* eslint-disable no-restricted-imports */

import * as React from "react";
import { Text } from "components/form";
import { Dialog } from "@material-ui/core";
import { DialogLayout } from "components/DialogLayout/DialogLayout";
import ActionButton, { ActionButtonType } from "components/Button";
import KeyboardHandler, { Key } from "components/KeyboardHandler";
import { CommitMessageWithDetails } from "areas/projects/components/VersionControl/CommitMessageWithDetails";

interface CommitDialogProps {
    open: boolean;
    branchName: string;
    defaultSummary: string;
    onCloseWithoutCommit: () => void;
    onCommit: () => void;
    onCommitMessageChanged: (commitMessage: CommitMessageWithDetails) => void;
    commitMessage: CommitMessageWithDetails;
    commitMessageAccessibleName: string;
    commitDetailsAccessibleName: string;
}

export interface CommitSummaryAndDetailsProps {
    onCommitMessageChanged: (commitMessage: CommitMessageWithDetails) => void;
    commitMessage: CommitMessageWithDetails;
    defaultSummary: string;
    commitMessageAccessibleName?: string;
    commitDetailsAccessibleName?: string;
}

export const CommitSummaryAndDetails: React.FC<CommitSummaryAndDetailsProps> = ({ onCommitMessageChanged, defaultSummary, commitMessageAccessibleName, commitDetailsAccessibleName, commitMessage }) => {
    const updateSummary = (newSummary: string) => {
        onCommitMessageChanged({
            details: commitMessage.details,
            summary: newSummary,
        });
    };

    const updateDetails = (newDetails: string) => {
        onCommitMessageChanged({
            details: newDetails,
            summary: commitMessage.summary,
        });
    };

    return (
        <>
            <Text key="summary" id="summary" name="Summary" label="Summary" value={commitMessage.summary} onChange={updateSummary} placeholder={defaultSummary} autoFocus={true} accessibleName={commitMessageAccessibleName ?? "Commit summary"} />
            <Text key="details" id="details" name="Details" label="Optional description" value={commitMessage.details} onChange={updateDetails} multiline={true} accessibleName={commitDetailsAccessibleName ?? "Optional description"} />
        </>
    );
};

const CommitDialog: React.FC<CommitDialogProps> = (props) => {
    const actions = [<ActionButton type={ActionButtonType.Save} key="Commit" label="Commit" title="Commit" onClick={() => props.onCommit()} />];
    const additionalActions = [<ActionButton key="Cancel" label="Cancel" title="Cancel" onClick={() => props.onCloseWithoutCommit()} />];

    const onEnter = (event: KeyboardEvent): boolean => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const target: any = event.target ? event.target : event.srcElement;
        const tagName = target.tagName;
        if (tagName.toUpperCase() === "INPUT") {
            props.onCommit();
            return true;
        }
        return false;
    };

    const onCtrlEnter = () => {
        props.onCommit();
        return true;
    };

    const keyboardRegistrations = [
        { key: Key.Enter, onKeyPressed: onEnter },
        { key: Key.CtrlEnter, onKeyPressed: onCtrlEnter },
    ];

    return (
        <Dialog open={props.open} fullWidth>
            <KeyboardHandler registrations={keyboardRegistrations}>
                <DialogLayout title={`Enter commit details (committing to ${props.branchName})`} actions={actions} additionalActions={additionalActions} closeDialog={() => props.onCloseWithoutCommit()}>
                    <CommitSummaryAndDetails
                        onCommitMessageChanged={props.onCommitMessageChanged}
                        defaultSummary={props.defaultSummary}
                        commitMessage={props.commitMessage}
                        commitDetailsAccessibleName={props.commitDetailsAccessibleName}
                        commitMessageAccessibleName={props.commitMessageAccessibleName}
                    />
                </DialogLayout>
            </KeyboardHandler>
        </Dialog>
    );
};

export default CommitDialog;
