import React from "react";
import { IScriptActionContext, useScriptActionContext } from "./ScriptActionContext";
import { createHookHOC } from "utils/createHookHOC";

export type WithScriptActionContextInjectedProps = {
    scriptActionContext: IScriptActionContext | undefined;
};

export const mapScriptActionContextToInjectedProps = (context: IScriptActionContext | undefined) => ({ scriptActionContext: context });

export const withScriptActionContext = createHookHOC(useScriptActionContext, "WithScriptActionContext");
