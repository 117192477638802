import React = require("react");
import ExternalLink from "components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";

export const CommonProjectRunbookSnapshotOverview: React.StatelessComponent<{}> = (props) => {
    return (
        <OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="OnboardingRunbooksLearnMore">Runbook Snapshots</ExternalLink> are a snapshot of your runbook process, configuration variables, and software packages. A snapshot can be run to any available environment.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>
    );
};
