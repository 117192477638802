import * as React from "react";
import SvgImage, { SvgImageProps } from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
const styles = require("../../../primitiveComponents/dataDisplay/SvgImage/styles.less");

const AzureCloudService = (props: Partial<SvgImageProps>) => (
    <SvgImage viewBox="0 0 58 70" title="Azure Vm" {...props}>
        <g id="azure-cloud-service" fillRule="nonzero" strokeWidth="1">
            <path
                className={styles.imgDark}
                d="M27.333 45.748V57.5c-1.169.431-2.155 1.04-2.958 1.826-.803.786-1.345 1.673-1.625 2.66H10.312c-.791.341-1.187.846-1.187 1.514s.396 1.165 1.188 1.491H22.75c1.162 3.006 3.141 4.509 5.938 4.509 2.796 0 4.736-1.503 5.819-4.509H46.75c1-.264 1.5-.761 1.5-1.491 0-.73-.5-1.234-1.5-1.514H34.507c-.272-1.098-.735-1.985-1.389-2.66-.653-.675-1.598-1.243-2.833-1.702V45.748h-2.952z"
            />
            <rect className={styles.imgCyan} width="56.94" height="47.95" x=".48" y=".57" rx="3" />
            <path className={styles.imgWhite} d="M35 19.07a2.05 2.05 0 1 1-.02 4.1 2.05 2.05 0 0 1 .02-4.1M24.46 26a2.06 2.06 0 1 0 2 2.06 2.12 2.12 0 0 0-2-2.06" />
            <path
                className={styles.imgWhite}
                d="M44.65 20l-.79-.29.2-.78a9.51 9.51 0 0 0 .29-2.35 9.41 9.41 0 0 0-18.09-3.62l-.59 1.27-.88-1.07a6.45 6.45 0 0 0-11.34 4.2c.013.591.077 1.18.19 1.76l.2.78-.78.3A7.27 7.27 0 0 0 8 27a7.48 7.48 0 0 0 7.73 7.33h1.95c-.1 0-.1.68-.1 1.08a6.86 6.86 0 0 0 6.85 6.84 7 7 0 0 0 6.36-4.2l.39-1 .68.78a4.63 4.63 0 0 0 3.52 1.66 4.76 4.76 0 0 0 4.7-4.69v-.49h2.12A7.49 7.49 0 0 0 49.93 27a7.1 7.1 0 0 0-5.28-7zm-26.51 7.31l1.76-.59.39-1-.68-1.46-.1-.2 1-1 1.66.78 1-.39.49-1.37.1-.09h1.46l.59 1.56 1 .39 1.47-.78.19-.1 1 1-.79 1.66.4 1 1.37.49.1.1v1.46l-1.57.49-.39 1 .78 1.47.1.19-1 1-1.66-.68-1 .39-.49 1.56-.1.2h-1.5l-.72-1.8-1-.39-1.46.68-.2.1-1-1 .78-1.66-.39-1-1.56-.49-.19-.1v-1.42h.16zm23.38-5.48l-1.86.59-.49 1 .68 1.47.1.2-1 1-1.66-.69-1 .39-.49 1.57-.09.19h-1.43l-.59-1.76-1-.39-1.44.6-.2.09-1-1 .79-1.66-.39-1-1.26-.43v-1.54l1.47-.59.29-1-.78-1.47-.1-.2 1-1 1.66.78 1-.39.49-1.47.1-.1h1.47l.58 1.66 1 .3 1.47-.78.19-.1 1 1-.78 1.66.39 1 1.66.49.3.09-.08 1.49z"
            />
        </g>
    </SvgImage>
);
export { AzureCloudService };
