import { AnalyticFields, AnalyticsEnvironment, AnalyticSession } from "./AnalyticSession";
import amplitude from "amplitude-js";
import jsSHA from "jssha";
import { NullSession } from "./NullSession";

interface EnvironmentInfo {
    endpoint: string | null;
    token: string;
}

const getEnvironmentInfo = (mode: AnalyticsEnvironment): EnvironmentInfo | null => {
    switch (mode) {
        case AnalyticsEnvironment.ProductionCloud:
            return { endpoint: "telemetry.octopus.com/api/ProcessEvent", token: "DUMMY-TOKEN" };
        case AnalyticsEnvironment.Development:
            // To send data to Amplitude in Development mode either supply a project API token, eg "c18357a114fe954c396ca0c12bd19d47", or fire up another Azure function and provide the endpoint as for Production.
            // By default, even though Amplitude is "Enabled" by default in Development mode, it will do nothing unless it is compiled with a developer supplied value here.
            // Supplying an endpoint will use that as a proxy.  Octopus uses an Azure function endpoint, which will override the token and use the one configured.  Leave this as null to talk directly to Amplitude
            // DO NOT use the production endpoint as the user data will pollute the analytic data making it harder to use.

            //return { endpoint: "teleproxy-test.azurewebsites.net/api/ProcessEvent", token: "DUMMY-TOKEN" }; // Use this to send events through the azure test function
            return { endpoint: null, token: "" };
    }

    return null;
};

function Prop(name: string, value?: string): object {
    return value !== undefined ? { [name]: value } : {};
}

export class AmplitudeSession implements AnalyticSession {
    private api: amplitude.AmplitudeClient;

    private constructor(public readonly environment: AnalyticsEnvironment, info: EnvironmentInfo, installationId: string, userId: string, userName: string, emailAddress?: string) {
        const sha = new jsSHA("SHA-1", "TEXT", { encoding: "UTF8" });
        sha.update(emailAddress ?? installationId + "-" + userId);
        const analyticsUserId = sha.getHash("HEX");

        this.api = amplitude.getInstance();
        if (info.endpoint !== null) {
            this.api.options.apiEndpoint = info.endpoint;
        }
        this.api.init(info.token);
        this.api.setUserId(analyticsUserId);
        this.api.setUserProperties({ "Installation ID": installationId });
    }

    public static Start(environment: AnalyticsEnvironment, installationId: string, userId: string, userName: string, emailAddress?: string): AnalyticSession {
        const info = getEnvironmentInfo(environment);
        if (!info) {
            throw new Error("Invalid AnalyticsEnvironment for Amplitude");
        }

        // Must supply an endpoint or token to use amplitude
        if (info.endpoint === null && info.token === "") {
            return new NullSession(environment);
        }

        return new AmplitudeSession(environment, info, installationId, userId, userName, emailAddress);
    }

    track(name: string, event: AnalyticFields): void {
        this.api.logEvent(name, { ...event });
    }

    end() {
        try {
            this.api.setUserId(null);
            this.api.regenerateDeviceId();
        } catch (err) {
            console.error(`(Amplitude) error in 'reset()'`);
            console.error(err);
        }
    }
}
