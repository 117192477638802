import { PackagingRegistration } from "../../Registry/packagingRegistry";

import BuildServerPackagingInstruction from "./BuildServerPackagingInstruction";
import ManualPackagingInstruction from "./ManualPackagingInstruction";
import CommandLinePackagingInstruction from "./CommandLinePackagingInstruction";

export const OtherPackagingRegistration: PackagingRegistration = {
    displayOrder: 600,
    instructions: [BuildServerPackagingInstruction, ManualPackagingInstruction, CommandLinePackagingInstruction],
    name: "Other",
    id: "Other",
};

export default OtherPackagingRegistration;
