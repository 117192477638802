import * as React from "react";
import { PermissionCheck } from "components/PermissionCheck";
import { IsNonVcsRunbook, Permission } from "client/resources";
import routeLinks from "routeLinks";
import { NavigationButtonType, NavigationButton } from "components/Button";
import { useRunbookContext } from "./RunbookContext";
import { useProjectContext } from "areas/projects/context";

export type RunNowButtonProps = {
    projectSlug: string;
    projectId: string;
    runbookId: string;
    isDisabled?: boolean;
};

export const RunNowButton: React.FC<RunNowButtonProps> = ({ projectSlug, isDisabled, projectId, runbookId }) => {
    return (
        <PermissionCheck permission={Permission.RunbookRunCreate} project={projectId} wildcard={true}>
            <NavigationButton type={NavigationButtonType.CreateRelease} label="Run..." href={routeLinks.project(projectSlug).operations.runbook(runbookId).runNow.root} disabled={isDisabled} />
        </PermissionCheck>
    );
};

type ContextualRunNowButtonProps = Omit<RunNowButtonProps, "projectId" | "projectSlug" | "runbookId">;
export const ContextualRunNowButton: React.FC<ContextualRunNowButtonProps> = (props) => {
    const {
        state: { runbook },
    } = useRunbookContext();
    const {
        state: { model: project },
    } = useProjectContext();

    if (!project || !runbook) {
        return null;
    }

    if (!IsNonVcsRunbook(runbook)) {
        // FIXME: CaC-runbooks Running Runbooks is not supported yet for VCS Runbooks
        return <></>;
    }
    return <RunNowButton projectSlug={project.Slug} projectId={runbook.ProjectId} runbookId={runbook.Id} {...props} />;
};

export default ContextualRunNowButton;
