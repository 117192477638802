import * as React from "react";
import Lookup, { LookupProps } from "../Lookup/Lookup";
import NamedResource from "client/resources/namedResource";
import ContextualMissingChip, { ChipIcon } from "components/Chips";
import { useKeyedItemAccess } from "../KeyAccessProvider/KeyedItemAccessProvider";

interface LookupResourceChipProps<T extends NamedResource> {
    lookupCollection: T[];
    lookupId: string;
    type: ChipIcon;
    chipRender: (item: T) => React.ReactElement;
}

function KeyedAccessLookupResourceChip<T extends NamedResource>({ type, lookupId, chipRender, lookupCollection }: LookupResourceChipProps<T>) {
    const key = useKeyedItemAccess();
    const getIdFromElement: LookupProps<T>["getIdFromElement"] = React.useCallback((item) => item[key], [key]);
    const renderFallback = React.useMemo(() => <ContextualMissingChip lookupKey={lookupId} type={type} />, [lookupId, type]);

    return <Lookup lookupCollection={lookupCollection} lookupId={lookupId} getIdFromElement={getIdFromElement} render={chipRender} renderFallback={renderFallback} />;
}

export default KeyedAccessLookupResourceChip;
