/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { repository } from "clientInstance";
import PaperLayout from "components/PaperLayout";
import Section from "components/Section";
import Markdown from "components/Markdown/index";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import EventListing from "components/EventListing/EventListing";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import { Permission } from "client/resources/permission";
import PermissionCheck, { isAllowed } from "components/PermissionCheck/PermissionCheck";
import OverflowMenu, { OverflowMenuItems } from "components/Menu/OverflowMenu";
import { Callout, CalloutType } from "primitiveComponents/dataDisplay/Callout/Callout";
import {
    RunbookSnapshotResource,
    EnvironmentResource,
    EventResource,
    ArtifactResource,
    RunbookRunResource,
    ResourceCollection,
    ProjectResource,
    PackageReferenceNamesMatch,
    EventCategoryResource,
    ProcessType,
    RunbookRunTemplateResource,
    IsNonVcsRunbook,
} from "client/resources";
import { ResourcesById } from "client/repositories/basicRepository";
import * as _ from "lodash";
import { FormSectionHeading, Note } from "components/form";
import StringHelper from "utils/StringHelper";
import ActionList from "components/ActionList/ActionList";
import routeLinks from "routeLinks";
import { RouteComponentProps } from "react-router";
import isBound from "components/form/BoundField/isBound";
import { PackageNote } from "client/repositories/packageRepository";
import ArtifactLink from "areas/tasks/components/Task/Artifacts/ArtifactLink";
const styles = require("./RunbookSnapshotInfo.less");
import { List } from "components/List/List";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import TimeFromNowLabel from "components/TimeLabels/TimeFromNowLabel";
import DateFormatter from "utils/DateFormatter/DateFormatter";
import InternalRedirect from "components/Navigation/InternalRedirect";
import NavigationButton from "components/Button/NavigationButton";
import { PackageModel } from "../../Releases/packageModel";
import { buildPartialReleaseNotes } from "../../Releases/releaseNoteHelper";
import { DeploymentCreateGoal } from "../../Releases/ReleasesRoutes/releaseRouteLinks";
import PackagesList from "../../Releases/PackagesList/PackagesList";
import { RunbookRouteProps } from "./RunbookSnapshots";
import { WithProjectContextInjectedProps, withProjectContext } from "areas/projects/context";
import { withRunbookContext, WithRunbookContextInjectedProps } from "../RunbookContext";
import { isEqual, compact } from "lodash";
import UpdateVariables from "../../Releases/UpdateVariables/UpdateVariables";
import VariableSnapshot from "../../Releases/VariableSnapshot/VariableSnapshot";
import PublishSnapshotDialog from "../PublishSnapshotDialog/PublishSnapshotDialog";
import { RunbookSnapshotPublishedChip } from "components/Chips";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import { publishingExplainedElement } from "../PublishButton";
import ToolTip from "primitiveComponents/dataDisplay/ToolTip/ToolTip";
import { isRunbookConsumerTryingToRunAnUnpublishedSnapshot, isRunbookConsumerOnly } from "../RunbookOverviewLayout";
import { AuditTrailLink, CsvSeparated, ModifiedProperty } from "../../Releases/Deployments/Create";
import { ProjectRouteParams } from "areas/projects/components/ProjectsRoutes/ProjectRouteParams";

interface RunbookSnapshotInfoState extends DataBaseComponentState {
    project: ProjectResource;
    runbookSnapshot: RunbookSnapshotResource;
    showUnblockRunbookSnapshotDialog: boolean;
    environmentsById: ResourcesById<EnvironmentResource>;
    packages: PackageModel[];
    events: ResourceCollection<EventResource>;
    eventCategories: EventCategoryResource[];
    artifacts: ResourceCollection<ArtifactResource>;
    runbookRuns: RunbookRunResource[];
    showFullNotes: boolean;
    isInitialLoad: boolean;
    currentPageIndex?: number;
    currentSkip: number;
    variableSnapshotRefreshKey: string;
    deleted: boolean;
    runbookRunTemplate: RunbookRunTemplateResource;
}

class ArtifactsList extends List<ArtifactResource> {}

type RunbookSnapshotInfoProps = RouteComponentProps<ProjectRouteParams & RunbookRouteProps & { runbookSnapshotId: string }> & WithRunbookContextInjectedProps & WithProjectContextInjectedProps;

class RunbookSnapshotInfoInternal extends DataBaseComponent<RunbookSnapshotInfoProps, RunbookSnapshotInfoState> {
    private packageResolveMessage: string = "Package will be resolved during runbook run";

    constructor(props: RunbookSnapshotInfoProps) {
        super(props);
        this.state = {
            project: null!,
            runbookSnapshot: null!,
            showUnblockRunbookSnapshotDialog: false,
            environmentsById: null!,
            packages: [],
            events: null!,
            eventCategories: null!,
            artifacts: null!,
            runbookRuns: [],
            showFullNotes: false,
            isInitialLoad: true,
            currentPageIndex: 0,
            currentSkip: 0,
            variableSnapshotRefreshKey: DateFormatter.timestamp(),
            deleted: false,
            runbookRunTemplate: null!,
        };
    }

    async componentDidMount() {
        await this.reload();
    }

    async componentDidUpdate(prevProps: RunbookSnapshotInfoProps) {
        const nextRunbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        const currentRunbook = prevProps.runbookContext.state && prevProps.runbookContext.state.runbook;
        if (!isEqual(currentRunbook, nextRunbook)) {
            await this.reload();
        }
    }

    reload = async () => {
        const project = this.props.projectContext.state && this.props.projectContext.state.model;
        if (!project) {
            return;
        }

        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        if (!runbook) {
            return;
        }

        return this.doBusyTask(async () => {
            const runbookSnapshot = await repository.RunbookSnapshots.get(this.props.match.params.runbookSnapshotId);
            const environmentsById = isAllowed({ permission: Permission.EnvironmentView, wildcard: true }) ? await repository.Environments.allById() : null!;

            await this.init(project, runbookSnapshot);
            this.setState(await this.refreshActiveComponents(project, runbookSnapshot, environmentsById));
        });
    };

    publishSnapshot = () => this.state.runbookSnapshot && this.props.runbookContext.actions.publishSnapshot(this.state.runbookSnapshot);

    render() {
        const project = this.props.projectContext.state && this.props.projectContext.state.model;
        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        if (!project || !runbook) {
            return <PaperLayout busy={true} errors={this.errors} />;
        }

        if (!IsNonVcsRunbook(runbook)) {
            throw new Error("FIXME: cac-runbook: Snapshotting runbooks not supported yet for VCS runbooks");
        }

        const runbookLinks = routeLinks.project(this.props.match.params.projectSlug).operations.runbook(this.props.match.params.runbookId);
        if (this.state.deleted) {
            return <InternalRedirect to={runbookLinks.runbookSnapshots} push={true} />;
        }

        const runbookSnapshotLinks = runbookLinks.runbookSnapshot(this.props.match.params.runbookSnapshotId);
        const overflowActions = [];
        if (this.state.project) {
            overflowActions.push(OverflowMenuItems.navItem("Edit", runbookSnapshotLinks.edit, null!, { permission: Permission.RunbookEdit, project: this.state.project.Id, wildcard: true }));
        }
        if (this.state.runbookSnapshot) {
            overflowActions.push(
                OverflowMenuItems.dialogItem(
                    "Update Variables",
                    <UpdateVariables
                        processType={ProcessType.Runbook}
                        onUpdateVariablesClicked={async () => {
                            const runbookSnapshot = await repository.RunbookSnapshots.snapshotVariables(this.state.runbookSnapshot);
                            this.setState({ runbookSnapshot, variableSnapshotRefreshKey: DateFormatter.timestamp() });
                        }}
                    />,
                    {
                        permission: Permission.RunbookEdit,
                        project: this.state.project && this.state.project.Id,
                        wildcard: true,
                    }
                )
            );
            overflowActions.push(
                OverflowMenuItems.deleteItemDefault(
                    "runbook snapshot",
                    this.handleDeleteConfirm,
                    {
                        permission: Permission.RunbookEdit,
                        project: this.state.project && this.state.project.Id,
                        wildcard: true,
                    },
                    "The runbook snapshot and any of its runs will be permanently deleted and they will disappear from all dashboards."
                )
            );
            overflowActions.push([
                OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([this.state.runbookSnapshot.Id]), null!, {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]);
        }
        const actions = [];
        if (this.state.project) {
            if (this.state.runbookSnapshot) {
                if (!runbook.PublishedRunbookSnapshotId || (runbook.PublishedRunbookSnapshotId && runbook.PublishedRunbookSnapshotId !== this.state.runbookSnapshot.Id)) {
                    actions.push(
                        <PermissionCheck permission={Permission.RunbookEdit} project={this.state.project.Id} wildcard={true}>
                            <ToolTip content={publishingExplainedElement}>
                                <OpenDialogButton label="Publish..." type={ActionButtonType.Ternary}>
                                    <PublishSnapshotDialog onPublishSnapshotDialogClicked={this.publishSnapshot} />
                                </OpenDialogButton>
                            </ToolTip>
                        </PermissionCheck>
                    );
                }
            }
            if (!isRunbookConsumerTryingToRunAnUnpublishedSnapshot(project, runbook, this.state.runbookSnapshot && this.state.runbookSnapshot.Id)) {
                actions.push(
                    <PermissionCheck permission={Permission.RunbookRunCreate} project={this.state.project.Id} wildcard={true}>
                        <NavigationButton label="Run..." href={runbookSnapshotLinks.runbookRuns.create(DeploymentCreateGoal.To)} />
                    </PermissionCheck>
                );
            }
        }
        if (this.state.runbookSnapshot) {
            actions.push(<OverflowMenu menuItems={overflowActions} />);
        }
        const sectionControl = <ActionList actions={actions} />;

        return (
            <PaperLayout title={this.runbookSnapshotTitle()} breadcrumbTitle={`${runbook && runbook.Name} snapshots`} breadcrumbPath={runbookLinks.runbookSnapshots} busy={this.state.busy} errors={this.errors} sectionControl={sectionControl}>
                <div className={styles.runbookSnapshotDetailsLayout}>
                    {this.state.runbookSnapshot && (
                        <div className={styles.runbookSnapshotDetailsLayoutContent}>
                            {this.state.runbookSnapshot && this.state.runbookSnapshot.Notes && this.getRunbookSnapshotNoteSection()}
                            <FormSectionHeading key="packages" title="Packages" />
                            <Section key="sectionPackages" sectionHeader="">
                                <PermissionCheck
                                    permission={Permission.FeedView}
                                    alternate={
                                        <Callout type={CalloutType.Information} title={"Permission required"}>
                                            The {Permission.FeedView} permission is required to view packages
                                        </Callout>
                                    }
                                >
                                    <PermissionCheck
                                        permission={Permission.RunbookView}
                                        project={this.state.runbookSnapshot.ProjectId}
                                        wildcard={true}
                                        alternate={
                                            <Callout type={CalloutType.Information} title={"Permission required"}>
                                                The {Permission.RunbookView} permission is required to view packages
                                            </Callout>
                                        }
                                    >
                                        <div className={styles.runbookSnapshotPackagesLayout}>
                                            <PackagesList packages={this.state.packages} buildInformation={null} />
                                        </div>
                                    </PermissionCheck>
                                </PermissionCheck>
                            </Section>
                            {/*Include the VariableSnapshot outside of the Section so that the table can extend to the edge of the paper element*/}
                            {this.state.runbookSnapshot && (
                                <PermissionCheck permission={Permission.VariableView} project={this.state.runbookSnapshot.ProjectId} wildcard={true}>
                                    <VariableSnapshot
                                        projectId={this.state.runbookSnapshot.ProjectId}
                                        snapshot={this.state.runbookSnapshot}
                                        doBusyTask={this.doBusyTask}
                                        updateVariablesRefreshKey={this.state.variableSnapshotRefreshKey}
                                        onUpdate={this.reload}
                                    />
                                </PermissionCheck>
                            )}
                            {this.state.artifacts && (
                                <>
                                    <FormSectionHeading key="artifacts" title="Artifacts" />
                                    <div className={styles.runbookSnapshotArtifactsLayout}>
                                        <ArtifactsList
                                            initialData={this.state.artifacts}
                                            onRow={(artifact: ArtifactResource) => (
                                                <>
                                                    <ArtifactLink artifact={artifact} key="link" />
                                                    <div key="time" className={styles.time}>
                                                        <TimeFromNowLabel time={artifact.Created} />
                                                    </div>
                                                </>
                                            )}
                                            showPagingInNumberedStyle={true}
                                            currentPageIndex={this.state.currentPageIndex}
                                            onPageSelected={this.handleArtifactsPageSelected}
                                            empty={
                                                <Note>
                                                    No artifacts have been added. Learn more about <ExternalLink href="Artifacts">collecting artifacts</ExternalLink>.
                                                </Note>
                                            }
                                        />
                                    </div>
                                </>
                            )}
                            <FormSectionHeading key="runbookRunHistory" title="Run history" />
                            <PermissionCheck
                                permission={Permission.RunbookRunView}
                                project={this.state.runbookSnapshot.ProjectId}
                                wildcard={true}
                                alternate={
                                    <Callout type={CalloutType.Information} title={"Permission required"}>
                                        The {Permission.RunbookRunView} permission is required to view the runbook run history
                                    </Callout>
                                }
                            >
                                <EventListing data={this.state.events} regarding={[this.state.runbookSnapshot.Id]} eventCategories={this.state.eventCategories} />
                            </PermissionCheck>
                        </div>
                    )}
                </div>
            </PaperLayout>
        );
    }

    private async init(project: ProjectResource, runbookSnapshot: RunbookSnapshotResource) {
        const runbookProcess = isAllowed({ permission: Permission.RunbookView, project: project.Id, wildcard: true }) ? await repository.RunbookProcess.get(runbookSnapshot.FrozenRunbookProcessId) : null;
        const template = runbookProcess && (await repository.RunbookProcess.getRunbookSnapshotTemplate(runbookProcess, runbookSnapshot.Id));

        const allPackages = template
            ? compact(
                  template.Packages.map((packageTemplate) => {
                      const selectionForStep = runbookSnapshot.SelectedPackages.find(
                          (selected) => selected.ActionName === packageTemplate.ActionName && PackageReferenceNamesMatch(selected!.PackageReferenceName!, packageTemplate.PackageReferenceName!)
                      );

                      if (selectionForStep) {
                          return {
                              ActionName: packageTemplate.ActionName,
                              PackageId: packageTemplate.PackageId,
                              PackageReferenceName: packageTemplate.PackageReferenceName!,
                              ProjectName: packageTemplate.ProjectName,
                              FeedName: packageTemplate.FeedName,
                              FeedId: packageTemplate.FeedId,
                              Version: (selectionForStep as any).Version,
                              Notes: {
                                  Notes: null!,
                                  Succeeded: true,
                                  FailureReason: null!,
                              },
                          };
                      }
                  })
              )
            : [];

        this.setState(() => {
            return { packages: allPackages };
        });

        if (isAllowed({ permission: Permission.FeedView, project: project.Id, wildcard: true })) {
            this.buildPackages(allPackages);
        }
    }

    private async refreshActiveComponents(project: ProjectResource, runbookSnapshot: RunbookSnapshotResource, environmentsById: ResourcesById<EnvironmentResource>) {
        const [artifacts, events, eventCategories, runbookRunsCollection, runbookRunTemplate] = await Promise.all([
            this.loadArtifactsPromise(runbookSnapshot, this.state.currentSkip)!,
            isAllowed({ permission: Permission.EventView, project: project.Id, wildcard: true }) ? repository.Events.list({ regarding: [runbookSnapshot.Id] }) : null!,
            repository.Events.categories({}),
            isAllowed({ permission: Permission.RunbookRunView, project: project.Id, wildcard: true }) ? repository.RunbookSnapshots.getRunbookRuns(runbookSnapshot, { take: 1000 }) : null!,
            repository.RunbookSnapshots.getRunbookRunTemplate(runbookSnapshot),
        ]);

        const runbookRuns = runbookRunsCollection && runbookRunsCollection.Items;

        const resultForState: RunbookSnapshotInfoState = {
            ...this.state,
            project,
            runbookSnapshot,
            environmentsById,
            artifacts,
            events,
            eventCategories,
            runbookRuns,
            runbookRunTemplate,
            isInitialLoad: false,
        };

        return resultForState;
    }

    private buildPackages(packages: PackageModel[]) {
        const grouped: { [feedId: string]: PackageModel[] } = _.groupBy(packages, (pkg) => {
            return pkg.FeedId;
        });
        for (const feedId in grouped) {
            if (isBound(feedId, false)) {
                grouped[feedId].forEach((p) => (p.Notes = { Succeeded: true, Notes: this.packageResolveMessage, FailureReason: null! }));
            } else {
                this.loadPackages(grouped[feedId]);
            }
        }
    }

    private loadPackages(allPackages: PackageModel[]) {
        // Create an object with two arrays, one containing bound packages, and the
        // other containing unbound packages.
        const boundUnbound = allPackages.reduce<{ bound: PackageModel[]; unBound: PackageModel[] }>(
            (container, pkg) => {
                (isBound(pkg.PackageId, false) ? container.bound : container.unBound).push(pkg);
                return container;
            },
            { bound: [], unBound: [] }
        );

        // Bound packages all get a standard runbookSnapshot notes string
        this.setState((existingState) => {
            boundUnbound.bound.forEach((bound) => (bound.Notes.Notes = this.packageResolveMessage));
            return {
                packages: [..._.differenceWith(existingState.packages, boundUnbound.bound, this.packageNoteEquals), ...boundUnbound.bound],
            };
        });

        // This is how many concurrent packages to query with each request. This
        // is based on a fixed number of concurrent requests, which is limited
        // by the browser. Chrome has a limit of 5, but we need to leave a few requests
        // for other calls made by this page, so we devote 3 to the runbookSnapshot notes.
        // We limit the batch size to 30 though. If there are hundreds of packages
        // we don't want to max out the URL length.
        const requests = Math.min(Math.ceil(boundUnbound.unBound.length / 3), 30);

        // This is the array that will hold chunks of the allPackages array. So an
        // array of PackageModel[30] becomes PackageModel[3][10] (roughly speaking).
        const splitAllPackages: PackageModel[][] = [];

        // Split the original array into a bunch of smaller arrays which will be
        // processed in parallel.
        while (boundUnbound.unBound.length > 0) {
            splitAllPackages.push(boundUnbound.unBound.splice(0, requests));
        }

        // Now process each group of packages with a concurrent request
        splitAllPackages.forEach((pkgs) => {
            repository.Packages.getNotes(pkgs)
                .then((pkgsDetails) => {
                    this.setState((existingState) => {
                        // for every package that was returned, update the existing package
                        // with the returned notes.
                        const updated = existingState.packages.map((existing) => _.assign(existing, this.findMatchingNotesPackage(existing, pkgsDetails.Packages)));

                        return {
                            packages: updated,
                        };
                    });
                })
                .catch((err) => {
                    this.setState((existingState) => {
                        // for every package that was requested, set the state to error.
                        const updated = existingState.packages.map((existing) => _.assign(existing, this.findMatchingNotesPackage(existing, pkgs, { Notes: { Success: false, FailureMessage: err.ErrorMessage } })));

                        return {
                            packages: updated,
                        };
                    });
                });
        });
    }

    /**
     * Finding runbookSnapshot notes in a bulk fashion from the server means:
     * 1. Requesting the package details (in a request with a bunch of other packages)
     * 2. Assigning the returned details back to the matching packages from the state
     * 3. Optionally setting the some additional field, typically when a batch request failed and all packages need to show an error
     * This function will attempt to find a matching package from the list of returned packages, and if so assign the values from 3
     * to it, and then return it. Otherwise it will return an empty object. The returned object is expected to be assigned to
     * the package in the state to result in an updated package object that can be displayed to the user.
     * @param {PackageNote} original The original package details to match against the package returned by the server
     * @param {PackageNote[]} packages The list of packages returned by the server
     * @param assign An object that is assigned to the matching package, if one was found. It is like an "overlay" on matching packages.
     * @returns {(PackageNote | undefined) | {}} An empty object if no match was found, and the returned package
     * with the assign object assigned to it.
     */
    private findMatchingNotesPackage(original: PackageNote, packages: PackageNote[], assign: any = null) {
        const packageWithNotes = packages.find((pkgWithNotes) => this.packageNoteEquals(pkgWithNotes, original));
        if (packageWithNotes) {
            if (assign) {
                _.assign(packageWithNotes, assign);
            }
        }
        return packageWithNotes || {};
    }

    private packageNoteEquals(a: PackageNote, b: PackageNote) {
        return a.PackageId === b.PackageId && a.Version === b.Version && a.FeedId === b.FeedId;
    }

    private runbookSnapshotTitle(): React.ReactNode {
        const runbook = this.props.runbookContext.state.runbook;
        if (runbook && !IsNonVcsRunbook(runbook)) {
            throw new Error("FIXME: cac-runbook: Snapshots not supported yet for VCS runbooks");
        }
        const publishedChip = runbook && this.state.runbookSnapshot && this.state.runbookSnapshot.Id === runbook.PublishedRunbookSnapshotId && (
            <>
                &nbsp;
                <RunbookSnapshotPublishedChip />
            </>
        );
        return this.state.runbookSnapshot
            ? this.state.runbookSnapshot && (
                  <>
                      {this.state.runbookSnapshot.Name}
                      {publishedChip}
                  </>
              )
            : StringHelper.ellipsis;
    }

    private buildNotes() {
        if (this.state.showFullNotes) {
            return <Markdown markup={this.state.runbookSnapshot.Notes} />;
        }
        const [runbookSnapshotNotes, isTruncated] = buildPartialReleaseNotes(this.state.runbookSnapshot.Notes, 10);
        return (
            <div>
                <Markdown markup={runbookSnapshotNotes} />
                {isTruncated && <ActionButton type={ActionButtonType.Ternary} onClick={() => this.setState({ showFullNotes: true })} label="show more" />}
            </div>
        );
    }

    private getRunbookSnapshotNoteSection() {
        return [
            <FormSectionHeading key="runbookSnapshotNoteHeading" title="Notes" />,
            <Section key="runbookSnapshotSection" sectionHeader="">
                <div className={styles.runbookSnapshotNoteLayout}>{this.buildNotes()}</div>
            </Section>,
        ];
    }

    private loadArtifactsPromise = (runbookSnapshot: RunbookSnapshotResource, skip: number) => {
        return isAllowed({ permission: Permission.ArtifactView, wildcard: true }) ? repository.Artifacts.list({ regarding: runbookSnapshot.Id, skip, take: 10, order: "asc" }) : null;
    };

    private handleArtifactsPageSelected = async (skip: number, p: number) => {
        this.setState({ currentPageIndex: p, currentSkip: skip });
        this.setState({ artifacts: await this.loadArtifactsPromise(this.state.runbookSnapshot, skip)! });
    };

    private handleDeleteConfirm = async (): Promise<boolean> => {
        if (this.state.runbookSnapshot) {
            await repository.RunbookSnapshots.del(this.state.runbookSnapshot);
            this.setState({ deleted: true });
            return true;
        } else {
            return false;
        }
    };
}

export default withRunbookContext(withProjectContext(RunbookSnapshotInfoInternal));
