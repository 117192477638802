import React from "react";
import { useDispatch } from "react-redux";
import { ExpanderActions, expanderActions } from "components/form/Sections/reducers/expanders";
import { Dispatch } from "redux";
import { useContextualExpandableContainer } from "./Expandable";

const ExpandAllExpanders: React.FC<{ containerKey?: string }> = (props) => {
    const dispatch = useDispatch<Dispatch<ExpanderActions>>();
    const container = useContextualExpandableContainer();

    React.useEffect(() => {
        dispatch(expanderActions.onToggleAll({ containerKey: props.containerKey ?? container, expanded: true }));
    }, [props.containerKey, container, dispatch]);

    return <>{props.children}</>;
};

export default React.memo(ExpandAllExpanders);
