import { Switch } from "react-router-dom";
import ReloadableRoute from "components/ReloadableRoute";
import routeLinks from "routeLinks";
import AuthenticationLayout from "areas/authentication/AuthenticationLayout";
import SignIn from "areas/authentication/SignIn";
import SignOut from "areas/authentication/SignOut";
import Register from "areas/authentication/Register";
import SecureRoute from "components/SecureRoute";
import { StandardLayout } from "components/StandardLayout";
import * as React from "react";
import { useState } from "react";
import { FeaturesConfigurationResource, UserResource } from "client/resources";
import { client } from "clientInstance";
import { AnalyticSessionProvider, getAnalyticSession, DefaultSession } from "analytics/AnalyticSession";

type SessionStartEvent = (user: UserResource, features: FeaturesConfigurationResource) => Promise<void>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function AuthenticationRoutes(props: React.ComponentProps<any>) {
    const [analyticsSession, setAnalyticsSession] = useState(DefaultSession);

    const onSessionStart: SessionStartEvent = async (user, features) => {
        if (analyticsSession === DefaultSession) {
            const serverInfo = client.tryGetServerInformation();
            if (serverInfo) {
                const session = await getAnalyticSession(serverInfo.installationId, user.Id, user.Username, user.EmailAddress);
                setAnalyticsSession(session);
                // session.track("Sign In", {});
            }
        }
    };

    const onSessionEnd = () => {
        analyticsSession.track("Sign Out", {});
        analyticsSession.end();
        setAnalyticsSession(DefaultSession);
    };

    return (
        <AnalyticSessionProvider session={analyticsSession}>
            <Switch>
                <ReloadableRoute
                    path={routeLinks.currentUser.signIn}
                    render={(props) => (
                        <AuthenticationLayout>
                            <SignIn {...props} onSessionStarted={onSessionStart} />
                        </AuthenticationLayout>
                    )}
                />
                <ReloadableRoute
                    path={routeLinks.currentUser.signOut}
                    render={(props) => (
                        <AuthenticationLayout>
                            <SignOut {...props} onSessionEnded={onSessionEnd} />
                        </AuthenticationLayout>
                    )}
                />
                <ReloadableRoute
                    path={routeLinks.currentUser.register(":inviteCode")}
                    render={(props) => (
                        <AuthenticationLayout>
                            <Register {...props} onSessionStarted={onSessionStart} />
                        </AuthenticationLayout>
                    )}
                />
                <SecureRoute path={routeLinks.root} component={StandardLayout} analyticsSession={analyticsSession} />
            </Switch>
        </AnalyticSessionProvider>
    );
}
