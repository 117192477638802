import BasicRepository, { AllArgs, ListArgs } from "./basicRepository";
import Client from "../client";
import ArtifactResource from "client/resources/artifactResource";

type ArtifactListArgs = {
    regarding?: string;
    order?: string;
} & ListArgs;

class ArtifactRepository extends BasicRepository<ArtifactResource, ArtifactResource, ArtifactListArgs> {
    constructor(client: Client) {
        super("Artifacts", client);
    }
}

export default ArtifactRepository;
