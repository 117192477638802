import Client from "../client";
import ConfigurationRepository from "./configurationRepository";
import FeaturesConfigurationResource from "../resources/featuresConfigurationResource";

class FeaturesConfigurationRepository extends ConfigurationRepository<FeaturesConfigurationResource> {
    constructor(client: Client) {
        super("FeaturesConfiguration", client);
    }
}

export default FeaturesConfigurationRepository;
