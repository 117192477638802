/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-imports */

import * as React from "react";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import Popover from "components/Popover/Popover";
import FilterSearchBox from "components/FilterSearchBox";
import { repository } from "clientInstance";
import { Section } from "components/Section/Section";
import { ProjectGroupResource, Permission, ProjectResource, ProjectSummaryResource } from "client/resources";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import VirtualListWithKeyboard from "components/VirtualListWithKeyboard/VirtualListWithKeyboard";
import { FocusableComponent } from "../VirtualListWithKeyboard/FocusableComponent";
import DebounceValue from "components/DebounceValue/DebounceValue";
import _ = require("lodash");
import { ResourcesById } from "client/repositories/basicRepository";
import BusyIndicator from "components/BusyIndicator";
import ErrorPanel from "components/ErrorPanel";
import { Errors } from "components/DataBaseComponent";
import { difference } from "lodash";
import { RecentProjects } from "utils/RecentProjects/RecentProjects";
import { Note } from "components/form";
import { compact } from "lodash";
import Logo from "components/Logo";
import { Tabs, Tab, Theme } from "@material-ui/core";
import { noOp } from "utils/noOp";
import { OctopusTheme, useOctopusTheme } from "components/Theme";
import { makeStyles } from "@material-ui/core/styles";

const styles = require("./projectSwitcher.less");

const useRecentlyViewedTabStyles = makeStyles((theme: Theme) => ({
    root: (props: { octopusTheme: OctopusTheme }) => ({
        display: "flex",
        alignItems: "flex-start", // Align each item at the top, allowing for long project names.
        textTransform: "none",
        fontWeight: theme.typography.fontWeightRegular,
        minWidth: "7.8125rem", // We have to contain the tab to get our ellipsis working for long project names. Math = 500 (width) / 4 (max number of items) / 16 (base rem)
        maxWidth: "7.8125rem",
        padding: 0,
        margin: "0 0 0.25rem 0", // Our busy indicator provides a natural top margin.
        color: props.octopusTheme.primaryText,
        lineHeight: "1rem",
        "&:hover": {
            backgroundColor: props.octopusTheme.hover,
        },
    }),
    wrapper: {
        padding: 0,
        margin: "0.75rem 0.5rem", // Needed to deal with long project names overflow correctly. Play carefully.
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
        "text-align": "center",
        overflow: "hidden",
        "& :nth-child(1)": {
            margin: "0 auto", // Keep centered within our container.
        },
    },
}));

const RecentlyViewedTab: React.FC<any> = (props) => {
    const octopusTheme = useOctopusTheme();
    const classes = useRecentlyViewedTabStyles({ octopusTheme });
    return <Tab classes={classes} {...props} />;
};

export interface ProjectSummary {
    Id: string;
    Name: string;
    Slug: string;
    Logo: string;
    Group: string | null;
}

interface ProjectSwitcherProps {
    open: boolean;
    onRequestClose: (projectId?: string, event?: React.MouseEvent<{}, MouseEvent>) => void;
    anchorEl: HTMLElement;
}

interface ProjectSwitcherState extends DataBaseComponentState {
    isLoadingProjectList: boolean;
    isLoadingRecentList: boolean;
    pagedProjects: ProjectSummary[];
    allProjects: ProjectSummary[];
    totalProjects: number;
    recentProjects: ProjectSummary[];
    localStorageRecentProjects: string[];
    filter?: string;
    projectGroupMap: ResourcesById<ProjectGroupResource> | null;
}

const VirtualList = VirtualListWithKeyboard<ProjectSummary>();
const DebounceFilterSearchBox = DebounceValue(FilterSearchBox);

export function renderErrors(errors?: Errors) {
    if (!errors) {
        return null;
    }
    return <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink} statusCode={errors.statusCode} />;
}

/**
 * Builds the list of recent projects. Sorts the ouput according to the
 * ordering of the recentProjectIds parameter. Handles the case where
 * an id exists in recentProjectIds but not the allProjectsInSpace (e.g.
 * when a user visited a project recently but no longer has access to it).
 * @param recentProjectIds
 * @param allProjectsInSpace
 */
function buildRecentProjectsList(recentProjectIds: string[], allProjectsInSpace: ProjectResource[]): ProjectResource[] {
    return _.compact(recentProjectIds.map((projectId) => allProjectsInSpace.find((p) => p.Id === projectId)));
}

export class ProjectSwitcher extends DataBaseComponent<ProjectSwitcherProps, ProjectSwitcherState> {
    private searchRef: any;
    private virtualList: FocusableComponent | null = undefined!;
    private updatePopoverPosition: () => void = undefined!;

    constructor(props: ProjectSwitcherProps) {
        super(props);

        this.state = {
            isLoadingProjectList: false,
            isLoadingRecentList: false,
            pagedProjects: [],
            allProjects: [],
            totalProjects: 0,
            projectGroupMap: null,
            localStorageRecentProjects: [],
            recentProjects: [],
        };
    }

    async componentDidMount() {
        await this.refreshAllData();
    }

    async componentDidUpdate(prevProps: ProjectSwitcherProps) {
        if (prevProps.open !== this.props.open && this.props.open === true && !this.state.busy) {
            await this.refreshAllDataSilently();
        }
    }

    render() {
        return (
            <Popover
                getUpdatePosition={(update) => (this.updatePopoverPosition = update)}
                style={{ overflowY: "hidden" }}
                open={this.props.open}
                anchorEl={this.props.anchorEl}
                onClose={this.onRequestClose}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                transformOrigin={{ horizontal: "left", vertical: "top" }}
            >
                <div className={styles.container} onKeyDown={this.onKeyEsc}>
                    {renderErrors(this.errors)}
                    {this.renderSearchControls()}
                    {this.renderRecentProjectsList()}
                    {this.renderProjectsSearchList()}
                </div>
            </Popover>
        );
    }

    //TODO @FrontendArchitecture - Pull this out into a separate/isolated component.
    renderRecentProjectsList() {
        return (
            <div className={styles.recentlyViewedSection}>
                <div className={styles.sectionHeader}>Recently Viewed</div>
                <BusyIndicator show={this.state.isLoadingRecentList} />
                {!this.state.isLoadingRecentList && this.state.recentProjects.length === 0 ? (
                    <Section>
                        <Note>Your recently viewed projects will appear here.</Note>
                    </Section>
                ) : (
                    <Tabs value={undefined} onChange={noOp} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="off" className={styles.tabContainer}>
                        {this.state.recentProjects.map((x) => {
                            return <RecentlyViewedTab label={x.Name} title={x.Name} icon={<Logo url={x.Logo} size={"3rem"} />} onClick={(e: any) => this.onSelectAndRequestClose(x.Id, e)} />;
                        })}
                    </Tabs>
                )}
            </div>
        );
    }

    renderSecondaryText(text: string | undefined | null): React.ReactNode {
        if (!text) {
            return null;
        }
        return <span className={styles.secondaryText}>{text}</span>;
    }

    renderSearchControls() {
        return (
            <Section>
                <DebounceFilterSearchBox
                    innerRef={this.setSearchRef}
                    autoFocus={true}
                    value={this.state.filter}
                    placeholder="Filter for a project..."
                    onChange={(x) => this.onFilterChange(x)}
                    fullWidth={true}
                    onKeyDown={this.onArrowDown}
                    containerClassName={styles.filterFieldContainer}
                />
            </Section>
        );
    }

    renderProjectsSearchList() {
        let projects = this.state.pagedProjects;
        if (this.state.allProjects.length > 0) {
            projects = this.filterAllProjects(this.state.filter, this.state.allProjects);
        }
        return (
            <>
                <>
                    {projects.length > 0 && (
                        <div className={styles.sectionHeader}>
                            Showing {projects.length} out of {this.state.totalProjects} project{projects.length === 1 ? "" : "s"}
                        </div>
                    )}
                    <BusyIndicator show={this.state.isLoadingProjectList} />
                    <div className={styles.menuContainer}>
                        <VirtualList
                            multiSelectRef={(el) => (this.virtualList = el)}
                            items={projects}
                            empty={<div className={styles.empty}>{`${!this.state.filter ? "There are no projects yet!" : `Cannot find projects matching "${this.state.filter}"`}`}</div>}
                            renderItem={(item) => ({
                                primaryText: item.Name,
                                secondaryText: this.renderSecondaryText(item.Group),
                            })}
                            onSelected={this.onSelectAndRequestClose}
                            onResized={() => {
                                // When the content's size changes, we re-render so that the
                                // popover can re-position itself based on the new `VirtualList` size
                                if (this.updatePopoverPosition) {
                                    this.updatePopoverPosition();
                                }
                            }}
                            onBlur={() => this.searchRef.focus()}
                        />
                    </div>
                </>
            </>
        );
    }

    // To cater for customers at scale or on slow connections (while still retaining snappy UX), this method looks up a page of
    // projects first, to allow the user to start viewing/search quickly, then looks up all projects in the background.
    private refreshAllData = async () => {
        await this.setStateAsync({ ...this.state, isLoadingProjectList: true, isLoadingRecentList: true });
        try {
            await this.doBusyTask(async () => {
                const [projectGroupMap, projectsAndTotalProjects, recentProjects] = await Promise.all([this.fetchProjectGroupMap(), await this.fetchPageOfProjects({ take: 10 }), this.fetchRecentProjectsFromLocalStorage()]);
                this.setState({
                    projectGroupMap,
                    pagedProjects: projectsAndTotalProjects?.projects ?? [],
                    totalProjects: projectsAndTotalProjects?.totalProjects ?? 0,
                    recentProjects: recentProjects ?? [],
                });
            });
        } finally {
            this.setState({ isLoadingProjectList: false, isLoadingRecentList: false });
        }

        // Fetch ALL projects outside the context of our loading flags above, then, when all projects are available, the UI will switch accordingly.
        await this.doBusyTask(async () => {
            const allProjects = await this.fetchAllProjects();
            this.setState({ allProjects, totalProjects: allProjects.length });
        });
    };

    private refreshAllDataSilently = async () => {
        await this.doBusyTask(async () => {
            const [projectGroupMap, allProjects, recentProjects] = await Promise.all([this.fetchProjectGroupMap(), this.fetchAllProjects(), this.fetchRecentProjectsFromLocalStorage()]);
            this.setState({ projectGroupMap, allProjects: allProjects ?? [], totalProjects: allProjects?.length ?? 0, recentProjects: recentProjects ?? [] });
        });
    };

    private searchForPageOfProjects = async (filter?: string) => {
        await this.setStateAsync({ ...this.state, isLoadingProjectList: true });
        try {
            await this.doBusyTask(async () => {
                const { projects, totalProjects } = await this.fetchPageOfProjects({ take: 10, filter });
                this.setState({ pagedProjects: projects, totalProjects });
            });
        } finally {
            this.setState({ isLoadingProjectList: false });
        }
    };

    private fetchPageOfProjects = async ({ filter, take = 10 }: { filter?: string; take?: number }): Promise<{ projects: ProjectSummary[]; totalProjects: number }> => {
        let projects: ProjectSummary[] = [];
        let totalResults = 0;

        if (this.state.allProjects.length === 0) {
            const matchedProjects = await repository.Projects.list({ take, ...(filter ? { partialName: filter } : {}) });
            totalResults = take;
            const projectResources = matchedProjects.Items;
            projects = projectResources.map((p) => ({
                Id: p.Id,
                Name: p.Name,
                Slug: p.Slug,
                Logo: p.Links.Logo,
                Group: this.getProjectGroupName(p.ProjectGroupId),
            }));
        } else {
            projects = this.filterAllProjects(filter, this.state.allProjects);
            totalResults = projects.length;
        }

        return { projects, totalProjects: totalResults };
    };

    private fetchAllProjects = async (): Promise<ProjectSummary[]> => {
        const allProjects = await repository.Projects.summaries();
        const projects = this.mapProjectResourcesToProjects(allProjects);
        return projects;
    };

    private fetchProjectGroupMap = async () => {
        if (isAllowed({ permission: Permission.ProjectGroupView, projectGroup: "*" })) {
            const groups = await repository.ProjectGroups.allById();
            return groups;
        }
        return null;
    };

    private async fetchRecentProjectsFromLocalStorage(): Promise<ProjectSummary[]> {
        const recentProjects = RecentProjects.getInstance().GetRecentProjectListInSpace();
        const recentProjectIds: string[] = recentProjects
            ? recentProjects.Projects.map((x) => {
                  return x.ProjectId;
              })
            : [];

        if (!recentProjectIds || recentProjectIds.length === 0 || !this.state.projectGroupMap || Object.keys(this.state.projectGroupMap).length === 0) {
            // Don't bother doing API requests etc. If there are none, just return empty.
            return [];
        }

        let projects: ProjectSummary[] = [];
        if (difference(recentProjectIds, this.state.localStorageRecentProjects).length > 0) {
            const args = recentProjectIds.length > 0 ? { ids: recentProjectIds } : undefined;

            // Check if we have state available with these projects already, if so, use that to save a network call.
            const foundFromAllProjects = this.state.allProjects.filter((x) => recentProjectIds.indexOf(x.Id) > -1);
            if (foundFromAllProjects.length === recentProjectIds.length) {
                projects = foundFromAllProjects;
            } else {
                const recentProjectResources = await repository.Projects.all(args);
                projects = compact(
                    recentProjects.Projects.map((recentProject) => {
                        const projectResource = recentProjectResources.find((x) => x.Id === recentProject.ProjectId);
                        if (projectResource) {
                            return {
                                Group: this.getProjectGroupName(projectResource.ProjectGroupId),
                                Id: projectResource.Id,
                                Name: projectResource.Name,
                                Slug: projectResource.Slug,
                                Logo: projectResource.Links.Logo,
                            };
                        }
                        return null;
                    })
                );
            }

            this.setState({
                localStorageRecentProjects: recentProjectIds,
                recentProjects: projects,
            });
        } else {
            projects = this.state.recentProjects;
        }

        projects
            .sort((a, b) => {
                const recentProjectA = recentProjects.Projects.find((x) => x.ProjectId === a.Id);
                const recentProjectB = recentProjects.Projects.find((x) => x.ProjectId === b.Id);
                return RecentProjects.SortByScoreThenTime(recentProjectA!.Score, recentProjectB!.Score, recentProjectA!.Timestamps, recentProjectB!.Timestamps);
            })
            .reverse();

        return projects;
    }

    private mapProjectResourcesToProjects(projectResources: ProjectSummaryResource[]) {
        return projectResources.map((p) => this.mapProjectResourceToProject(p));
    }

    private mapProjectResourceToProject(p: ProjectSummaryResource): ProjectSummary {
        return {
            Id: p.Id,
            Name: p.Name,
            Slug: p.Slug,
            Logo: p.Links.Logo,
            Group: this.getProjectGroupName(p.ProjectGroupId),
        };
    }

    private getProjectGroupName = (gId: string) => {
        const projectGroupMap = this.state.projectGroupMap;
        return projectGroupMap && projectGroupMap.hasOwnProperty(gId) ? projectGroupMap[gId].Name : null;
    };

    private filterAllProjects(filter: string | undefined, allProjects: ProjectSummary[]): ProjectSummary[] {
        if (!allProjects || allProjects.length === 0) {
            return [];
        }
        const matchesFilter = (n: string) => n.toLowerCase().includes(filter?.toLowerCase() || "");
        return filter ? allProjects.filter((p) => matchesFilter(p.Name) || (p.Group ? matchesFilter(p.Group) : false)) : allProjects;
    }

    private setSearchRef = (el: any) => {
        this.searchRef = el;
    };

    private onSelectAndRequestClose = (projectId?: string, event?: React.MouseEvent<{}, MouseEvent>) => {
        this.props.onRequestClose(projectId, event);
        this.onFilterChange(undefined);
    };

    private onRequestClose = () => {
        this.props.onRequestClose();
        this.onFilterChange(undefined);
    };

    private onFilterChange = (filter: string | undefined) => {
        this.setState({ filter }, async () => {
            if (this.state.allProjects.length === 0) {
                await this.searchForPageOfProjects(this.state.filter);
            } else {
                const filteredProjects = this.filterAllProjects(this.state.filter, this.state.allProjects);
                const totalProjects = this.state.allProjects.length;
                this.setState({ pagedProjects: filteredProjects, totalProjects });
            }
        });
    };

    private onKeyEsc = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Escape") {
            this.onRequestClose();
        }
    };

    private onArrowDown = (event: KeyboardEvent) => {
        if (event.key === "ArrowDown" || event.key === "Tab") {
            if (this.filterAllProjects(this.state.filter, this.state.allProjects).length === 0) {
                return;
            }
            this.virtualList?.focus();
            event.preventDefault();
        }
    };
}

export default ProjectSwitcher;
export { buildRecentProjectsList };
