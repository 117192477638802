import * as React from "react";
import { PermissionCheck } from "components/PermissionCheck";
import { Permission } from "client/resources";
import routeLinks from "routeLinks";
import InternalLink from "components/Navigation/InternalLink";
import ActionButton, { ActionButtonType } from "components/Button";
import { useProjectContext } from "areas/projects/context";
import { Action, useAnalyticActionDispatch } from "analytics/Analytics";

export type CreateReleaseButtonProps = {
    projectId: string;
    projectSlug: string;
    disabled?: boolean;
};

export const CreateReleaseButton: React.FC<CreateReleaseButtonProps> = ({ projectId, projectSlug, disabled }) => {
    const dispatchAction = useAnalyticActionDispatch();

    return (
        <PermissionCheck permission={Permission.ReleaseCreate} project={projectId} tenant="*">
            <div>
                {/* Note: We can't just use a NavigationButton here, because once you click it, it renders a redirect and disappears :) */}
                <InternalLink to={routeLinks.project(projectSlug).deployments.releaseCreate} onClick={() => dispatchAction("Create a release", { resource: "Create Release", action: Action.Add })}>
                    <ActionButton type={ActionButtonType.CreateRelease} label="Create release" disabled={disabled} />
                </InternalLink>
            </div>
        </PermissionCheck>
    );
};

export const ContextualCreateReleaseButton: React.FC<Omit<CreateReleaseButtonProps, "projectId">> = (props) => {
    const project = useProjectContext();
    return <CreateReleaseButton projectId={project.state.model.Id} {...props} />;
};

export default ContextualCreateReleaseButton;
