import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonProjectRunbookTopics } from "../../CommonComponents/CommonProjectRunbooksViews";
import { OverviewContainer, OverviewHighlightText } from "../../CommonComponents/CommonViews";

export const ProjectRunbooksRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().runbook.settings,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return (
            <OverviewContainer>
                <OverviewHighlightText>
                    <p>Runbook settings allow you to edit details about the runbook, such as the name and description, as well as see options to clone/delete in the overflow menu.</p>
                </OverviewHighlightText>
            </OverviewContainer>
        );
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectRunbookTopics />;
    },
};
