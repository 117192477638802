import BasicRepository from "./basicRepository";
import Client from "../client";
import { NewSpaceResource, SpaceResource } from "../resources";

class SpaceRepository extends BasicRepository<SpaceResource, NewSpaceResource> {
    constructor(client: Client) {
        super("Spaces", client);
    }
    search(keyword: string) {
        return this.client.get<SpaceSearchResult[]>(this.client.getLink("SpaceSearch"), { id: this.client.spaceId, keyword: keyword });
    }
}

export interface SpaceSearchResult {
    Id: string;
    Name: string;
    Type: string;
}

export default SpaceRepository;
