import * as React from "react";
import PaperLayout from "components/PaperLayout";
import Section from "components/Section";

const UxPatternsOverview: React.FC = () => {
    return (
        <PaperLayout title={"Overview"} busy={undefined} errors={undefined}>
            <Section>TODO: A nice overview page to go here :)</Section>
        </PaperLayout>
    );
};

export default UxPatternsOverview;
