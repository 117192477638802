/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { repository } from "../../clientInstance";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import routeLinks from "../../routeLinks";
import PaperLayout from "components/PaperLayout";
import AreaTitle from "components/AreaTitle/index";
import InternalRedirect from "../../components/Navigation/InternalRedirect/InternalRedirect";

interface RunbookRunToRunbookTaskRedirectState extends DataBaseComponentState {
    redirectTo: string;
}

export default class RunbookRunToRunbookTaskRedirect extends DataBaseComponent<RouteComponentProps<{ runbookRunId: string }>, RunbookRunToRunbookTaskRedirectState> {
    constructor(props: RouteComponentProps<{ runbookRunId: string }>) {
        super(props);
        this.state = {
            redirectTo: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const runbookRunId = this.props.match.params.runbookRunId;
            const runbookRun = await repository.RunbookRuns.get(runbookRunId);
            const runbookSnapshot = await repository.RunbookSnapshots.get(runbookRun.RunbookSnapshotId);

            const path = routeLinks.project(runbookSnapshot.ProjectId).operations.runbook(runbookSnapshot.RunbookId).runbookSnapshot(runbookSnapshot).runbookRuns.specific(runbookRun);
            this.setState({
                redirectTo: path,
            });
        });
    }

    render() {
        const redirectTo = this.state.redirectTo;
        if (!redirectTo) {
            return (
                <main id="maincontent">
                    <AreaTitle link={routeLinks.projects.root} title="Projects" />
                    <PaperLayout busy={this.state.busy} fullWidth={true} errors={this.errors} />
                </main>
            );
        }

        return <InternalRedirect push={false} to={{ pathname: redirectTo }} />;
    }
}
