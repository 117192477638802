import { useMemo } from "react";
import { orderBy } from "lodash";
import packagingRegistry from "./packagingRegistry";

export const usePackagingRegistrations = () => {
    return useMemo(() => {
        return orderBy(packagingRegistry.getAllRegistrations(), ["displayOrder", "name"]);
    }, []);
};

export default usePackagingRegistrations;
