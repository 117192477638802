/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import BaseComponent from "components/BaseComponent";
import { repository } from "clientInstance";
import { WorkerPoolResource, ResourceCollection } from "client/resources";
import List from "components/List";
import routeLinks from "../../../routeLinks";
import WorkerPoolSummary from "./WorkerPoolSummary";

interface WorkerPoolListProp {
    pools?: ResourceCollection<WorkerPoolResource>;
    selectedWorkerPoolId?: string;
    doBusyTask: (action: () => Promise<any>) => Promise<boolean>;
    onSelected?: (pool: WorkerPoolResource) => void;
}

interface WorkerPoolListState {
    pools?: ResourceCollection<WorkerPoolResource>;
    selectedId?: string;
    busy: boolean;
}

class WorkerPoolList extends List<WorkerPoolResource> {}

export default class WorkerPoolSearch extends BaseComponent<WorkerPoolListProp, WorkerPoolListState> {
    constructor(props: WorkerPoolListProp) {
        super(props);
        this.state = {
            pools: this.props.pools,
            busy: false,
            selectedId: this.props.selectedWorkerPoolId,
        };
    }

    componentDidMount() {
        return this.props.doBusyTask(async () => {
            const pools = this.state.pools ? this.state.pools : await repository.WorkerPools.list();

            this.setState({ pools });
        });
    }

    buildRow = (pool: WorkerPoolResource) => {
        return [
            <WorkerPoolSummary
                onClick={() => {
                    if (!this.props.onSelected) {
                        return;
                    }
                    this.setState({ selectedId: pool.Id }, () => {
                        if (this.props.onSelected) {
                            this.props.onSelected(pool);
                        }
                    });
                }}
                showSelection={this.props.onSelected ? true : false}
                selected={pool.Id === this.state.selectedId}
                key={pool.Id}
                pool={pool}
            />,
        ];
    };

    rowClicked = (pool: WorkerPoolResource) => {
        if (this.props.onSelected) {
            return null;
        } else {
            return routeLinks.infrastructure.workerPool(pool.Id);
        }
    };

    applyFilter(filter: string, resource: WorkerPoolResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }

    render() {
        // Note: This is wrapped in a <div> on purpose for CSS transition animations.
        return (
            <div>
                {this.state.pools && (
                    <WorkerPoolList
                        initialData={this.state.pools}
                        onRow={this.buildRow}
                        onRowRedirectUrl={this.rowClicked}
                        onFilter={this.applyFilter}
                        filterSearchEnabled={true}
                        apiSearchParams={["partialName"]}
                        filterHintText="Filter by name, subject, or thumbprint"
                    />
                )}
            </div>
        );
    }
}
