import { HashRouter } from "react-router-dom";
import DevTools from "components/DevTools";
import * as React from "react";
import { useState } from "react";
import store from "store";
import ConfirmNavigate from "components/ConfirmNavigate/ConfirmNavigate";
import { AuthenticationRoutes } from "components/AuthenticationRoutes/AuthenticationRoutes";

export function OctopusRouter() {
    const [userConfirmationRequest, setUserConfirmationRequest] = useState<UserConfirmationRequest | null>(null);
    const [confirmationRequestId, setConfirmationRequestId] = useState<number>(1);

    return (
        <>
            {/*We use a key here to create a new instance of this component (with new state) whenever there is a new incoming request*/}
            {/*Since the state about whether the dialog is open or not is internal to this component, this is the easiet way to manage it*/}
            <ConfirmNavigateAwayFromFormPaperLayout userConfirmationRequest={userConfirmationRequest} key={confirmationRequestId} />
            <HashRouter
                getUserConfirmation={(message, callback) => {
                    setUserConfirmationRequest({
                        message,
                        callback,
                    });
                    setConfirmationRequestId((prev) => prev + 1);
                }}
            >
                <AuthenticationRoutes />
                <DevTools />
            </HashRouter>
        </>
    );
}

function ConfirmNavigateAwayFromFormPaperLayout(props: { userConfirmationRequest: UserConfirmationRequest | null }) {
    const formPaperLayoutState = store.getState().formPaperLayout;

    const userConfirmationRequest = props.userConfirmationRequest;
    if (userConfirmationRequest === null) return null;

    // Note: We want to keep rendering this component after the selection has been made, so that the fade away animation shows
    return (
        <ConfirmNavigate
            message={userConfirmationRequest.message}
            onCancelNavigation={() => userConfirmationRequest.callback(false)}
            onContinueNavigating={() => userConfirmationRequest.callback(true)}
            onSaveClick={async () => {
                if (!formPaperLayoutState.onSaveClick) return false;
                return await formPaperLayoutState.onSaveClick();
            }}
            saveLabel={formPaperLayoutState.saveLabel}
        />
    );
}

type UserConfirmationCallback = (ok: boolean) => void;
interface UserConfirmationRequest {
    callback: UserConfirmationCallback;
    message: string;
}
