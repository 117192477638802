import * as React from "react";
import Chip from "./Chip";
import DeletableChip from "./DeletableChip";
import { UserResource } from "client/resources";
import { CommonOrDeletableChipProps, isDeletableChip, matchesToChips } from "components/Chips/index";

const UserChip: React.FC<{ user: UserResource } & CommonOrDeletableChipProps> = (props) => {
    const { user, ...rest } = props;
    const name = userDisplayName(user);
    const description = "User: " + name;

    if (isDeletableChip(props)) {
        const { onRequestDelete, deleteButtonAccessibleName } = props;
        return (
            <DeletableChip onRequestDelete={onRequestDelete} deleteButtonAccessibleName={deleteButtonAccessibleName} description={description} {...rest}>
                {name}
            </DeletableChip>
        );
    }

    return (
        <Chip description={description} {...rest}>
            {name}
        </Chip>
    );
};

function userDisplayName(resource: UserResource) {
    return resource.DisplayName !== resource.Username ? `${resource.DisplayName} (${resource.Username})` : resource.DisplayName;
}

function userList(set: UserResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, (x) => <UserChip user={x} key={x.Id} />);
}

export { UserChip, userDisplayName, userList };
