import * as React from "react";
import SvgImage, { SvgImageProps } from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
const styles = require("../../../primitiveComponents/dataDisplay/SvgImage/styles.less");

//This is used for the login screen and needs to be white in both themes

const Octopus = (props: Partial<SvgImageProps>) => (
    <SvgImage {...props} viewBox="0 0 61 64" title="Octopus">
        <g id="octopus" fillRule="evenodd" strokeWidth="1">
            <path
                className={styles.imgWhite}
                fillRule="nonzero"
                d="M4.017 48.308C8.82 45.22 14.463 39.926 12.32 33.69c-1.167-3.397-2.784-6.31-3-9.983-.183-3.1.282-6.233 1.369-9.143 4.307-11.538 17.111-17 28.683-13.532 10.718 3.212 18.092 15.632 13.65 26.454-2.563 6.246-3.712 11.067 2.005 15.927 1.549 1.318 5.31 3.293 5.297 5.628-.02 3.06-6.032-.651-6.704-1.183.765 1.333 8.346 9.222 3.52 9.783-4.442.517-8.364-5.674-11.033-8.332-4.483-4.464-3.703 5.412-3.724 7.452-.032 3.221-2.312 9.75-6.404 5.498-3.38-3.51-2.102-9.108-4.452-13.007-2.574-4.271-6.856 4.271-7.926 5.834-1.195 1.746-7.176 10.2-9.556 5.696-1.93-3.655 1.156-9.389 2.674-12.695-.554 1.201-4.476 2.977-5.622 3.554-2.59 1.305-5.218 2.08-8.13 1.871-6.027-.431-1.405-3.624 1.05-5.204.822-.528-.77.496 0 0"
            />
        </g>
    </SvgImage>
);
export { Octopus };
