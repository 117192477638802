/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable no-restricted-imports */

import * as React from "react";
import { DataTable } from "primitiveComponents/dataDisplay/DataTable/DataTable";
import { DataTableBody } from "primitiveComponents/dataDisplay/DataTable/DataTableBody";
import { DataTableRow } from "primitiveComponents/dataDisplay/DataTable/DataTableRow";
import { DataTableRowColumn } from "primitiveComponents/dataDisplay/DataTable/DataTableRowColumn";
import { DataTableHeader } from "primitiveComponents/dataDisplay/DataTable/DataTableHeader";
import { DataTableHeaderColumn } from "primitiveComponents/dataDisplay/DataTable/DataTableHeaderColumn";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import routeLinks from "routeLinks";
import { ExpandableFormSection, Summary, FormSectionHeading } from "components/form";
import {
    AccountResource,
    AccountUsageResource,
    StepUsage,
    ReleaseUsage,
    ReleaseUsageEntry,
    LibraryVariableSetUsageEntry,
    ProjectVariableSetUsage,
    TargetUsageEntry,
    RunbookStepUsage,
    ProjectResource,
    NonVcsRunbookResource,
    RunbookSnapshotUsage,
} from "client/resources";
import NavigationChip from "components/Chips/NavigationChip";
import Chip from "components/Chips/Chip";
import { sortBy, uniq, keys, reduce, groupBy, compact, flatten, flatMap } from "lodash";
import { SemVer, valid } from "semver";
import DataBaseComponent, { DataBaseComponentState } from "components/DataBaseComponent";
import { repository } from "clientInstance";
import { ResourcesById } from "client/repositories/basicRepository";
import toResourceLookup from "utils/toResourceLookup";
import cn from "classnames";
import { Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

import { Theme, useTheme } from "theme";
import { CardFill } from "components/form/Sections/ExpandableFormSection";

class AccountUsageProps {
    account: AccountResource = undefined!;
    accountUsages: AccountUsageResource = undefined!;
}

enum UsageType {
    Target = "Target",
    LibraryVariableSet = "LibraryVariableSet",
}

type ProjectAccountUsage = Pick<AccountUsageResource, "DeploymentProcesses" | "Releases" | "ProjectVariableSets" | "RunbookProcesses" | "RunbookSnapshots">;
type RunbookAccountUsage = Pick<AccountUsageResource, "RunbookProcesses" | "RunbookSnapshots">;

type UsageByProjectIdLookup = { [key: string]: ProjectAccountUsage };
type UsageByRunbookIdLookup = { [key: string]: RunbookAccountUsage };

type ProjectLookup = ResourcesById<ProjectResource>;
type RunbookLookup = ResourcesById<NonVcsRunbookResource>;

function lookupUsage<T>(lookup: () => T[], fallback: () => T[]) {
    const result = lookup();
    return result ? result : fallback();
}

function getProjectUsageGroupings(usage: AccountUsageResource): UsageByProjectIdLookup {
    const deploymentProcesses = groupBy(usage.DeploymentProcesses, (x) => x.ProjectId);
    const projectVariables = groupBy(
        usage.ProjectVariableSets.filter((x) => x.IsCurrentlyBeingUsedInProject === true),
        (x) => x.ProjectId
    );
    const releases = groupBy(usage.Releases, (x) => x.ProjectId);
    const runbookProcesses = groupBy(usage.RunbookProcesses, (x) => x.ProjectId);
    const runbookSnapshots = groupBy(usage.RunbookSnapshots, (x) => x.ProjectId);
    const initial: { [key: string]: ProjectAccountUsage } = {};

    const projectIds = uniq([...keys(deploymentProcesses), ...keys(projectVariables), ...keys(releases), ...keys(runbookProcesses), ...keys(runbookSnapshots)]);

    return reduce(
        projectIds,
        (prev, id) => {
            return {
                ...prev,
                [id]: {
                    DeploymentProcesses: lookupUsage(
                        () => deploymentProcesses[id],
                        () => [] as StepUsage[]
                    ),
                    ProjectVariableSets: lookupUsage(
                        () => projectVariables[id],
                        () => [] as ProjectVariableSetUsage[]
                    ),
                    Releases: lookupUsage(
                        () => releases[id],
                        () => [] as ReleaseUsage[]
                    ),
                    RunbookProcesses: lookupUsage(
                        () => runbookProcesses[id],
                        () => [] as RunbookStepUsage[]
                    ),
                    RunbookSnapshots: lookupUsage(
                        () => runbookSnapshots[id],
                        () => [] as RunbookSnapshotUsage[]
                    ),
                },
            };
        },
        initial
    );
}

function getRunbookUsageGroupings(usage: AccountUsageResource): UsageByRunbookIdLookup {
    const runbookSnapshots = groupBy(usage.RunbookSnapshots, (x) => x.RunbookId);
    const runbookProcesses = groupBy(usage.RunbookProcesses, (x) => x.RunbookId);

    const runbookIds = uniq([...keys(runbookSnapshots), ...keys(runbookProcesses)]);

    const initial: { [key: string]: RunbookAccountUsage } = {};

    return reduce(
        runbookIds,
        (prev, id) => {
            return {
                ...prev,
                [id]: {
                    RunbookProcesses: lookupUsage(
                        () => runbookProcesses[id],
                        () => [] as RunbookStepUsage[]
                    ),
                    RunbookSnapshots: lookupUsage(
                        () => runbookSnapshots[id],
                        () => [] as RunbookSnapshotUsage[]
                    ),
                },
            };
        },
        initial
    );
}

interface AccountUsageState extends DataBaseComponentState {
    projectUsageLookup: UsageByProjectIdLookup;
    runbookUsageLookup: UsageByRunbookIdLookup;
    projects: ProjectLookup;
    runbooks: RunbookLookup;
    runbooksByProjectId: { [key: string]: NonVcsRunbookResource[] };
}

interface UsageSection {
    className?: string;
    children: React.ReactNode;
}

const UsageSection: React.FC<UsageSection> = ({ className, children, ...rest }) => {
    return (
        <div className={cn(className)} {...rest}>
            {children}
        </div>
    );
};

const UsageSectionHeading = styled("h1")<Theme, {}>(({ theme }) => ({
    fontSize: "1rem",
    fontWeight: theme.typography.fontWeightMedium,
}));

interface GroupedUsageSectionProps {
    className?: string;
    title: React.ReactNode;
    description?: React.ReactNode;
}

const GroupedUsageSection: React.FC<GroupedUsageSectionProps> = ({ className, title: name, description, children, ...rest }) => {
    return (
        <Box className={cn(className)} {...rest}>
            <Box display={"flex"} alignItems="center" flexDirection="row">
                <Box width={1 / 6}>
                    <UsageSectionHeading>{name}</UsageSectionHeading>
                </Box>
                <Box>{description}</Box>
            </Box>

            {children}
        </Box>
    );
};

const UsageGroupHeading = styled("h1")<Theme, {}>(({ theme }) => ({
    background: theme.palette.action.hover,
    padding: theme.spacing(1),
    fontSize: "1rem",
    fontWeight: theme.typography.fontWeightMedium,
    borderBottom: `solid 1px ${theme.palette.divider}`,
    borderTop: `solid 1px ${theme.palette.divider}`,
}));

interface UsageGroupProps {
    name: React.ReactNode;
}

const UsageGroup: React.FC<UsageGroupProps> = ({ name, children }) => {
    return (
        <Box>
            <UsageGroupHeading>{name}</UsageGroupHeading>
            {children}
        </Box>
    );
};

interface UsageRowProps {
    name: string;
    children: React.ReactNode;
}

const UsageRow: React.FC<UsageRowProps> = ({ name, children }) => {
    const theme = useTheme();
    return (
        <Box alignItems="center" display="flex" flexDirection="row" paddingX={1} paddingY={2} borderBottom={`solid 1px ${theme.palette.divider}`}>
            <Box width={1 / 6}>{name}</Box>
            <Box>{children}</Box>
        </Box>
    );
};

interface ReleaseUsageRowProps {
    usages: ReleaseUsage[];
}

const ReleaseUsageRow: React.FC<ReleaseUsageRowProps> = ({ usages }) => {
    return (
        <React.Fragment>
            {usages && usages.length > 0 && (
                <UsageRow name={"Releases"}>
                    {usages.map((usageEntry: ReleaseUsage) =>
                        usageEntry.Releases.map((release: ReleaseUsageEntry, i) => (
                            <NavigationChip to={routeLinks.release(release.ReleaseId)} key={`${release.ReleaseId}`} accessibleName={`Release version ${release.ReleaseVersion} for project ${usageEntry.ProjectName}`}>
                                {release.ReleaseVersion}
                            </NavigationChip>
                        ))
                    )}
                </UsageRow>
            )}
        </React.Fragment>
    );
};

interface RunbookSnapshotUsageRowProps {
    usages: RunbookSnapshotUsage[];
}

const RunbookSnapshotUsageRow: React.FC<RunbookSnapshotUsageRowProps> = ({ usages }) => {
    return (
        <React.Fragment>
            {usages && usages.length > 0 && (
                <UsageRow name={"Snapshots"}>
                    {usages.map((usageEntry) =>
                        usageEntry.Snapshots.map((snapshot) => (
                            <NavigationChip
                                to={routeLinks.runbookSnapshot(snapshot.SnapshotId)}
                                key={snapshot.SnapshotId}
                                accessibleName={`Runbook snapshot ${snapshot.SnapshotName} for runbook ${usageEntry.RunbookName} in project ${usageEntry.ProjectName}`}
                            >
                                {snapshot.SnapshotName}
                            </NavigationChip>
                        ))
                    )}
                </UsageRow>
            )}
        </React.Fragment>
    );
};

interface DeploymentProcessStepsUsageRowProps {
    usages: StepUsage[];
}

const DeploymentProcessStepsUsageRow: React.FC<DeploymentProcessStepsUsageRowProps> = ({ usages }) => {
    return (
        <React.Fragment>
            {usages && usages.length > 0 && (
                <UsageRow name={"Steps"}>
                    {usages.map((usageEntry) =>
                        usageEntry.Steps.map((step) => (
                            <NavigationChip
                                to={routeLinks.project(usageEntry.ProjectSlug).deployments.process.step(step.StepId)}
                                key={`${step.StepId}`}
                                accessibleName={`Deployment process step ${step.StepName} for project ${usageEntry.ProjectName}`}
                            >
                                {step.StepName}
                            </NavigationChip>
                        ))
                    )}
                </UsageRow>
            )}
        </React.Fragment>
    );
};
interface RunbookProcessStepsUsageRowProps {
    usages: RunbookStepUsage[];
}

const RunbookProcessStepsUsageRow: React.FC<RunbookProcessStepsUsageRowProps> = ({ usages }) => {
    return (
        <React.Fragment>
            {usages && usages.length > 0 && (
                <UsageRow name={"Steps"}>
                    {usages.map((usageEntry: RunbookStepUsage) =>
                        usageEntry.Steps.map((step) => (
                            <NavigationChip
                                to={routeLinks.project(usageEntry.ProjectSlug).operations.runbook(usageEntry.RunbookId).runbookProcess.runbookProcess(usageEntry.ProcessId).process.step(step.StepId)}
                                key={`${step.StepId}`}
                                accessibleName={`Runbook step ${step.StepName} for runbook ${usageEntry.RunbookName} in project ${usageEntry.ProjectName}`}
                            >
                                {step.StepName}
                            </NavigationChip>
                        ))
                    )}
                </UsageRow>
            )}
        </React.Fragment>
    );
};

const getCountSummary = (items: ArrayLike<{}>, singular: string, plural: string) => {
    return (
        items &&
        items.length > 0 && (
            <Chip>
                {items.length} {items.length === 1 ? singular : plural}
            </Chip>
        )
    );
};

const getProjectSummary = (usages: ProjectAccountUsage & RunbookAccountUsage = { DeploymentProcesses: [], ProjectVariableSets: [], Releases: [], RunbookProcesses: [], RunbookSnapshots: [] }) => {
    const usageSummary = flatten(
        compact([
            getCountSummary(
                flatMap(usages.DeploymentProcesses || [], (x) => x.Steps),
                "deployment process step",
                "deployment process steps"
            ),
            getCountSummary(
                flatMap(usages.Releases || [], (x) => x.Releases),
                "release",
                "releases"
            ),
            getCountSummary(
                flatMap(usages.RunbookProcesses || [], (x) => x.Steps),
                "runbook process step",
                "runbook process steps"
            ),
            getCountSummary(
                flatMap(usages.RunbookSnapshots || [], (x) => x.Snapshots),
                "runbook snapshot",
                "runbook snapshots"
            ),
            usages.ProjectVariableSets && usages.ProjectVariableSets.length > 0 && `is used in variables`,
        ]).map((value, index) => (index === 0 ? [value] : [" and ", value]))
    );

    return <React.Fragment>This account is used in {usageSummary}</React.Fragment>;
};

export default class AccountUsage extends DataBaseComponent<AccountUsageProps, AccountUsageState> {
    constructor(props: AccountUsageProps) {
        super(props);
        this.state = {
            projectUsageLookup: {},
            projects: {},
            runbooks: {},
            runbooksByProjectId: {},
            runbookUsageLookup: {},
        };
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const projectUsageLookup = getProjectUsageGroupings(this.props.accountUsages);
            const runbookUsageLookup = getRunbookUsageGroupings(this.props.accountUsages);
            const runbooks = await repository.Runbooks.all({ ids: keys(runbookUsageLookup) });

            const projects = await repository.Projects.all({ ids: uniq([...keys(projectUsageLookup), ...this.props.accountUsages.RunbookProcesses.map((x) => x.ProjectId), ...this.props.accountUsages.RunbookSnapshots.map((x) => x.ProjectId)]) });

            const runbookByProjectId = groupBy(runbooks, (x) => x.ProjectId);

            this.setState({
                projects: toResourceLookup(projects),
                projectUsageLookup,
                runbooks: toResourceLookup(runbooks),
                runbooksByProjectId: runbookByProjectId,
                runbookUsageLookup,
            });
        });
    }

    render() {
        const accountUsages = this.props.accountUsages;

        return (
            <div>
                <ExpandableFormSection
                    key="usageInLibraryVariableSets"
                    errorKey="usageInLibraryVariableSets"
                    title="Library Variable Sets"
                    summary={this.accountUsageSummary(UsageType.LibraryVariableSet, accountUsages)}
                    help={this.accountUsageHelp(UsageType.LibraryVariableSet, accountUsages)}
                >
                    {this.accountUsageInLibraryVariableSets(accountUsages)}
                </ExpandableFormSection>
                <ExpandableFormSection key="usageInTargets" errorKey="usageInTargets" title="Targets" summary={this.accountUsageSummary(UsageType.Target, accountUsages)} help={this.accountUsageHelp(UsageType.Target, accountUsages)}>
                    {this.accountUsageInTargets(accountUsages)}
                </ExpandableFormSection>

                <FormSectionHeading title={"Projects"} />
                {keys(this.state.projects).map((x) => {
                    return (
                        <ExpandableFormSection
                            fillCardWidth={CardFill.FillRight}
                            key={x}
                            errorKey={x}
                            title={this.state.projects[x].Name}
                            summary={Summary.summary(getProjectSummary({ ...this.state.projectUsageLookup[x] }))}
                            help={"Areas of this project linked to this account."}
                        >
                            {this.state.projectUsageLookup[x] && (
                                <GroupedUsageSection title={"Deployment"}>
                                    <UsageGroup name={<InternalLink to={routeLinks.project(this.state.projects[x].Slug).deployments.process.root}>Process</InternalLink>}>
                                        <ReleaseUsageRow usages={this.state.projectUsageLookup[x].Releases} />
                                        <DeploymentProcessStepsUsageRow usages={this.state.projectUsageLookup[x].DeploymentProcesses} />
                                    </UsageGroup>
                                </GroupedUsageSection>
                            )}

                            {this.state.runbooksByProjectId[x] && (
                                <GroupedUsageSection title={"Runbooks"}>
                                    {this.state.runbooksByProjectId[x].map(({ Id: runbookId, Name: name, RunbookProcessId: runbookProcessId }) => (
                                        <UsageGroup name={<InternalLink to={routeLinks.project(this.state.projects[x].Slug).operations.runbook(runbookId).runbookProcess.runbookProcess(runbookProcessId).root}>{name}</InternalLink>} key={runbookId}>
                                            <RunbookSnapshotUsageRow usages={this.state.runbookUsageLookup[runbookId].RunbookSnapshots} />
                                            <RunbookProcessStepsUsageRow usages={this.state.runbookUsageLookup[runbookId].RunbookProcesses} />
                                        </UsageGroup>
                                    ))}
                                </GroupedUsageSection>
                            )}

                            {this.state.projectUsageLookup[x] && this.state.projectUsageLookup[x].ProjectVariableSets.length > 0 && (
                                <GroupedUsageSection title={"Variables"} description={<InternalLink to={routeLinks.project(this.state.projects[x].Slug).variables.root}>This account is being used in this projects variables</InternalLink>} />
                            )}
                        </ExpandableFormSection>
                    );
                })}
            </div>
        );
    }

    accountUsageSummary(usageType: UsageType, accountUsages: AccountUsageResource) {
        switch (usageType) {
            case UsageType.Target:
                return accountUsages.Targets.length > 0
                    ? accountUsages.Targets.length > 1
                        ? Summary.summary(
                              <span>
                                  This account is being used in <b>{accountUsages.Targets.length}</b> targets
                              </span>
                          )
                        : Summary.summary(
                              <span>
                                  This account is being used in <b>1</b> target
                              </span>
                          )
                    : Summary.placeholder("This account is not being used in any targets");
            case UsageType.LibraryVariableSet:
                return accountUsages.LibraryVariableSets.length > 0
                    ? accountUsages.LibraryVariableSets.length > 1
                        ? Summary.summary(
                              <span>
                                  This account is being used in <b>{accountUsages.LibraryVariableSets.length}</b> library variable sets
                              </span>
                          )
                        : Summary.summary(
                              <span>
                                  This account is being used in <b>1</b> library variable set
                              </span>
                          )
                    : Summary.placeholder("This account is not being used in any library variable sets");
        }
    }

    accountUsageHelp(usageType: UsageType, accountUsages: AccountUsageResource) {
        switch (usageType) {
            case UsageType.Target:
                return accountUsages.Targets.length === 0
                    ? "This account is not being used in any targets"
                    : accountUsages.Targets.length === 1
                    ? "This account is being used in the following target"
                    : "This account is being used in the following targets";
            case UsageType.LibraryVariableSet:
                return accountUsages.LibraryVariableSets.length === 0
                    ? "This account is not being used in any library variable sets"
                    : accountUsages.LibraryVariableSets.length === 1
                    ? "This account is being used in the following library variable set"
                    : "This account is being used in the following library variable sets";
        }
    }

    private combineReleaseSnapshotsIntoReleases(accountUsages: AccountUsageResource) {
        const releases = accountUsages.Releases;

        accountUsages.ProjectVariableSets.filter((x) => x.Releases.length > 0).forEach((usageEntry: ProjectVariableSetUsage) => {
            let project: ReleaseUsage | undefined = releases.find((x) => x.ProjectId === usageEntry.ProjectId);

            if (!project) {
                project = { ProjectId: usageEntry.ProjectId, ProjectName: usageEntry.ProjectName, Releases: [] };
                releases.push(project);
            }

            usageEntry.Releases.forEach((releaseEntry: ReleaseUsageEntry) => {
                if (project) {
                    const existingEntry = project.Releases.find((x) => x.ReleaseId === releaseEntry.ReleaseId);
                    if (!existingEntry) {
                        project.Releases.push(releaseEntry);
                    }
                }
            });
        });

        //sort the projects list
        const sortedProjects = sortBy(releases, [(x) => x.ProjectName.toLowerCase()], ["asc"]);
        //sort the releases for each project
        sortedProjects.forEach((accountUsage) => {
            accountUsage.Releases = accountUsage.Releases.sort(this.compareSemVer);
        });

        return sortedProjects;
    }

    private compareSemVer = (a: ReleaseUsageEntry, b: ReleaseUsageEntry) => {
        if (valid(a.ReleaseVersion) && valid(b.ReleaseVersion)) {
            return new SemVer(a.ReleaseVersion).compare(new SemVer(b.ReleaseVersion));
        }
        //it is usually valid semver, but sometimes it may not be (eg "1" is a valid version number)
        if (a.ReleaseVersion < b.ReleaseVersion) {
            return -1;
        }
        if (a.ReleaseVersion > b.ReleaseVersion) {
            return 1;
        }
        return 0;
    };

    private accountUsageInLibraryVariableSets(accountUsages: AccountUsageResource) {
        return (
            <div>
                {accountUsages.LibraryVariableSets.length > 0 && (
                    <DataTable>
                        <DataTableHeader>
                            <DataTableRow>
                                <DataTableHeaderColumn>Library Variable Set</DataTableHeaderColumn>
                            </DataTableRow>
                        </DataTableHeader>
                        <DataTableBody>
                            {sortBy(accountUsages.LibraryVariableSets, [(x) => x.LibraryVariableSetName, "asc"]).map((usageEntry: LibraryVariableSetUsageEntry, idx) => {
                                const rowKey = `AULVS-${usageEntry.LibraryVariableSetId}`;
                                return (
                                    <DataTableRow key={rowKey}>
                                        <DataTableRowColumn>
                                            <InternalLink to={routeLinks.library.variableSet(usageEntry.LibraryVariableSetId)}>{usageEntry.LibraryVariableSetName}</InternalLink>
                                        </DataTableRowColumn>
                                    </DataTableRow>
                                );
                            })}
                        </DataTableBody>
                    </DataTable>
                )}
            </div>
        );
    }

    private accountUsageInTargets(accountUsages: AccountUsageResource) {
        return (
            <div>
                {accountUsages.Targets.length > 0 && (
                    <DataTable>
                        <DataTableHeader>
                            <DataTableRow>
                                <DataTableHeaderColumn>Target Name</DataTableHeaderColumn>
                            </DataTableRow>
                        </DataTableHeader>
                        <DataTableBody>
                            {sortBy(accountUsages.Targets, [(x) => x.TargetName, "asc"]).map((usageEntry: TargetUsageEntry, idx) => {
                                const rowKey = `AUT-${usageEntry.TargetId}`;
                                return (
                                    <DataTableRow key={rowKey}>
                                        <DataTableRowColumn>
                                            <InternalLink to={routeLinks.infrastructure.machine(usageEntry.TargetId).root}>{usageEntry.TargetName}</InternalLink>
                                        </DataTableRowColumn>
                                    </DataTableRow>
                                );
                            })}
                        </DataTableBody>
                    </DataTable>
                )}
            </div>
        );
    }
}

export { UsageType };
