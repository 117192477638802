import React from "react";
import FeedResource from "client/resources/feedResource";
import useRequiredContext from "hooks/useRequiredContext";
import { NameOrIdKey } from "components/KeyAccessProvider/types";
import { useKeyedItemAccess } from "components/KeyAccessProvider/KeyedItemAccessProvider";
import ActionTemplateSearchResource from "../../../../../client/resources/actionTemplateSearchResource";

type ProcessActionTemplatesContextValue = ActionTemplateSearchResource[];
type ProcessActionTemplatesRefreshContextValue = () => Promise<unknown>;

const ProcessActionTemplatesContext = React.createContext<ProcessActionTemplatesContextValue>([]);
const RefreshProcessActionTemplatesContext = React.createContext<ProcessActionTemplatesRefreshContextValue | undefined>(undefined);

export function useActionTemplatesFromContext() {
    return useRequiredContext(ProcessActionTemplatesContext, "ProcessActionTemplatesContext");
}

export function useRefreshActionTemplatesFromContext() {
    return useRequiredContext(RefreshProcessActionTemplatesContext, "RefreshProcessActionTemplatesContext");
}

export function useActionTemplateFromContext(id: string) {
    const actionTemplates = useActionTemplatesFromContext();

    return React.useMemo(() => {
        return actionTemplates.find((f) => f.Id === id);
    }, [actionTemplates, id]);
}

type ProcessActionTemplatesContextProviderProps = {
    templates: ActionTemplateSearchResource[];
    refreshActionTemplates: () => Promise<unknown>;
};

export function ProcessActionTemplatesContextProvider(props: React.PropsWithChildren<ProcessActionTemplatesContextProviderProps>) {
    return (
        <ProcessActionTemplatesContext.Provider value={props.templates}>
            <RefreshProcessActionTemplatesContext.Provider value={props.refreshActionTemplates}>{props.children}</RefreshProcessActionTemplatesContext.Provider>
        </ProcessActionTemplatesContext.Provider>
    );
}
