import * as React from "react";
import cn from "classnames";

const styles = require("./style.less");

type DataTableHeaderColumnProps = React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableHeaderCellElement>, HTMLTableHeaderCellElement>;

export class DataTableHeaderColumn extends React.Component<DataTableHeaderColumnProps> {
    render() {
        const { className, ...otherProps } = this.props;
        return (
            <th className={cn(className, styles.dataTableHeaderColumn)} {...otherProps}>
                {this.props.children}
            </th>
        );
    }
}
