/* eslint-disable @typescript-eslint/no-non-null-assertion */
import DashboardResource from "../../../../../client/resources/dashboardResource";

class CurrentVersionMap {
    private environmentVersionMap: Map<string, string>;
    private tenantEnvironmentVersionMap: Map<string, Map<string, string>>;

    constructor(dashboard: DashboardResource) {
        this.environmentVersionMap = new Map<string, string>();
        this.tenantEnvironmentVersionMap = new Map<string, Map<string, string>>();

        for (const item of dashboard.Items) {
            if (item.TenantId) {
                if (!this.tenantEnvironmentVersionMap.has(item.TenantId)) {
                    this.tenantEnvironmentVersionMap.set(item.TenantId, new Map<string, string>());
                }

                this.tenantEnvironmentVersionMap.get(item.TenantId)!.set(item.EnvironmentId, item.ReleaseVersion);
            } else {
                this.environmentVersionMap.set(item.EnvironmentId, item.ReleaseVersion);
            }
        }
    }

    getCurrentRelease(environmentId: string, tenantId?: string) {
        if (tenantId) {
            return this.tenantEnvironmentVersionMap.has(tenantId) ? this.tenantEnvironmentVersionMap.get(tenantId)!.get(environmentId) : null;
        }

        return this.environmentVersionMap.get(environmentId);
    }
}

export default CurrentVersionMap;
