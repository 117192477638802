import React from "react";
import { makeStyles, Slide, Snackbar as MaterialSnackbar, SnackbarContentClassKey, GrowProps } from "@material-ui/core";
import { Theme } from "theme";
import { SnackbarClassKey } from "@material-ui/core/Snackbar";
import { SlideProps } from "@material-ui/core/Slide/Slide";
import { OctopusTheme, useOctopusTheme } from "components/Theme";

interface SnackbarProps {
    open: boolean;
    size?: "large" | "small";
    autoHideDuration?: number;
    content: string | React.ReactNode;
    onClose?(): void;
    textAlign?: TextAlign;
}

export function Snackbar({ open, content, size, onClose, autoHideDuration, textAlign }: SnackbarProps) {
    const theme = useOctopusTheme();
    const classes = useSnackbarStyles({ size: size ?? "small" });
    const contentClasses = useContentStyles({ textAlign: textAlign ?? "left", theme });
    const contentProps = { classes: contentClasses };

    const [message, children] =
        typeof content === "string"
            ? [content, undefined]
            : // We need to wrap children in a <div> because MaterialSnackbar requires it child to always have a ref property
              [undefined, <div>{content}</div>];

    return (
        <MaterialSnackbar open={open} message={message} classes={classes} autoHideDuration={autoHideDuration} onClose={onClose} TransitionComponent={SlideUp} ContentProps={contentProps}>
            {children}
        </MaterialSnackbar>
    );
}

const useContentStyles = makeStyles<Theme, { textAlign: TextAlign; theme: OctopusTheme }, SnackbarContentClassKey>({
    root: (props) => ({
        backgroundColor: props.theme.darkBackground,
        color: props.theme.darkText,
    }),
    message: (props) => {
        if (props.textAlign === "center") {
            return {
                textAlign: "center",
                width: "100%",
            };
        }
        return {};
    },
    action: {},
});

const useSnackbarStyles = makeStyles<Theme, { size: Size }, SnackbarClassKey>({
    root: (props) => {
        if (props.size === "large")
            return {
                width: "60%",
                display: "block",
                margin: "1.5em",
                bottom: 0,
            };
        return {};
    },
    anchorOriginTopCenter: {},
    anchorOriginBottomCenter: {},
    anchorOriginTopRight: {},
    anchorOriginBottomRight: {},
    anchorOriginTopLeft: {},
    anchorOriginBottomLeft: {},
});

function SlideUp(props: Omit<SlideProps, "direction">) {
    return <Slide {...props} direction="up" />;
}

type Size = "large" | "small";
type TextAlign = "center" | "left";
