import * as React from "react";
import Tasks from "areas/tasks/components/Tasks/Tasks";
import { RouteComponentProps } from "react-router";
import Logger from "client/logger";

type TenantTasksProps = RouteComponentProps<{ tenantId: string }>;

const TenantTasks: React.FC<TenantTasksProps> = (props) => {
    const tenantId = props.match.params.tenantId;
    if (!tenantId) {
        Logger.error(`Failed to find tenantId from the route. This should not happen.`);
        return null;
    }
    return <Tasks {...props} restrictToTenantId={tenantId} hideScriptConsoleAction={true} />;
};

export default TenantTasks;
