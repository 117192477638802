import * as React from "react";

import ActionButton from "components/Button";
import Dialog from "components/Dialog/Dialog";
import DynamicConnectivityCheckDialog from "components/DynamicForm/DynamicConnectivityCheckDialog";
import { ActionButtonProps } from "components/Button/ActionButton";
import { ProjectResource, VersionControlledPersistenceSettings } from "client/resources";

const TestConnectionButton: React.FC<{ project: ProjectResource; model: VersionControlledPersistenceSettings } & Partial<ActionButtonProps>> = ({ project, model, ...otherProps }) => {
    const [open, setOpen] = React.useState(false);

    const openDialog = () => setOpen(true);
    const closeDialog = () => setOpen(false);

    return (
        <React.Fragment>
            <ActionButton label="Test" {...otherProps} onClick={openDialog} />
            <Dialog open={open}>
                <DynamicConnectivityCheckDialog title="Version Control Settings" url={project.Links["VersionControlSettingsConnectionTest"]} values={model} onOkClick={closeDialog} />
            </Dialog>
        </React.Fragment>
    );
};

export default TestConnectionButton;
