import * as React from "react";
import PaperLayout from "components/PaperLayout/PaperLayout";
import DataLoader from "components/DataLoader";
import { Errors } from "components/DataBaseComponent/Errors";

interface RenderAlternateProps {
    busy: boolean;
    errors: Errors | undefined;
}

interface FormPageProps<TData> {
    title: string;
    load(): Promise<TData>;
    reloadOnPropChanges?: boolean;
    renderWhenLoaded(data: TData): React.ReactNode;
    renderAlternate?(props: RenderAlternateProps): React.ReactNode;
}
// TODO: Consolidate Page and FormPage into a single component. These are doing similar-but-different things.
export default function FormPage<TData>() {
    const DataLoaderInternal = DataLoader<TData>();

    return class LoadInner extends React.Component<FormPageProps<TData>> {
        render() {
            return (
                <DataLoaderInternal
                    load={this.props.load}
                    reloadOnPropChanges={this.props.reloadOnPropChanges}
                    renderWhenLoaded={this.props.renderWhenLoaded}
                    renderAlternate={
                        this.props.renderAlternate
                            ? this.props.renderAlternate
                            : ({ errors, busy }) => {
                                  return <PaperLayout title={this.props.title} errors={errors} busy={busy} />;
                              }
                    }
                />
            );
        }
    };
}
