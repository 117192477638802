/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { connect } from "react-redux";
import { actions as dialogActions } from "components/Dialog/store";
import { DialogLayoutDispatchProps } from "components/DialogLayout/DialogLayout";
import { Dispatch, Action } from "redux";

export class DialogLayoutConnect {
    static to<TProps extends any>(component: React.ComponentType<TProps & DialogLayoutDispatchProps>) {
        const mapGlobalActionDispatchersToProps = (dispatch: Dispatch, props: TProps): DialogLayoutDispatchProps => {
            return {
                close: () => {
                    dispatch(dialogActions.closeAll());
                },
            };
        };
        return connect(null, mapGlobalActionDispatchersToProps)(component as any) as React.ComponentType<TProps>;
    }
}

export default DialogLayoutConnect;
