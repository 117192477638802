import { LinksMenu } from "components/LinksMenu/LinksMenu";
import { MenuNode } from "components/LinksMenu/MenuNode";
import * as React from "react";

interface LinksMenuButtonProps {
    activeItemClassName: string;
    icon?: string;
    label: string;
    moreItems: MenuNode[];
}

export const LinksMenuButton: React.StatelessComponent<LinksMenuButtonProps> = (props) => (
    <li key="navItem-more">
        <LinksMenu items={props.moreItems} label={props.label} icon={props.icon} />
    </li>
);
