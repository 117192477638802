import { WarningChip, DangerChip } from "components/Chips";
import React from "react";
import { DynamicWorkerType } from "client/resources/workerPoolsSupportedTypesResouce";
import moment from "moment";
import DateFormatter from "utils/DateFormatter";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout";
import ExternalLink from "components/Navigation/ExternalLink";
import { Note } from "components/form";

export const DeprecatedDynamicWorkerTypeChip: React.FC<{ workerType: DynamicWorkerType | undefined }> = (props) => {
    if (!props.workerType) {
        return null;
    }
    const dateFormatForHumans = "D MMMM YYYY";

    if (IsWorkerTypeDecommissioned(props.workerType)) {
        const formattedDate = DateFormatter.dateToCustomFormat(moment(props.workerType.EndOfLifeDateUtc), dateFormatForHumans);
        return <DangerChip title="Decommissioned" description={`This worker image was decommissioned on ${formattedDate}. Deployments to this worker pool will fail. Please move to a new worker image.`} />;
    }

    if (IsDeprecated(props.workerType)) {
        if (props.workerType.EndOfLifeDateUtc) {
            const formattedDate = DateFormatter.dateToCustomFormat(moment(props.workerType.EndOfLifeDateUtc), dateFormatForHumans);
            return <WarningChip title="Deprecated" description={`This worker image is deprecated, and will be removed on ${formattedDate}. Please move to a new worker image before this date to prevent deployments from failing.`} />;
        } else {
            return <WarningChip title="Deprecated" description={`This worker image is deprecated, and will be removed soon. Please move to a new worker image to prevent deployments from failing.`} />;
        }
    }
    return null;
};

export const DeprecatedDynamicWorkerTypeCallout: React.FC<{ workerType: DynamicWorkerType | undefined; isNew: boolean }> = (props) => {
    if (!props.workerType) {
        return null;
    }
    const dateFormatForHumans = "D MMMM YYYY";

    if (IsWorkerTypeDecommissioned(props.workerType)) {
        const formattedDate = DateFormatter.dateToCustomFormat(moment(props.workerType.EndOfLifeDateUtc), dateFormatForHumans);
        return (
            <Callout title="Decommissioned" type={CalloutType.Danger}>
                This worker image was decommissioned on {formattedDate}. Deployments to this worker pool will fail. Please move to a new worker image. <ExternalLink href="DeprecatedWorkerImages">Learn More</ExternalLink>
            </Callout>
        );
    }

    if (IsDeprecated(props.workerType)) {
        if (props.workerType.EndOfLifeDateUtc) {
            const formattedDate = DateFormatter.dateToCustomFormat(moment(props.workerType.EndOfLifeDateUtc), dateFormatForHumans);
            const message = props.isNew
                ? `This worker image is deprecated, and will be removed on ${formattedDate}. Where possible, please avoid using this worker image as deployments will begin failing after that date.`
                : `This worker image is deprecated, and will be removed on ${formattedDate}. Please move to a new worker image before this date to prevent deployments from failing.`;
            return (
                <Callout title="Deprecated" type={CalloutType.Warning}>
                    {message} <ExternalLink href="DeprecatedWorkerImages">Learn More</ExternalLink>
                </Callout>
            );
        } else {
            const message = props.isNew
                ? `This worker image is deprecated, and will be removed soon. Where possible, please avoid using this worker image as deployments will begin failing when the the end of life date is reached.`
                : `This worker image is deprecated, and will be removed soon. Please move to a new worker image to prevent deployments from failing when the the end of life date is reached.`;
            return (
                <Callout title="Deprecated" type={CalloutType.Warning}>
                    {message} <ExternalLink href="DeprecatedWorkerImages">Learn More</ExternalLink>
                </Callout>
            );
        }
    }

    if (IsDefaultForOperatingSystem(props.workerType)) {
        return <Note>Octopus will choose the most appropriate {props.workerType.Description} version, and use new versions as they are released.</Note>;
    }

    return null;
};

const IsDefaultForOperatingSystem = (workerType: DynamicWorkerType): boolean => {
    return workerType.Type === workerType.Description + "Default";
};

const IsDeprecated = (workerType: DynamicWorkerType): boolean => {
    if (workerType.DeprecationDateUtc) {
        return moment() > moment(workerType.DeprecationDateUtc);
    }
    return false;
};

export const IsWorkerTypeDecommissioned = (workerType: DynamicWorkerType): boolean => {
    if (workerType.EndOfLifeDateUtc) {
        return moment() > moment(workerType.EndOfLifeDateUtc);
    }
    return false;
};

export const IsWorkerTypeAvailable = (workerType: DynamicWorkerType): boolean => {
    if (workerType.StartDateUtc) {
        if (moment(workerType.StartDateUtc) > moment()) {
            return false;
        }
    }

    return !IsWorkerTypeDecommissioned(workerType);
};
