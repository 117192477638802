import * as React from "react";
import { Switch } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import routeLinks from "routeLinks";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";

interface RouteParams {
    accountId: string;
}

const EditAccount: React.SFC<RouteComponentProps<{ accountId: string }>> = (props) => <InternalRedirect to={{ pathname: routeLinks.infrastructure.account(props.match.params.accountId), search: props.location.search }} />;

export class V3AccountRoutes extends React.Component<RouteComponentProps<RouteParams>> {
    render() {
        const match = this.props.match;
        return (
            <Switch>
                <ReloadableRoute path={"/accounts/new"}>
                    <InternalRedirect to={{ pathname: routeLinks.infrastructure.accounts.create, search: this.props.location.search }} />
                </ReloadableRoute>
                <ReloadableRoute path={"/accounts/:accountId"} component={EditAccount} />
                <ReloadableRoute>
                    <InternalRedirect to={{ pathname: routeLinks.infrastructure.accounts.root, search: this.props.location.search }} />
                </ReloadableRoute>
            </Switch>
        );
    }
}

export default V3AccountRoutes;
