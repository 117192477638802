import React from "react";
import { Callout, CalloutType } from "primitiveComponents/dataDisplay/Callout";
import { Errors } from "components/DataBaseComponent";
import { ProcessContextProps } from "../ProcessContext";
import { ProjectContextProps } from "areas/projects/context";
import FileUploadDropzone from "primitiveComponents/form/FileUploadDragDrop/FileUploadDragDrop";
const styles = require("./style.less");

export const DevToolbarProcessUpload: React.FC<{ processContext: ProcessContextProps; projectContext: ProjectContextProps }> = (props) => {
    const [file, setFile] = React.useState<File | null | undefined>(null);
    const [error, setError] = React.useState<Errors>();
    const [success, setSuccess] = React.useState<boolean | null>(null);

    const tryParseJson = (rawJson: string) => {
        try {
            const process = JSON.parse(rawJson);
            return process;
        } catch (err) {
            return new Error("Unable to parse json: " + err);
        }
    };

    const saveProcess = async (rawJson: string) => {
        const process = tryParseJson(rawJson);
        if (process instanceof Error) {
            setError({
                message: process.message,
                errors: [process.message],
                fieldErrors: {},
            });
            return;
        }

        const model = props.processContext.state.cleanModel;
        if (model instanceof Error) {
            setError({
                message: model.message,
                errors: [model.message],
                fieldErrors: {},
            });
            return;
        }

        const existingProcess = model.process;
        process.Id = existingProcess?.Id;
        process.SpaceId = existingProcess?.SpaceId;
        process.Version = existingProcess?.Version;
        process.ProjectId = existingProcess?.ProjectId;
        process.Links["Self"] = existingProcess?.Links["Self"];

        await props.processContext.actions.saveOnServer(
            props.projectContext.state.projectContextRepository,
            process,
            (errors) => {
                setError(errors);
            },
            () => {
                setSuccess(true);
                setFile(null);
                const fiveSeconds = 5 * 1000;
                setTimeout(() => {
                    setSuccess(null);
                }, fiveSeconds);
            }
        );
    };

    return (
        <div className={styles.uploadContainer}>
            <FileUploadDropzone
                label={"Drag and drop a process json file or select to browse"}
                onFilesChanged={(file) => {
                    setError(undefined);
                    setFile(file[0]);
                    if (file && file.length > 0) {
                        loadFile(file[0], saveProcess);
                    }
                }}
                showAlerts={false}
                showFileChips={false}
            />
            <DeploymentProcessUploadCallout success={success} errors={error} />
        </div>
    );
};

const loadFile = (file: File, saveProcess: (rawJson: string) => Promise<void>) => {
    const reader = new FileReader();
    const DONE = 2;
    reader.onload = async (event) => {
        if (event && event.target && event.target.readyState === DONE) {
            if (typeof event.target.result === "string") {
                await saveProcess(event.target.result);
            } else {
                throw new Error(`Unexpected type returned when reading file. Expected 'string' but got '${typeof event.target.result}'.`);
            }
        }
    };
    reader.readAsText(file);
};

const DeploymentProcessUploadCallout: React.FC<{ success: boolean | null; errors: Errors | undefined }> = (props) => {
    if (props.success) {
        return <DeploymentProcessUploadSuccessCallout />;
    }
    if (props.errors) {
        return <DeploymentProcessUploadErrorCallout errors={props.errors} />;
    }
    return <DeploymentProcessUploadInfoCallout />;
};

const DeploymentProcessUploadSuccessCallout: React.FC<{}> = (props) => {
    return (
        <Callout type={CalloutType.Success} title="Success">
            Process uploaded
        </Callout>
    );
};

const DeploymentProcessUploadErrorCallout: React.FC<{ errors: Errors }> = (props) => {
    return (
        <Callout type={CalloutType.Danger} title="Failed">
            Upload failed: {props.errors.message}
        </Callout>
    );
};

const DeploymentProcessUploadInfoCallout: React.FC<{}> = (props) => {
    return (
        <Callout type={CalloutType.Warning} title={"Experimental feature"}>
            This feature is designed for support. Importing a deployment process may cause errors that will break things. Use with caution.
        </Callout>
    );
};

export default DevToolbarProcessUpload;
