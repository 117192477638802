import * as React from "react";
import { repository } from "clientInstance";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { ServerStatusResource } from "client/resources/serverStatusResource";
import { ActivityLogElement } from "client/resources/taskDetailsResource";
import TaskLogLines from "components/TaskLogLines/TaskLogLines";
import { Section } from "components/Section/Section";
import { reverse } from "lodash";
import { Callout, CalloutType } from "primitiveComponents/dataDisplay/Callout/Callout";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import routeLinks from "../../../../routeLinks";
import HALogWarning from "./HALogWarning";
import { noOp } from "utils/noOp";

interface DetailedServerLogsState extends DataBaseComponentState {
    status?: ServerStatusResource;
    logs?: ActivityLogElement[];
    isHA: boolean;
}

export class DetailedServerLogs extends DataBaseComponent<{}, DetailedServerLogsState> {
    constructor(props: {}) {
        super(props);
        this.state = { isHA: false };
    }

    async componentDidMount() {
        const nodes = repository.OctopusServerNodes.list();
        await this.doBusyTask(async () => {
            await this.refresh();
            this.setState({ isHA: (await nodes).Items.length > 1 });
        });
    }

    refresh = async () => {
        const status = await repository.ServerStatus.getServerStatus();

        this.setState({
            status,
            logs: reverse(await repository.ServerStatus.getLogs(status)),
        });
    };

    render() {
        const refresh = this.state.logs && (
            <PermissionCheck permission={Permission.AdministerSystem}>
                <ActionButton label="Refresh" type={ActionButtonType.Primary} busyLabel="Refreshing..." disabled={this.state.busy} onClick={this.refresh} />
            </PermissionCheck>
        );
        return (
            <PaperLayout title="Detailed Server Logs" breadcrumbTitle={"Diagnostics"} breadcrumbPath={routeLinks.configuration.diagnostics.root} sectionControl={refresh} busy={this.state.busy} errors={this.errors}>
                {this.state.logs && (
                    <div>
                        <Section>The entries below are the most recent warning and error-level events recorded on this Octopus Server node in this session.</Section>
                        <HALogWarning isHA={this.state.isHA} />
                        {this.state.logs.length === 0 && (
                            <Section>
                                <Callout type={CalloutType.Information} title={"No events found"}>
                                    No warning or error-level events have been collected in this session.
                                </Callout>
                            </Section>
                        )}
                        <Section>
                            <TaskLogLines lines={this.state.logs} showAdditional={() => noOp()} />
                        </Section>
                    </div>
                )}
            </PaperLayout>
        );
    }
}

export default DetailedServerLogs;
