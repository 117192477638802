import * as React from "react";
import Tasks from "areas/tasks/components/Tasks/Tasks";
import AreaTitle from "components/AreaTitle";
import { repository, session } from "clientInstance";
import { SpaceResource } from "client/resources";
import routeLinks from "routeLinks";
import DataBaseComponent, { DataBaseComponentState } from "components/DataBaseComponent";
import DrawerWrapperLayout from "components/Drawer/DrawerWrapperLayout";
import PaperLayout from "components/PaperLayout";
import ActionList from "components/ActionList";
import ImportExportMenu from "./ImportExportMenu";
import ImportExportCallout, { ImportExportCalloutType } from "./ImportExportCallouts";
const styles = require("./style.less");

interface ImportExportTasksState extends DataBaseComponentState {
    space?: SpaceResource;
}

class ImportExportTasks extends DataBaseComponent<{}, ImportExportTasksState> {
    constructor(props: {}) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            if (!repository.spaceId) {
                throw new Error("Attempted to render ImportExportTasks in a system context. This should never happen.");
            }
            const [space] = await Promise.all([repository.Spaces.get(repository.spaceId)]);

            this.setState({
                space,
            });
        });
    }

    isSpaceManager(): boolean {
        if (!session.currentPermissions) {
            throw new Error("Attempted to access the current user's permissions, but they weren't found. This should never happen.");
        }
        return this.state.space !== undefined && session.currentPermissions.isSpaceManager(this.state.space);
    }

    render() {
        const isSpaceManager = this.isSpaceManager();
        const overflowMenu = <ImportExportMenu />;

        return (
            <main id="maincontent">
                <AreaTitle title="Projects" link={routeLinks.projects.root}>
                    <ActionList actions={[overflowMenu]} />
                </AreaTitle>
                <DrawerWrapperLayout>
                    <div className={styles.container}>
                        <Tasks
                            renderLayout={(layoutProps) => {
                                return (
                                    <PaperLayout title="Import/Export Tasks" busy={layoutProps.busy} errors={this.errors} fullWidth={true}>
                                        {this.state.space && !isSpaceManager && <ImportExportCallout type={ImportExportCalloutType.PermissionRequired} />}
                                        {this.state.space && isSpaceManager && (
                                            <>
                                                <ImportExportCallout type={ImportExportCalloutType.Eap} />
                                                <div className={styles.layoutContainer}>{layoutProps.children}</div>
                                            </>
                                        )}
                                    </PaperLayout>
                                );
                            }}
                            restrictToTaskTypes={["ExportProjects", "ImportProjects"]}
                            hideScriptConsoleAction={true}
                            hideAdvancedFilters={true}
                        />
                    </div>
                </DrawerWrapperLayout>
            </main>
        );
    }
}

export default ImportExportTasks;
