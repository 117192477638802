import React from "react";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import ExternalLink from "components/Navigation/ExternalLink";

interface ArcWarningCalloutProps {
    parentStep?: boolean;
    stepName?: string;
    deleteAllSteps?: boolean;
    triggersLink: string;
}

export const ArcWarningCallout: React.FC<ArcWarningCalloutProps> = ({ parentStep, stepName, deleteAllSteps, triggersLink }) => {
    const singleStepJsx = (
        <>
            This step can not be deleted because{" "}
            {parentStep ? (
                <>
                    its child step '<strong>{stepName}</strong>'
                </>
            ) : (
                "it"
            )}{" "}
            is used as the trigger for Automatic Release Creation.
        </>
    );

    const allStepsJsx = (
        <>
            The step '<strong>{stepName}</strong>' is used as the trigger for Automatic Release Creation.
        </>
    );

    return (
        <Callout title={`Unable to delete ${deleteAllSteps ? "all steps" : "step"}`} type={CalloutType.Danger}>
            <p>
                {deleteAllSteps ? allStepsJsx : singleStepJsx} To delete {deleteAllSteps ? "all steps" : "this step"} you first need to disable Automatic Release Creation {!deleteAllSteps && <>or choose another step as the trigger</>} on{" "}
                <InternalLink to={triggersLink}>this page</InternalLink>.
            </p>
            <p>
                Learn about <ExternalLink href="AutoReleaseCreation">Automatic Release Creation</ExternalLink>.
            </p>
        </Callout>
    );
};

export default ArcWarningCallout;
