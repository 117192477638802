import { useState } from "react";
import Logger from "client/logger";

function useLocalStorage<T>(key: string, initialValue: T): [T, (val: T) => void] {
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            Logger.warn(error);
            return initialValue;
        }
    });

    const setValue = (value: T) => {
        try {
            const valueToStore = value;
            setStoredValue(valueToStore);
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            // A more advanced implementation would handle the error case
            Logger.warn(error);
        }
    };

    return [storedValue, setValue];
}

export default useLocalStorage;
export { useLocalStorage };
