import * as React from "react";
import TargetList from "./TargetList";
import { RoleChip } from "components/Chips/index";
import ParseHelper from "../../utils/ParseHelper/ParseHelper";

interface RolesProps {
    rolesAsCSV: string;
}

class RolesList extends TargetList<string> {}

const Roles: React.StatelessComponent<RolesProps> = (props) => {
    const roles = ParseHelper.parseCSV(props.rolesAsCSV);
    return (
        <span>
            {roles.length === 1 ? "role" : "roles"} <RolesList targets={roles} renderTarget={(role) => <RoleChip key={role} role={role} />} />
        </span>
    );
};

Roles.displayName = "Roles";

export default Roles;
