/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import Select from "primitiveComponents/form/Select/Select";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { RadioButton, StringRadioButtonGroup } from "components/form";
import { repository } from "clientInstance";
import { ProjectResource, NonVcsRunbookResource, NamedResource, IId, Permission, IProcessResource, RunbookResource, IsNonVcsRunbook } from "client/resources";
import FormComponent from "components/FormComponent/FormComponent";
import { DialogLayout, DialogLayoutCommonProps, DialogLayoutDispatchProps } from "components/DialogLayout/DialogLayout";
import ActionButton, { ActionButtonType } from "components/Button";
import { DialogLayoutConnect } from "components/Dialog/DialogLayoutConnect";
import InfoDialogLayout from "components/DialogLayout/InfoDialogLayout";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout";
import InternalLink from "components/Navigation/InternalLink";
import routeLinks from "routeLinks";
import { Errors } from "components/DataBaseComponent";
import { values, Dictionary, merge, compact, head, property } from "lodash";
import toSelectItem from "utils/toSelectItem";
import toResourceLookup from "utils/toResourceLookup";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";

interface CloneStepProps {
    currentProject: ProjectResource;
    currentRunbook?: RunbookResource;
    actionName: string;
    stepId: string;
    actionId?: string;
    onCloneTargetSelected: (definition: CloneSourceDefinition) => Promise<IProcessResource>;
}

interface CloneStepState extends DataBaseComponentState {
    selectedProject: ProjectResource;
    selectedProjectRunbooks: { [id: string]: NonVcsRunbookResource };
    selectedRunbook?: RunbookResource;
    projects: { [id: string]: ProjectResource };
    processTargetType: ProcessTargetType;
    status: CloneStatus;
}

export enum CloneStepContextType {
    CurrentContext = "CurrentContext",
    DifferentContext = "DifferentContext",
}

export enum ProcessTargetType {
    Deployment = "Deployment",
    Runbook = "Runbook",
}

export interface CloneStepsSource {
    type: ProcessTargetType;
    project: ProjectResource;
}

export interface CloneRunbookProcessSource extends CloneStepsSource {
    type: ProcessTargetType.Runbook;
    runbook: RunbookResource;
}

export interface CloneDeploymentProcessStepsSource extends CloneStepsSource {
    type: ProcessTargetType.Deployment;
}

enum CloneStatus {
    AskingForTarget = "AskingForTarget",
    Success = "Success",
}

export interface CloneSourceDefinition {
    source: CloneStepsSource;
    target: CloneStepsSource;
    targetType: CloneStepContextType;
}

export function isRunbookProcessCloneSource(source: CloneStepsSource | undefined): source is CloneRunbookProcessSource {
    return source !== null && source !== undefined && source.type === ProcessTargetType.Runbook;
}

export function isDeploymentsStepsCloneSource(source: CloneStepsSource | undefined): source is CloneDeploymentProcessStepsSource {
    return source !== null && source !== undefined && source.type === ProcessTargetType.Deployment;
}

interface CloneStepDialogLayoutProps extends DialogLayoutCommonProps {
    onOkClick(): Promise<boolean>;
}

class CloneStepDialogLayoutInternal extends React.Component<CloneStepDialogLayoutProps & DialogLayoutDispatchProps> {
    okClick = async () => {
        const result = await this.props.onOkClick();
        if (result) {
            this.props.close();
        }
    };

    render() {
        const { children, ...other } = this.props;

        const ok = <ActionButton key="Ok" label="Ok" onClick={this.okClick} type={ActionButtonType.Primary} />;
        const cancel = <ActionButton key="Cancel" label="Cancel" onClick={() => this.props.close()} />;
        const actions = [cancel, ok];

        return (
            <DialogLayout actions={actions} closeDialog={this.props.close} {...other}>
                <FormComponent onFormSubmit={this.okClick}>{children}</FormComponent>
            </DialogLayout>
        );
    }
}

const getId = property<IId, string>("Id");
const lookupToSelectItems = <T extends Dictionary<TItem>, TItem extends NamedResource>(lookup: T, filter?: (item: TItem) => boolean) => {
    return (filter ? values(lookup).filter(filter) : values(lookup)).map(toSelectItem);
};

function exhaustiveCheck(param: never) {
    return;
}

const CloneStepDialogLayout = DialogLayoutConnect.to<CloneStepDialogLayoutProps>(CloneStepDialogLayoutInternal);
CloneStepDialogLayout.displayName = "CloneDialogLayout";

interface RunbookSelectorProps {
    project: ProjectResource;
    runbooks?: { [id: string]: NonVcsRunbookResource };
    runbook?: RunbookResource;
    error?: string;
    onChange: (runbook: NonVcsRunbookResource) => void;
    filterItems?: (resource: NonVcsRunbookResource) => boolean;
}

const RunbookSelector: React.FC<RunbookSelectorProps> = ({ project, runbooks, runbook, error, onChange, filterItems }) => {
    const handleChange = (id: string | undefined) => {
        onChange(runbooks![id!]);
    };

    const items = lookupToSelectItems(runbooks!, filterItems);
    if (!project) {
        return null;
    }
    return <Select label="Select Runbook" value={runbook && runbook.Id} error={error} items={items} onChange={handleChange} />;
};

class CloneStepInternal extends DataBaseComponent<CloneStepProps, CloneStepState> {
    constructor(props: CloneStepProps) {
        super(props);

        this.state = {
            selectedRunbook: this.props.currentRunbook,
            selectedProjectRunbooks: {},
            selectedProject: this.props.currentProject,
            projects: {},
            processTargetType: this.props.currentRunbook ? ProcessTargetType.Runbook : ProcessTargetType.Deployment,
            status: CloneStatus.AskingForTarget,
        };
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const projects = await repository.Projects.all();
            const selectedProjectRunbooks = await repository.Projects.getRunbooks(this.state.selectedProject, { take: repository.takeAll });
            this.setState({
                projects: toResourceLookup(projects),
                selectedProjectRunbooks: toResourceLookup(selectedProjectRunbooks.Items),
            });
        });
    }

    renderTargetSelectionView() {
        const hasProcessViewPermissions = isAllowed({
            permission: Permission.ProcessView,
            project: this.props.currentProject.Id,
            tenant: "*",
        });
        return (
            <CloneStepDialogLayout title="Clone Step" busy={this.state.busy} errors={this.errors} onOkClick={this.onOk}>
                <p>
                    Clone the step <strong>{this.props.actionName}</strong> to:
                </p>

                <Select
                    label="Select project"
                    value={this.state.selectedProject && this.state.selectedProject.Id}
                    error={this.errors && this.errors.fieldErrors.selectedProjectId}
                    items={lookupToSelectItems(this.state.projects)}
                    allowFilter={true}
                    onChange={this.onSelectProject}
                />

                {Object.keys(this.state.selectedProjectRunbooks).length > 0 && hasProcessViewPermissions && (
                    <StringRadioButtonGroup value={this.state.processTargetType} onChange={(selection) => this.changeProcessTargetType(selection as ProcessTargetType)}>
                        <RadioButton value={ProcessTargetType.Deployment} label={"Deployment Process"} />
                        <RadioButton value={ProcessTargetType.Runbook} label={"Runbook"} />
                    </StringRadioButtonGroup>
                )}

                {this.state.processTargetType === ProcessTargetType.Runbook && (
                    <p>
                        <RunbookSelector
                            error={this.errors && this.errors.fieldErrors.selectedRunbookId}
                            project={this.state.selectedProject}
                            runbooks={this.state.selectedProjectRunbooks}
                            runbook={this.state.selectedRunbook}
                            onChange={this.onSelectRunbook}
                        />
                    </p>
                )}
            </CloneStepDialogLayout>
        );
    }

    changeProcessTargetType = (processTargetType: ProcessTargetType) => {
        const runbooks = values(this.state.selectedProjectRunbooks);
        const nextSelectedRunbook = this.state.selectedRunbook ? this.state.selectedRunbook : processTargetType === ProcessTargetType.Runbook && runbooks.length > 0 ? head(runbooks) : null;
        this.setState({ processTargetType, selectedRunbook: nextSelectedRunbook! });
    };

    onSelectProject = async (id: string | undefined) => {
        const selectedProject = this.state.projects[id!];
        if (this.state.selectedProject.Id === id) {
            return;
        }

        await this.doBusyTask(async () => {
            const selectedProjectRunbooks = await repository.Projects.getRunbooks(selectedProject, { take: repository.takeAll });
            const nextType = this.state.processTargetType === ProcessTargetType.Runbook && selectedProjectRunbooks.Items.length > 0 ? ProcessTargetType.Runbook : ProcessTargetType.Deployment;
            this.setState({
                selectedProject: this.state.projects[id!],
                selectedRunbook: nextType === ProcessTargetType.Runbook && selectedProjectRunbooks.Items.length > 0 ? head(selectedProjectRunbooks.Items) : null!,
                processTargetType: nextType,
                selectedProjectRunbooks: toResourceLookup(selectedProjectRunbooks.Items),
            });
        });
    };

    onSelectRunbook = (runbook: NonVcsRunbookResource) => {
        this.setState({
            selectedRunbook: runbook,
        });
    };

    getCloneDefinition = (): CloneSourceDefinition | undefined => {
        const baseDetails: Pick<CloneSourceDefinition, "source" | "targetType"> = { source: this.getCurrentContextSource(), targetType: this.getCloneStepContextType() };

        if (this.state.processTargetType === ProcessTargetType.Deployment) {
            return { ...baseDetails, target: this.getDeploymentProcessSource(this.state.selectedProject) };
        } else if (this.state.processTargetType === ProcessTargetType.Runbook) {
            return { ...baseDetails, target: this.getRunbookSource(this.state.selectedProject, this.state.selectedRunbook!) };
        }

        exhaustiveCheck(this.state.processTargetType);
    };

    getCloneStepContextType = () => {
        const currentProjectId = getId(this.props.currentProject);
        const selectedProjectId = getId(this.state.selectedProject);
        const currentRunbookId = getId(this.props.currentRunbook!);
        const selectedRunbookId = getId(this.state.selectedRunbook!);

        //If we are cloning from a deployment process
        if (!this.props.currentRunbook && this.state.processTargetType === ProcessTargetType.Deployment && currentProjectId === selectedProjectId) {
            return CloneStepContextType.CurrentContext;
        } else if (this.props.currentRunbook && this.state.processTargetType === ProcessTargetType.Runbook && currentRunbookId === selectedRunbookId) {
            return CloneStepContextType.CurrentContext;
        }

        return CloneStepContextType.DifferentContext;
    };

    getDeploymentProcessSource = (project: ProjectResource): CloneDeploymentProcessStepsSource => {
        return { project, type: ProcessTargetType.Deployment };
    };

    getRunbookSource = (project: ProjectResource, runbook: RunbookResource): CloneRunbookProcessSource => {
        return { project, runbook, type: ProcessTargetType.Runbook };
    };

    getCurrentContextSource = (): CloneStepsSource => {
        if (this.props.currentRunbook) {
            return this.getRunbookSource(this.props.currentProject, this.props.currentRunbook);
        } else {
            return this.getDeploymentProcessSource(this.props.currentProject);
        }
    };

    renderSuccessView() {
        const source = this.getCloneDefinition()?.target;
        const runbook = isRunbookProcessCloneSource(source) ? source.runbook : null;

        return (
            <InfoDialogLayout title="Clone Successful" busy={this.state.busy} errors={this.errors}>
                {!runbook && <ClonedToDeploymentProcessSucces project={source!.project} actionName={this.props.actionName} />}
                {runbook && <ClonedToRunbookSuccessMessage project={source!.project} runbook={runbook} actionName={this.props.actionName} />}

                <Callout type={CalloutType.Warning} title="Variables">
                    No variables were copied - consider reviewing the cloned step and manually copy any required variables.
                </Callout>
            </InfoDialogLayout>
        );
    }

    render() {
        switch (this.state.status) {
            case CloneStatus.AskingForTarget:
                return this.renderTargetSelectionView();
            case CloneStatus.Success:
                return this.renderSuccessView();
        }
    }

    validateSelectedProject = (): Errors | undefined => {
        if (!this.state.selectedProject) {
            return {
                message: "Please select a project",
                fieldErrors: { selectedProjectId: "Select a project" },
                errors: [],
                details: {},
            };
        }
    };

    validateSelectedRunbook = (): Errors | undefined => {
        if (!this.state.selectedRunbook) {
            return {
                message: "Please select a runbook",
                fieldErrors: { selectedRunbookId: "Select a runbook" },
                errors: [],
                details: {},
            };
        }
    };

    mergeErrors = (errors: Array<Errors | undefined>): Errors | undefined => {
        const compacted = compact(errors);
        if (compacted.length === 0) {
            return;
        } else if (compacted.length === 1) {
            return compacted[0];
        }

        return compacted.reduce((prev, current) => {
            return { ...prev, fieldErrors: merge(prev.fieldErrors, current.fieldErrors) };
        }, compacted[0]);
    };

    validate = () => {
        const errors: Array<Errors | undefined> = [];

        if (this.state.processTargetType === ProcessTargetType.Runbook) {
            errors.push(this.validateSelectedProject());
            errors.push(this.validateSelectedRunbook());
        }

        return this.mergeErrors(errors);
    };

    private onOk = async () => {
        const errors = this.validate();
        if (errors) {
            this.setValidationErrors(errors.message, errors.fieldErrors);
            return false;
        }

        const definition = this.getCloneDefinition()!;
        const contextType = this.getCloneStepContextType();
        await this.doBusyTask(() => this.props.onCloneTargetSelected(definition));
        if (contextType === CloneStepContextType.CurrentContext) {
            return true;
        }

        this.setState({ status: CloneStatus.Success });
        return false;
    };
}

const ClonedToRunbookSuccessMessage: React.FC<{ project: ProjectResource; runbook: RunbookResource; actionName: string }> = ({ project, actionName, runbook }) => {
    if (!IsNonVcsRunbook(runbook)) {
        throw new Error("FIXME: cac-runbooks: Cloning to a VCS runbooks in to yet supported.");
    }
    return (
        <p>
            Step <strong>{actionName}</strong> has been successfully cloned to project {project.Name} runbook &nbsp;
            <InternalLink to={routeLinks.project(project.Slug).operations.runbook(runbook.Id).runbookProcess.runbookProcess(runbook.RunbookProcessId).process.root}>{runbook.Name}</InternalLink>
        </p>
    );
};

const ClonedToDeploymentProcessSucces: React.FC<{ project: ProjectResource; actionName: string }> = ({ project, actionName }) => {
    return (
        <p>
            Step <strong>{actionName}</strong> has been successfully cloned to <InternalLink to={routeLinks.project(project.Slug).deployments.process.root}>{project.Name}</InternalLink>.
        </p>
    );
};

export { CloneStepContextType as CloneStepTarget };

export default CloneStepInternal;
