import { Client, Repository } from "./client";
import URI from "urijs";
import Session from "session";
import { ClientConfiguration } from "client/client";
import octopusDynamicExtensions from "areas/dynamicExtensions";

const browserURI = URI(window.location);
const configuration: ClientConfiguration = {
    serverEndpoint: resolveEndpoint(),
};
const cookieName = "OctopusDevExperience.Server";
const octopusServerURI = browserURI.search(true)["octopus.server"];
if (!octopusServerURI) {
    document.cookie = `${cookieName}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
} else {
    document.cookie = `${cookieName}=${octopusServerURI}`;
}

octopusDynamicExtensions.setApiDocumentRootUrl(configuration.serverEndpoint + "/api");

const client = Client.Create(configuration);
const repository = new Repository(client);
const session = new Session();

function resolveEndpoint() {
    let endpoint = "" + window.location.protocol;
    if (!endpoint.endsWith("//")) {
        endpoint = endpoint + "//";
    }

    endpoint = endpoint + window.location.host;

    let path = window.location.pathname;
    if (!path.startsWith("/")) {
        path = "/" + path;
    }

    if (path.length >= 1) {
        const lastSegmentIndex = path.lastIndexOf("/");
        if (lastSegmentIndex >= 0) {
            path = path.substring(0, lastSegmentIndex + 1);
        }
    }

    endpoint = endpoint + path;
    return endpoint;
}

export { client, repository, session };
