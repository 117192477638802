import * as React from "react";
import { RouteComponentProps, Switch } from "react-router";
import VariableSets from "areas/library/components/VariableSets/VariableSets";
import NewVariableSet from "areas/library/components/VariableSets/NewVariableSet";
import VariableSet from "areas/library/components/VariableSets/VariableSet";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import routeLinks from "../../../../routeLinks";
import pageIds from "pageIds";
import { withPage } from "components/Page/Page";

const NewVariableSetPage = withPage({ page: pageIds.library.variableSets.create })(NewVariableSet);
const VariableSetPage = withPage({ page: pageIds.library.variableSet })(VariableSet);
const ListVariableSetPage = withPage({ page: pageIds.library.variableSets.root })(VariableSets);

export default class VariableSetsRoutes extends React.Component<RouteComponentProps<void>> {
    render() {
        const libraryLinks = routeLinks.library;
        return (
            <Switch>
                <ReloadableRoute path={`${libraryLinks.variableSets}/new`} component={NewVariableSetPage} />
                <ReloadableRoute path={libraryLinks.variableSet(":variableSetId")} component={VariableSetPage} />
                <ReloadableRoute component={ListVariableSetPage} />
            </Switch>
        );
    }
}
