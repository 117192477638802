import * as React from "react";
import routeLinks from "routeLinks";
import OverflowMenu, { OverflowMenuItems } from "components/Menu/OverflowMenu";

export default class ImportExportMenu extends React.Component {
    render() {
        return (
            <OverflowMenu
                tabIndex={-1}
                menuItems={[
                    OverflowMenuItems.navItem("Import Projects", routeLinks.import.root),
                    OverflowMenuItems.navItem("Export Projects", routeLinks.export.root),
                    OverflowMenuItems.navItem("View Import Export Tasks", routeLinks.importExport.root),
                ]}
            />
        );
    }
}
