import Client from "../client";
import { UserIdentityMetadataResource } from "client/authentication";
import UserAuthenticationResource from "client/resources/userAuthenticationResource";

class UserIdentityMetadataRepository {
    private client: Client;
    constructor(client: Client) {
        this.client = client;
    }
    all(): Promise<UserIdentityMetadataResource> {
        return this.client.get(this.client.getLink("UserIdentityMetadata"));
    }
    authenticationConfiguration(userId: string) {
        return this.client.get<UserAuthenticationResource>(this.client.getLink("UserAuthentication"), { userId });
    }
}

export default UserIdentityMetadataRepository;
