import React, { useRef } from "react";
//eslint-disable-next-line no-restricted-imports
import { ClickAwayListener, createStyles, Fade, InputAdornment, makeStyles, Theme } from "@material-ui/core";
//eslint-disable-next-line no-restricted-imports
import SearchIcon from "@material-ui/icons/Search";
import { ThemeStorage } from "components/Theme/ThemeStorage";
import GlobalSearch from "./GlobalSearch";
import DataBaseComponent, { DataBaseComponentState, DoBusyTask, Errors } from "components/DataBaseComponent";
import { useHotkeys } from "react-hotkeys-hook";
import { useOctopusTheme } from "components/Theme";
import IconButton from "components/IconButton";
import { Icon } from "components/IconButton/IconButton";
import SimplePopper from "primitiveComponents/dataDisplay/Popper/SimplePopper";
import { DebounceText, TextInput } from "primitiveComponents/form/Text/Text";
import MobileDetect = require("mobile-detect");

const keycode = require("keycode");
const styles = require("./SearchAndOpenPopover.less");

//eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SearchAndOpenPopoverState extends DataBaseComponentState {}

// We're just using a class component to inject our doBusyTask. When we have functional equivlanets, this can be removed.
export class SearchAndOpenPopover extends DataBaseComponent<{}, SearchAndOpenPopoverState> {
    render() {
        return <SearchAndOpenPopoverInternal doBusyTask={this.doBusyTask} busy={this.state?.busy} errors={this.errors} />;
    }
}

interface SearchAndOpenPopoverProps {
    doBusyTask: DoBusyTask;
    busy?: Promise<void>;
    errors?: Errors;
}

interface SearchAndOpenPopoverState {
    keyword: string;
    isPopoverOpen: boolean;
    selectedIndex: number;
}

const SearchAndOpenPopoverInternal: React.FC<SearchAndOpenPopoverProps> = ({ doBusyTask, busy, errors }) => {
    const defaultSelectedIndex = -1; // Don't set this to 0 to try and automatically select the first result (or it takes away focus from the input while you're typing).
    const octopusTheme = useOctopusTheme();
    const [popoverState, setPopoverState] = React.useState<SearchAndOpenPopoverState>({ keyword: "", isPopoverOpen: false, selectedIndex: defaultSelectedIndex });
    const searchInputRef = useRef<TextInput | null>(null);
    const searchAnchorElement = useRef<HTMLDivElement | null>(null);

    const handleOpen = (e: React.MouseEvent | null) => {
        e?.preventDefault();
        setPopoverState((prevState) => ({ ...prevState, isPopoverOpen: true, selectedIndex: defaultSelectedIndex }));

        // Need to scroll to top for mobile, or the results stay at the top of the container.
        const md = new MobileDetect(window.navigator.userAgent);
        if (md.isPhoneSized()) {
            window.scroll({ top: 0, left: 0 });
        }
    };

    const handleClose = () => {
        setPopoverState((prevState) => ({ ...prevState, isPopoverOpen: false, selectedIndex: defaultSelectedIndex, keyword: "" }));
    };

    useHotkeys(
        "ctrl+space",
        () => {
            handleOpen(null);
        },
        {
            filter: () => true, // This allows us to use hotkeys while inside an input (so we can toggle out of a focused input).
        },
        []
    );

    React.useEffect(() => {
        if (popoverState.isPopoverOpen) {
            searchInputRef?.current?.focus();
        } else {
            searchInputRef?.current?.blur();
        }
    }, [popoverState.isPopoverOpen, searchInputRef]);

    const handleFilterKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        switch (keycode(event)) {
            case "esc":
                handleClose();
                break;
            case "up":
                {
                    if (!popoverState.keyword) {
                        // Guard against navigation if there's no search.
                        return;
                    }
                    const selectedIndex = popoverState.selectedIndex;
                    const newSelectedIndex = selectedIndex > -1 ? selectedIndex - 1 : -1;
                    if (newSelectedIndex === -1) {
                        searchInputRef?.current?.focus();
                        setPopoverState((prevState) => ({ ...prevState, selectedIndex: newSelectedIndex }));
                    } else {
                        setPopoverState((prevState) => ({ ...prevState, selectedIndex: newSelectedIndex }));
                    }
                }
                break;
            case "down":
                {
                    if (!popoverState.keyword) {
                        // Guard against navigation if there's no search.
                        return;
                    }
                    const selectedIndex = popoverState.selectedIndex;
                    setPopoverState((prevState) => ({ ...prevState, selectedIndex: selectedIndex + 1 }));
                }
                break;
        }
    };

    const stylesForSearchInput = useStylesForSearchAndPopover();

    return (
        <div className={styles.root} onKeyDown={handleFilterKeyDown}>
            <ClickAwayListener onClickAway={handleClose}>
                <div className={styles.contentContainer}>
                    <div
                        className={styles.searchControls}
                        ref={searchAnchorElement}
                        onClick={() => {
                            searchInputRef?.current?.focus();
                        }}
                    >
                        <div className={styles.searchContainer}>
                            <DebounceText
                                placeholder="Search..."
                                autoComplete={"off"}
                                inputProps={{
                                    "aria-label": "Search",
                                    startAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon htmlColor={octopusTheme.iconNeutral} className={styles.searchIconButton} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: popoverState.keyword && (
                                        <IconButton
                                            className={styles.clearTextAction}
                                            onClick={() => {
                                                // Refocus when they cancel/clear text to make typing your next search easy.
                                                searchInputRef?.current?.focus();
                                                setPopoverState((prevState) => ({ ...prevState, keyword: "" }));
                                            }}
                                            icon={Icon.Cancel}
                                        />
                                    ),
                                    classes: stylesForSearchInput,
                                    disableUnderline: true,
                                }}
                                value={popoverState.keyword ?? ""}
                                //eslint-disable-next-line @typescript-eslint/no-explicit-any
                                onChange={(e: any) => {
                                    setPopoverState((prevState) => ({ ...prevState, keyword: e }));
                                    if (!popoverState.isPopoverOpen) {
                                        handleOpen(null);
                                    }
                                }}
                                onFocus={() => {
                                    if (!popoverState.isPopoverOpen) {
                                        handleOpen(null);
                                    }
                                    // Automatically select all if the user re-opens the search with an existing keyword (UX nicety).
                                    if (popoverState.keyword) {
                                        searchInputRef?.current?.select();
                                    }
                                }}
                                textInputRef={(inputElement) => {
                                    searchInputRef.current = inputElement;
                                }}
                                margin="dense"
                            />
                        </div>
                    </div>
                    <SimplePopper
                        id={popoverState.isPopoverOpen ? "transitions-popper" : undefined}
                        placement="bottom-end"
                        anchorEl={searchAnchorElement.current}
                        open={popoverState.isPopoverOpen}
                        className={styles.popper}
                        disablePortal={false}
                        transition={true}
                    >
                        <Fade in={popoverState.isPopoverOpen} mountOnEnter unmountOnExit>
                            <div>
                                <GlobalSearch
                                    keyword={popoverState.keyword.trim()}
                                    selectedIndex={popoverState.selectedIndex}
                                    resetToSelectedIndex={(index: number) => {
                                        setPopoverState((prevState) => ({ ...prevState, selectedIndex: index }));
                                    }}
                                    doBusyTask={doBusyTask}
                                    busy={busy}
                                    errors={errors}
                                    handleClose={handleClose}
                                />
                            </div>
                        </Fade>
                    </SimplePopper>
                </div>
            </ClickAwayListener>
        </div>
    );
};

const useStylesForSearchAndPopover = () => {
    const themeName = ThemeStorage.get();
    const dark = useStylesForSearchDark();
    const regular = useStylesForSearchRegular();
    return themeName === "dark" ? dark : regular;
};

const commonStylesForSearchRoot = (theme: Theme) => {
    return {
        overflow: "hidden",
        borderRadius: 4,
        padding: "0.3rem 1rem 0.3rem 0.25rem",
        width: "220px",
        [theme.breakpoints.down("sm")]: {
            width: "180px",
        },
    };
};

const commonStylesForInput = () => {
    return { padding: "0 0.25rem" };
};

const useStylesForSearchDark = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            ...commonStylesForSearchRoot(theme),
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.8)",
            },
            "&$focused": {
                backgroundColor: "rgba(0, 0, 0, 0.8)",
            },
            color: "white",
        },
        focused: {},
        input: { ...commonStylesForInput() },
    })
);

const useStylesForSearchRegular = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            ...commonStylesForSearchRoot(theme),
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.8)",
            },
            "&$focused": {
                backgroundColor: "rgba(255, 255, 255, 0.8)",
            },
            color: "black",
        },
        focused: {},
        input: { ...commonStylesForInput() },
    })
);

export default SearchAndOpenPopover;
