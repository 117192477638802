import { useHistory } from "react-router";
import { LocationDescriptor } from "history";
import { resolvePathWithSpaceId, isLocationDescriptorObject } from "../resolvePathWithSpaceId";

export class SpaceAwareNavigation {
    constructor(private readonly history: ReturnType<typeof useHistory>, private readonly spaceId: string | undefined) {
        this.history = history;
        this.spaceId = spaceId;
    }

    navigate = (path: string | LocationDescriptor) => {
        this.history.push(resolvePathWithSpaceId(path, this.spaceId));
    };
    redirect = (path: string | LocationDescriptor) => {
        this.history.push(resolvePathWithSpaceId(path, this.spaceId));
    };
    open = (path: string | LocationDescriptor) => {
        window.open(`#/${resolvePathWithSpaceId(path, this.spaceId)}`);
    };
}
