import { Client } from "client";
import ProjectExportRequest from "client/resources/projectExportRequest";
import ProjectExportResponse from "client/resources/projectExportResponse";
import ProjectImportRequest from "client/resources/projectImportRequest";
import ProjectImportResponse from "client/resources/projectImportResponse";
import ProjectImportFileListResponse from "client/resources/projectImportFileListResponse";
import ProjectImportFile from "client/resources/projectImportFile";
import ProjectImportPreviewRequest from "client/resources/projectImportPreviewRequest";
import ProjectImportPreviewResponse from "client/resources/projectImportPreviewResponse";

class ImportExportActions {
    protected client: Client;

    constructor(client: Client) {
        this.client = client;
    }

    export(exportRequest: ProjectExportRequest): Promise<ProjectExportResponse> {
        return this.client.post(this.client.getLink("ExportProjects"), exportRequest);
    }

    import(importRequest: ProjectImportRequest): Promise<ProjectImportResponse> {
        return this.client.post(this.client.getLink("ImportProjects"), importRequest);
    }

    preview(importRequest: ProjectImportPreviewRequest): Promise<ProjectImportPreviewResponse> {
        return this.client.post(this.client.getLink("ProjectImportPreview"), importRequest);
    }

    files(): Promise<ProjectImportFileListResponse> {
        return this.client.get(this.client.getLink("ProjectImportFiles"));
    }

    upload(pkg: File): Promise<ProjectImportFile> {
        const fd = new FormData();
        fd.append("fileToUpload", pkg);
        return this.client.post<ProjectImportFile>(this.client.getLink("ProjectImportFiles"), fd);
    }
}

export default ImportExportActions;
