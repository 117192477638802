import * as React from "react";
import { UserResource } from "client/resources";
import { Avatar } from "primitiveComponents/dataDisplay/Avatar/Avatar";
import ExternalLink from "components/Navigation/ExternalLink";

interface GravatarProps {
    user: UserResource;
    size?: number;
}

const Gravatar: React.StatelessComponent<GravatarProps> = (props) => {
    return (
        <div>
            <Avatar avatarLink={props.user && props.user.Links && props.user.Links.Avatar} isService={props.user && props.user.IsService} size={props.size} />
            <div>
                You can change your picture at <ExternalLink href="Gravatar">Gravatar.com</ExternalLink>
            </div>
        </div>
    );
};

Gravatar.displayName = "Gravatar";

export default Gravatar;
