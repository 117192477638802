import * as React from "react";
import { LogsBase } from "areas/configuration/components/Diagnostics/Logs/LogsBase";

export class ScheduledDeployLogs extends LogsBase {
    constructor(props: {}) {
        super("ProcessRecurringScheduledDeployments", "Scheduled Trigger Logs", props);
    }
}

export default ScheduledDeployLogs;
