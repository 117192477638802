import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonProjectProcessOverview, CommonProjectProcessTopics } from "../../CommonComponents/CommonProjectProcessViews";
import { ProcessType } from "client/resources";

export const ProjectProcessRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().runbook.process.root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectProcessOverview processType={ProcessType.Runbook} />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectProcessTopics />;
    },
};
