import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonTenantTopics } from "../../CommonComponents/CommonTenantViews";
import { CommonTenantOverviewViews } from "../../CommonComponents/CommonTenantOverviewViews";

export const TenantVariablesRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.tenant.variables,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonTenantOverviewViews />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonTenantTopics />;
    },
};
