import * as React from "react";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import OnboardingPage from "../../../components/GettingStarted/OnboardingPage";

const Onboarding = () => (
    <OnboardingPage
        title="Add Tenants to start deploying your software to multiple end-customers"
        intro="Use Tenants to deploy your projects to multiple end-customers, with the software tailored to each specific customer."
        learnMore={
            <>
                <ExternalLink href="MultiTenantDeployments">Learn more</ExternalLink>
            </>
        }
    />
);

export default Onboarding;
