import { Reducer } from "redux";
import { getType } from "typesafe-actions";
import actions, { DrawerAction } from "./actions";

export interface DrawerState {
    isOpen: boolean;
}
const updateConfig = (state: DrawerState, patch: Partial<DrawerState>) => {
    return state ? { ...state, ...patch } : state;
};

const setOpenDrawer = (state: DrawerState) => {
    return updateConfig(state, { isOpen: true });
};

const setCloseDrawer = (state: DrawerState) => {
    return updateConfig(state, { isOpen: false });
};

const setDrawerVisibility = (state: DrawerState, value: boolean) => {
    return updateConfig(state, { isOpen: value });
};

const toggleDrawer = (state: DrawerState) => {
    return setDrawerVisibility(state, !state.isOpen);
};

const INITIAL_STATE: DrawerState = { isOpen: true };

const drawerConfigReducer: Reducer<DrawerState> = (state: DrawerState = INITIAL_STATE, action: DrawerAction) => {
    switch (action.type) {
        case getType(actions.openDrawer):
            return setOpenDrawer(state);
        case getType(actions.closeDrawer):
            return setCloseDrawer(state);
        case getType(actions.setDrawerVisibility):
            return setDrawerVisibility(state, action.payload);
        case getType(actions.toggleDrawer):
            return toggleDrawer(state);
        default:
            return state;
    }
};

export default drawerConfigReducer;
