/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable no-restricted-imports */

import BaseComponent from "components/BaseComponent";
import { client } from "clientInstance";
import { OctopusError } from "client/resources";
import Logger from "client/logger";
import ActionButton, { ActionButtonType } from "components/Button";
import React from "react";
import { Collapse } from "@material-ui/core";
import { Note, ErrorPanel } from "components/form";
import AuthenticationError from "client/resources/authenticationError";
import { LoadingImage } from "components/Images/LoadingCowboy/LoadingImage";
const styles = require("./style.less");

interface ClientConnectorProps {
    renderWhenConnected: () => JSX.Element;
}

interface ClientConnectorState {
    inProgressMessage: string;
    connectError: OctopusError | null;
    isConnected: boolean;
    showFullErrorMessage: boolean;
    authenticationError?: AuthenticationError;
}

export class ClientConnector extends BaseComponent<ClientConnectorProps, ClientConnectorState> {
    constructor(props: ClientConnectorProps) {
        super(props);
        this.state = {
            isConnected: false,
            connectError: null,
            inProgressMessage: "Trying to connect to Octopus",
            showFullErrorMessage: false,
        };
    }

    async componentDidMount() {
        await this.connectClient();
    }

    async connectClient() {
        await client.connect((progress: string, error: OctopusError | undefined) => {
            Logger.log("Progress: ", progress);
            if (error) {
                Logger.log("Error: ", error);
            }
            this.setState({ inProgressMessage: progress, connectError: error as OctopusError });
        });
        this.setState({ isConnected: true });
    }

    render() {
        return this.state.isConnected ? this.props.renderWhenConnected() : this.progressStatus();
    }

    private progressStatus() {
        return (
            <>
                <div className={styles.container}>
                    <div className={styles.loading}>
                        <div className={styles.inProgressMessage}>{this.state.inProgressMessage}</div>
                        <LoadingImage width={"18.75rem"} />
                        {this.state.authenticationError && this.showError(this.state.authenticationError)}
                        {this.state.connectError && (
                            <div className={styles.errorInfoPanel}>
                                <ActionButton onClick={this.toggleFullErrorMessage} label={this.state.showFullErrorMessage ? "Hide Details" : "Show Details"} type={ActionButtonType.Ternary} />
                            </div>
                        )}
                    </div>

                    <Collapse in={this.state.showFullErrorMessage} timeout="auto" unmountOnExit={true}>
                        {this.state.connectError && (
                            <div className={styles.errorInfoPanelDetails}>
                                <h3>{this.state.connectError.ErrorMessage}</h3>
                                <Note>
                                    {this.state.connectError.Errors &&
                                        (this.state.connectError.Errors.length > 1 ? (
                                            <div>
                                                Errors:{" "}
                                                {this.state.connectError.Errors.map((x) => (
                                                    <li>{x}</li>
                                                ))}
                                            </div>
                                        ) : (
                                            <div>{this.state.connectError.Errors[0]}</div>
                                        ))}
                                    {this.state.connectError.FullException && <div>FullException: {this.state.connectError.FullException}</div>}
                                </Note>
                            </div>
                        )}
                    </Collapse>
                </div>
            </>
        );
    }

    private toggleFullErrorMessage = () => {
        this.setState({ showFullErrorMessage: !this.state.showFullErrorMessage });
    };

    private showError(authenticationError: AuthenticationError) {
        return (
            <div className={styles.authenticationError}>
                <ErrorPanel message={authenticationError.ErrorMessage} errors={authenticationError.Errors} />
            </div>
        );
    }
}
