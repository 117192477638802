import * as React from "react";
import cn from "classnames";

const styles = require("./style.less");

interface DataTableFooterProps {
    className?: string;
}

export class DataTableFooter extends React.Component<DataTableFooterProps> {
    render() {
        return <tfoot className={cn(this.props.className, styles.dataTableFooter)}>{this.props.children}</tfoot>;
    }
}
