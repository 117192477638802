import * as React from "react";
import { CategoryDefinition } from "./endpointRegistry";
import ExternalLink from "components/Navigation/ExternalLink";
import { Note } from "components/form";

const category: CategoryDefinition = {
    category: "Mac",
    title: (
        <React.Fragment>
            What type of <strong>Mac target</strong> do you want to set up?
        </React.Fragment>
    ),
    help: (
        <Note>
            Learn more about <ExternalLink href="SSHTargets">SSH Targets</ExternalLink>
        </Note>
    ),
    displayOrder: 25,
};

export default category;
