/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { CommunicationStyle, MachineModelHealthStatus } from "client/resources";
import { repository } from "clientInstance";
import { EnvironmentMachinesArgs, EnvironmentSummaryArgs } from "client/repositories/environmentRepository";
import { EnvironmentSummaryQuery } from "./EnvironmentSummaryQuery";
import { arrayValueFromQueryString } from "utils/ParseHelper/ParseHelper";
import { BaseInfrastructureFilter, defaultBaseInfrastructureFilter } from "../MachinesLayout/MachineFilter";
import { TagIndex } from "components/tenantTagsets";

export interface EnvironmentSummaryFilter extends BaseInfrastructureFilter {
    environmentIds: string[];
    roles: string[];
    tenantIds: string[];
    tenantTags: string[];
    shellNames: string[];
    hideEmptyEnvironments: boolean;
    machinePartialName: string | undefined;
}

export const defaultEnvironmentSummaryFilter: EnvironmentSummaryFilter = {
    ...defaultBaseInfrastructureFilter,
    environmentIds: [],
    roles: [],
    tenantIds: [],
    tenantTags: [],
    shellNames: [],
    hideEmptyEnvironments: false,
    machinePartialName: undefined,
};

export const environmentSummaryQueryToFilter = (query: EnvironmentSummaryQuery): EnvironmentSummaryFilter => {
    return {
        ...defaultEnvironmentSummaryFilter,
        partialName: query.partialName,
        machinePartialName: query.machinePartialName,
        environmentIds: arrayValueFromQueryString(query.environmentIds),
        healthStatuses: arrayValueFromQueryString(query.healthStatuses) as MachineModelHealthStatus[],
        commStyles: arrayValueFromQueryString(query.commStyles) as CommunicationStyle[],
        roles: arrayValueFromQueryString(query.roles),
        isDisabled: query.isDisabled === "true",
        tenantIds: arrayValueFromQueryString(query.tenantIds),
        tenantTags: arrayValueFromQueryString(query.tenantTags), // Expecting canonical tag names
        hideEmptyEnvironments: query.hideEmptyEnvironments === "true",
        shellNames: arrayValueFromQueryString(query.shellNames),
    };
};

export const environmentSummaryFilterToQuery = (filter: EnvironmentSummaryFilter): EnvironmentSummaryQuery => {
    return {
        partialName: filter.partialName,
        machinePartialName: filter.machinePartialName,
        environmentIds: filter.environmentIds,
        healthStatuses: filter.healthStatuses,
        commStyles: filter.commStyles,
        roles: filter.roles,
        isDisabled: filter.isDisabled ? "true" : undefined,
        tenantIds: filter.tenantIds,
        tenantTags: filter.tenantTags,
        hideEmptyEnvironments: filter.hideEmptyEnvironments ? "true" : undefined,
        shellNames: filter.shellNames,
    };
};

export const createEnvironmentMachinesArgs = (filter: EnvironmentSummaryFilter, isDisabled: boolean, applicableHealthStatusFilters: MachineModelHealthStatus[]): EnvironmentMachinesArgs => {
    const skip = 0; // Don't skip, just increase the take size.
    const take = repository.takeAll; // No paging, just take all, because we need to group our response data by health status.
    // Here we take the machinePartialName from the filter, as this is where we bind the worker name from the UI.
    // We map this to partialName on the returned args, as this is where the API expects the worker name for the query.
    const partialName = filter.machinePartialName;
    const roles = filter.roles ? filter.roles.join(",") : null;
    const healthStatuses = applicableHealthStatusFilters ? applicableHealthStatusFilters.join(",") : null;
    const commStyles = filter.commStyles.length > 0 ? filter.commStyles.join(",") : null;
    const tenantIds = filter.tenantIds.length > 0 ? filter.tenantIds.join(",") : null;
    const tenantTags = filter.tenantTags.length > 0 ? filter.tenantTags.join(",") : null;
    const shellNames = filter.shellNames ? filter.shellNames.join(",") : null;

    return { skip, take, partialName, roles, isDisabled, healthStatuses, commStyles, tenantIds, tenantTags, shellNames };
};

export const createEnvironmentSummaryArgs = (filter: EnvironmentSummaryFilter, tagIndex?: TagIndex): Partial<EnvironmentSummaryArgs> => {
    const args: Partial<EnvironmentSummaryArgs> = {};
    if (filter.partialName && filter.partialName !== "") {
        args.partialName = filter.partialName;
    }
    if (filter.machinePartialName && filter.machinePartialName !== "") {
        args.machinePartialName = filter.machinePartialName;
    }
    if (filter.roles && filter.roles.length) {
        args.roles = filter.roles.join(",");
    }
    if (filter.shellNames && filter.shellNames.length) {
        args.shellNames = filter.shellNames.join(",");
    }
    if (filter.isDisabled) {
        args.isDisabled = true;
    }
    if (filter.environmentIds && filter.environmentIds.length) {
        args.ids = filter.environmentIds.join(",");
    }
    if (filter.healthStatuses && filter.healthStatuses.length) {
        args.healthStatuses = filter.healthStatuses.join(",");
    }
    if (filter.commStyles && filter.commStyles.length) {
        args.commStyles = filter.commStyles.join(",");
    }
    if (filter.tenantIds && filter.tenantIds.length) {
        args.tenantIds = filter.tenantIds.join(",");
    }
    if (filter.tenantTags && filter.tenantTags.length && tagIndex) {
        args.tenantTags = filter.tenantTags.map((name) => tagIndex[name].Id).join(",");
    }
    if (filter.hideEmptyEnvironments) {
        args.hideEmptyEnvironments = true;
    }

    return args;
};
