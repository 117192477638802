/* eslint-disable no-restricted-imports */
import React from "react";
import Note from "primitiveComponents/form/Note/Note";
import { Collapse } from "@material-ui/core";
const styles = require("./style.less");

export interface MoreInfoProps {
    showLabel?: string;
    hideLabel?: string;
}

const MoreInfo: React.FC<MoreInfoProps> = ({ showLabel = "Show more information", hideLabel = "Hide more information", children }) => {
    const [expanded, setExpanded] = React.useState(false);

    return (
        <>
            <Note>
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setExpanded(!expanded);
                    }}
                >
                    {expanded ? hideLabel : showLabel}
                </a>
            </Note>
            <Collapse in={expanded} timeout={"auto"}>
                <div className={styles.moreInformation}>{children}</div>
            </Collapse>
        </>
    );
};

export default MoreInfo;
