/* eslint-disable @typescript-eslint/no-non-null-assertion */

import * as React from "react";
import { BaseComponent } from "../../BaseComponent/BaseComponent";
import { ReactElement } from "react";
import * as notificationLocalState from "./notificationLocalState";
import { notificationActions, NotificationItem } from "../notifications.redux";
import store from "../../../store";

export abstract class NotificationPoller extends BaseComponent<{}, never> {
    pollingPeriod: number = 1000 * 60 * 60 * 6; //6 hours
    abstract notificationId: string;
    abstract notificationCheck: () => Promise<void>;

    timeout = -1;

    async componentDidMount() {
        await this.notificationCheck();
        this.tick();
    }

    componentWillUnmount() {
        window.clearTimeout(this.timeout);
    }

    render(): ReactElement {
        return null!;
    }

    protected dismissNotification() {
        notificationLocalState.clearValue(this.notificationId);
        store.dispatch(notificationActions.dismissNotification(this.notificationId));
    }

    protected raiseNotification(notification: NotificationItem) {
        store.dispatch(notificationActions.raiseNotification(notification));
    }

    private tick() {
        this.timeout = window.setTimeout(async () => {
            if (this.unmounted) {
                return;
            }
            await this.notificationCheck();
            this.tick();
        }, this.pollingPeriod);
    }
}
