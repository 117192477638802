import React from "react";
import { ThemeStorage } from "./ThemeStorage";
import { ThemePaletteType } from "theme";
import { OctopusTheme, LightTheme, DarkTheme } from "./Themes";
import cssVars from "css-vars-ponyfill";

export class ThemeApplier extends React.PureComponent<{}> {
    private static lookup: Map<ThemePaletteType, OctopusTheme> = new Map([
        ["light", LightTheme],
        ["dark", DarkTheme],
    ]);

    render() {
        return false;
    }

    componentDidMount(): void {
        this.applyTheme(ThemeStorage.get());
        ThemeStorage.addEventListener(this.applyTheme);
    }

    componentWillUnmount(): void {
        ThemeStorage.removeEventListener(this.applyTheme);
    }

    applyTheme = (themeId: ThemePaletteType) => {
        if (!themeId) {
            return;
        }
        const theme = ThemeApplier.lookup.get(themeId);

        if (!theme) {
            return;
        }

        const keys = Object.entries(theme);

        const variables: Record<string, string> = {};

        for (const [key, value] of keys) {
            variables[key] = value;
        }

        cssVars({
            watch: true,
            variables,
        });
    };
}
