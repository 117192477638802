/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import FormPaperLayout from "components/FormPaperLayout";
import { FormPaperLayoutProps } from "components/FormPaperLayout/FormPaperLayout";

export interface TabbedFormPaperLayoutProps extends FormPaperLayoutProps {
    tabNavigation?: React.ComponentType<any>;
    hideTabNavigation?: boolean;
}

export const TabbedFormPaperLayout: React.FC<TabbedFormPaperLayoutProps> = (props) => {
    const { tabNavigation: TabNavigationComponent, hideTabNavigation = false, disableAnimations = true, children, ...rest } = props;
    return (
        <FormPaperLayout disableAnimations {...rest}>
            {TabNavigationComponent && !hideTabNavigation && <TabNavigationComponent key="tabs" />}
            {children}
        </FormPaperLayout>
    );
};

TabbedFormPaperLayout.defaultProps = {
    fullWidth: true,
};

export default TabbedFormPaperLayout;
