import { useDispatch } from "react-redux";
import * as React from "react";
import { expanderActions } from "../form/Sections/reducers/expanders";

export const useSetExpanderState = () => {
    const dispatch = useDispatch();
    return React.useCallback(
        (key: string, open: boolean) => {
            dispatch(expanderActions.onExpanderStateChanged({ containerKey: null, key, expanded: open }));
        },
        [dispatch]
    );
};
