import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonConfigurationDiagnosticsOverview, CommonConfigurationDiagnosticsTopics } from "../../CommonComponents/CommonConfigurationDiagnosticsViews";

export const ConfigurationDiagnosticLogsRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.diagnostics.logs.root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationDiagnosticsOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationDiagnosticsTopics />;
    },
};
