import { RetentionPeriod } from "client/resources";

const policySummary = (policy: RetentionPeriod): string => {
    if (policy.ShouldKeepForever) {
        return "Keep all";
    }

    if (policy.Unit === "Days") {
        return policy.QuantityToKeep === 1 ? "Keep " + policy.QuantityToKeep + " day" : "Keep " + policy.QuantityToKeep + " days";
    } else {
        return policy.QuantityToKeep === 1 ? "Keep " + policy.QuantityToKeep + " release" : "Keep " + policy.QuantityToKeep + " releases";
    }
};

export default policySummary;
