import { pageId } from "utils/pageId";

export default () => ({
    releases: pageId({ area: "Project", id: "Project.Releases", name: "Releases", routeLink: null }),
    releaseCreate: pageId({ area: "Project", id: "Project.ReleaseNew", name: "Add New Release", routeLink: null }),
    release: () => {
        return {
            root: pageId({ area: "Project", id: "Project.ReleaseVersion", name: "Release Version", routeLink: null }),
            edit: pageId({ area: "Project", id: "Project.ReleaseVersionEdit", name: "Edit Release Version", routeLink: null }),
            deployments: {
                specific: pageId({ area: "Project", id: "Project.Deployment", name: "Deployment", routeLink: null }),
                retry: pageId({ area: "Project", id: "Project.DeploymentRetry", name: "Retry Deployment", routeLink: null }),
                create: pageId({ area: "Project", id: "Project.DeploymentNew", name: "Add New Deployment", routeLink: null }),
            },
        };
    },
});
