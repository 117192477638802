/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { defaultBaseInfrastructureFilter, BaseInfrastructureFilter } from "../../MachinesLayout/MachineFilter";
import { WorkerPoolsSummaryArgs, WorkerPoolsMachinesArgs } from "client/repositories/workerpoolsRepository";
import { repository } from "clientInstance";
import { ListWorkerArgs } from "client/repositories/workerRepository";
import { WorkerPoolSummaryMachineQuery } from "./WorkerPoolSummaryMachineQuery";
import { arrayValueFromQueryString } from "utils/ParseHelper/ParseHelper";
import { MachineModelHealthStatus, CommunicationStyle } from "client/resources";

export interface WorkerPoolsSummaryFilter extends BaseInfrastructureFilter {
    workerPoolIds: string[];
    shellNames: string[];
    machinePartialName: string | undefined; // not used in workers sidebar filter
    roles: string[]; // not used in workers sidebar filter
    hideEmptyWorkerPools: boolean; // not used in workers sidebar filter
}

export const defaultWorkerPoolsSummaryFilter: WorkerPoolsSummaryFilter = {
    ...defaultBaseInfrastructureFilter,
    workerPoolIds: [],
    shellNames: [],
    machinePartialName: undefined, // not used in workers sidebar filter
    roles: [], // not used in workers sidebar filter
    hideEmptyWorkerPools: false, // not used in workers sidebar filter
};

export const createWorkerPoolListWorkerArgs = (filter: WorkerPoolsSummaryFilter, healthStatusFilter: string | null, isDisabledFilter: boolean): ListWorkerArgs => {
    // used in the WorkerMachinesSummarySection
    const workerPoolIds = filter.workerPoolIds ? filter.workerPoolIds.join(",") : undefined;
    const skip = 0; // Don't skip, just increase the take size.
    const take = repository.takeAll;
    // Here we take the machinePartialName from the filter, as this is where we bind the worker name from the UI.
    // We map this to partialName on the returned args, as this is where the API expects the worker name for the query.
    const partialName = filter.machinePartialName;
    const shellNames = filter.shellNames ? filter.shellNames.join(",") : undefined;
    const applicableHealthStatusFilters = filter.healthStatuses.length > 0 ? filter.healthStatuses : healthStatusFilter ? [healthStatusFilter] : undefined;
    const healthStatuses = applicableHealthStatusFilters ? applicableHealthStatusFilters.join(",") : undefined;
    const isDisabled = filter.isDisabled ? filter.isDisabled : isDisabledFilter;
    const commStyles = filter.commStyles.length > 0 ? filter.commStyles.join(",") : undefined;
    return { skip, take, partialName, shellNames, healthStatuses, isDisabled, commStyles, workerPoolIds };
};

export const createWorkerPoolsMachinesArgs = (filter: WorkerPoolsSummaryFilter, isDisabled: boolean, applicableHealthStatusFilters: MachineModelHealthStatus[]): WorkerPoolsMachinesArgs => {
    const skip = 0; // Don't skip, just increase the take size.
    const take = repository.takeAll; // No paging, just take all, because we need to group our response data by health status.
    // Here we take the machinePartialName from the filter, as this is where we bind the worker name from the UI.
    // We map this to partialName on the returned args, as this is where the API expects the worker name for the query.
    const partialName = filter.machinePartialName;
    const healthStatuses = applicableHealthStatusFilters ? applicableHealthStatusFilters.join(",") : null;
    const commStyles = filter.commStyles.length > 0 ? filter.commStyles.join(",") : null;
    const shellNames = filter.shellNames ? filter.shellNames.join(",") : null;
    return { skip, take, partialName, isDisabled, healthStatuses, commStyles, shellNames };
};

export const createWorkerPoolsSummaryArgs = (filter: WorkerPoolsSummaryFilter): Partial<WorkerPoolsSummaryArgs> => {
    // used in the WorkerMachinesLayout
    const args: Partial<WorkerPoolsSummaryArgs> = {};
    if (filter.isDisabled) {
        args.isDisabled = true;
    }

    if (filter.shellNames && filter.shellNames.length) {
        args.shellNames = filter.shellNames.join(",");
    }

    if (filter.workerPoolIds && filter.workerPoolIds.length) {
        args.ids = filter.workerPoolIds.join(",");
    }

    if (filter.healthStatuses && filter.healthStatuses.length) {
        args.healthStatuses = filter.healthStatuses.join(",");
    }

    if (filter.commStyles && filter.commStyles.length) {
        args.commStyles = filter.commStyles.join(",");
    }

    if (filter.partialName && filter.partialName !== "") {
        args.partialName = filter.partialName;
    }

    if (filter.machinePartialName && filter.machinePartialName !== "") {
        args.machinePartialName = filter.machinePartialName;
    }

    if (filter.hideEmptyWorkerPools) {
        args.hideEmptyWorkerPools = true;
    }

    return args;
};

export const workerPoolsQueryToFilter = (query: WorkerPoolSummaryMachineQuery): WorkerPoolsSummaryFilter => ({
    ...defaultWorkerPoolsSummaryFilter,
    partialName: query.partialName,
    machinePartialName: query.machinePartialName,
    workerPoolIds: arrayValueFromQueryString(query.workerPoolIds),
    healthStatuses: arrayValueFromQueryString(query.healthStatuses) as MachineModelHealthStatus[],
    commStyles: arrayValueFromQueryString(query.commStyles) as CommunicationStyle[],
    roles: arrayValueFromQueryString(query.roles),
    isDisabled: query.isDisabled === "true",
    hideEmptyWorkerPools: query.hideEmptyWorkerPools === "true",
    shellNames: arrayValueFromQueryString(query.shellNames),
});

export const workerPoolsFilterToQuery = (filter: WorkerPoolsSummaryFilter): WorkerPoolSummaryMachineQuery => {
    return {
        partialName: filter.partialName,
        machinePartialName: filter.machinePartialName,
        workerPoolIds: filter.workerPoolIds,
        healthStatuses: filter.healthStatuses,
        commStyles: filter.commStyles,
        roles: filter.roles,
        isDisabled: filter.isDisabled ? "true" : "",
        hideEmptyWorkerPools: filter.hideEmptyWorkerPools ? "true" : "",
        shellNames: filter.shellNames,
    };
};
