import React, { useEffect, useState } from "react";
import { useRemoveAllConsoleErrors, useConsoleErrors, ConsoleEntries } from "components/ConsoleErrors/ConsoleErrorCapturer";
import { DevOnly } from "components/DevTools/DevOnly";
import { Snackbar } from "primitiveComponents/feedback/Snackbar";
import { Alert } from "primitiveComponents/feedback/Alert/Alert";

export function ConsoleErrorDisplayer() {
    return (
        <DevOnly>
            <DevOnlyConsoleErrorDisplayer />
        </DevOnly>
    );
}

function DevOnlyConsoleErrorDisplayer() {
    const consoleErrors = useConsoleErrors();
    const removeAllConsoleErrors = useRemoveAllConsoleErrors();
    const [consoleErrorMessage, setConsoleErrorMessage] = useState<string>("");

    useEffect(() => {
        // If the console errors have just become 0, then the snackbar should hide.
        // However, we don't want to immediately change the message text until the snackbar has animated away, so keep the old error message around a bit longer.
        if (consoleErrors.length !== 0) {
            setConsoleErrorMessage(buildConsoleErrorMessage(consoleErrors));
        }
    }, [consoleErrors]);

    return (
        <>
            <Snackbar
                open={consoleErrors.length > 0}
                content={
                    <Alert severity={"error"} onClose={removeAllConsoleErrors}>
                        {consoleErrorMessage}
                    </Alert>
                }
            />
        </>
    );
}

function buildConsoleErrorMessage(errors: ConsoleEntries) {
    if (errors.length > 1) {
        return `There are ${errors.length} errors in the console`;
    } else if (errors.length === 1) {
        return `There is 1 error in the console`;
    } else {
        return "";
    }
}
