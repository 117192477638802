import * as React from "react";
import { session, repository } from "clientInstance";
import { Permission, ProjectResource } from "client/resources";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout";
import { required, Text } from "components/form";

interface CloneProjectDialogProps {
    clone: ProjectResource;
    projectCreated: (project: ProjectResource) => void;
}

interface CloneProjectDialogState extends DataBaseComponentState {
    name: string;
}

class CloneProject extends DataBaseComponent<CloneProjectDialogProps, CloneProjectDialogState> {
    constructor(props: CloneProjectDialogProps) {
        super(props);
        this.state = {
            name: "",
        };
    }

    async save() {
        await this.doBusyTask(async () => {
            const result = await repository.Projects.create(
                {
                    Name: this.state.name,
                    Description: this.props.clone.Description,
                    ProjectGroupId: this.props.clone.ProjectGroupId,
                    LifecycleId: this.props.clone.LifecycleId,
                },
                { clone: this.props.clone.Id }
            );

            // refresh permissions to include the new project
            if (session.currentUser) {
                const permissionSet = await repository.UserPermissions.getAllPermissions(session.currentUser, true);
                session.refreshPermissions(permissionSet);
            }

            this.props.projectCreated(result);
            return true;
        });

        return false;
    }

    render() {
        return (
            <SaveDialogLayout title="Clone Project" busy={this.state.busy} errors={this.errors} saveButtonDisabled={this.state.name.length === 0} onSaveClick={() => this.save()}>
                <PermissionCheck
                    permission={Permission.LifecycleView}
                    alternate={
                        <Callout type={CalloutType.Information} title={"Permission required"}>
                            The {Permission.LifecycleView} permission is required to clone a project
                        </Callout>
                    }
                >
                    {this.props.clone.IsVersionControlled && (
                        <>
                            <Callout title="Clone project is version controlled enabled" type={CalloutType.Warning}>
                                When cloning a version controlled enabled project, we will also clone the version control setting (the repository url and authentication settings). Please review these after cloning.
                            </Callout>
                            <div style={{ marginTop: "1rem" }} />
                        </>
                    )}
                    <Text label="New project name" value={this.state.name} onChange={(name) => this.setState({ name })} validate={required("Please enter a project name")} autoFocus={true} />
                </PermissionCheck>
            </SaveDialogLayout>
        );
    }
}

export default CloneProject;
