import { TenantResource } from "client/resources";
import { combineReducers, Reducer } from "redux";

import { TenantMissingVariableResource } from "../../client/resources/tenantMissingVariablesResource";

enum ActionKeys {
    TENANT_FETCHED = "TENANT_FETCHED",
    TENANT_SAVED = "TENANT_SAVED",
    TENANT_VARIABLES_FETCHED = "TENANT_VARIABLES_FETCHED",
    TENANT_ENABLED_FETCHED = "TENANT_ENABLED_FETCHED",
    OTHER_ACTION = "__any_other_action_type__",
}

interface ITenantAction {
    type: ActionKeys;
}

interface ITenantFetchedAction {
    type: ActionKeys.TENANT_FETCHED;
    tenant: TenantResource;
}

interface ITenantSavedAction {
    type: ActionKeys.TENANT_SAVED;
    tenant: TenantResource;
}

interface ITenantVariablesFetchedAction {
    type: ActionKeys.TENANT_VARIABLES_FETCHED;
    hasMissingVariables: boolean;
}

interface IOtherAction {
    type: ActionKeys.OTHER_ACTION;
}

type ActionTypes = ITenantFetchedAction | ITenantSavedAction | ITenantVariablesFetchedAction | IOtherAction;

export interface TenantsAreaState {
    readonly currentTenant: TenantState | null;
}

export interface TenantState {
    readonly name: string;
    readonly description: string | null;
    readonly logoUrl: string;
    readonly id: string;
    readonly hasMissingVariables: boolean;
}

const updateTenant = (tenant: TenantResource, state: TenantState | null): TenantState => {
    return {
        ...(state || {}),
        name: tenant.Name,
        description: tenant.Description,
        logoUrl: tenant.Links.Logo,
        id: tenant.Id,
        hasMissingVariables: false,
    };
};

const currentTenantReducer: Reducer<TenantsAreaState["currentTenant"]> = (state = null, action: ActionTypes) => {
    switch (action.type) {
        case ActionKeys.TENANT_SAVED:
            return updateTenant(action.tenant, state);
        case ActionKeys.TENANT_FETCHED:
            return updateTenant(action.tenant, state);
        case ActionKeys.TENANT_VARIABLES_FETCHED:
            if (!state) {
                return state;
            }

            return {
                ...state,
                hasMissingVariables: action.hasMissingVariables,
            };
        default:
            return state;
    }
};

export const tenantsActions = {
    tenantFetched: (tenant: TenantResource): ITenantFetchedAction => {
        return { type: ActionKeys.TENANT_FETCHED, tenant };
    },
    tenantSaved: (tenant: TenantResource): ITenantSavedAction => {
        return { type: ActionKeys.TENANT_SAVED, tenant };
    },
    tenantMissingVariablesFetched: (tenantMissingVariables?: TenantMissingVariableResource): ITenantVariablesFetchedAction => {
        return {
            type: ActionKeys.TENANT_VARIABLES_FETCHED,
            hasMissingVariables: !!tenantMissingVariables,
        };
    },
};

export const tenantsArea = combineReducers<TenantsAreaState>({
    currentTenant: currentTenantReducer,
});
