import FeedResource from "client/resources/feedResource";

export interface ActionWithFeeds {
    feeds: FeedResource[];
    refreshFeeds: () => Promise<unknown>;
}

/**
 * Some package ID's are invalid names, so we will try and sanitize them into valid a name
 * @param name The supplied package name
 * @return The sanitized package name
 */
export const SanitizeName = (name: string) => {
    if (!name) {
        return name;
    }

    // Container image ID's may contain `/`
    // Maven package ID's will contain `:`
    // We will take everything to the right of the last invalid-char as the name.
    // e.g. hashicorp/http-echo -> http-echo
    // e.g. org.acme:acme-web -> acme-web
    // Of course there may be other invalid characters, but these are two common ones, so we optimize for them.
    // Any others will fail server-side validation.

    const lastInvalidCharIndex = name.search("[/,:]");

    // If no invalid chars found
    if (lastInvalidCharIndex < 0) {
        return name;
    }

    // If the last character is an invalid char?  We'll just clear the name to force them to set it manually.
    if (lastInvalidCharIndex === name.length - 1) {
        return "";
    }

    // Return everything after the last invalid char, and replace any remaining invalid chars with underscores
    return name.substring(lastInvalidCharIndex + 1).replace(/[^A-Za-z0-9.,\-_#]/g, "_");
};
