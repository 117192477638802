import * as React from "react";
import FormFieldProps from "components/form/FormFieldProps";
const styles = require("./style.less");

interface ThumbprintTextProps {
    thumbprint: string;
}

class ThumbprintText extends React.PureComponent<ThumbprintTextProps> {
    render() {
        return <span className={styles.thumbprint}>{this.props.thumbprint}</span>;
    }
}

export default ThumbprintText;
