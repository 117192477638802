/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import ExpandableFormSection, { CardFill } from "components/form/Sections/ExpandableFormSection";
import FormSectionHeading from "components/form/Sections/FormSectionHeading";
import { default as TaskResource } from "client/resources/taskResource";
import { Section } from "components/Section/Section";
import AdHocScript, { AdHocScriptModel, AdHocScriptState, GlobalConnectedProps, AdHocScriptProps } from "components/AdHocScript/AdHocScript";
import CodeEditor from "components/CodeEditor/CodeEditor";
import CommonSummaryHelper from "utils/CommonSummaryHelper/CommonSummaryHelper";
import ScriptingLanguageSelector from "components/ScriptingLanguageSelector/ScriptingLanguageSelector";
import { ScriptingLanguage } from "components/scriptingLanguage";
import TaskLayout from "areas/tasks/TaskLayout/TaskLayout";
import { repository } from "../../../../clientInstance";
import { AdHocScriptTargetArguments } from "../../../../client/repositories/taskRepository";
import { connect } from "react-redux";

interface ScriptConsoleModel extends AdHocScriptModel {
    ScriptBody: string;
    Syntax: ScriptingLanguage;
}

type ScriptConsoleProps = AdHocScriptProps;

class ScriptConsole extends AdHocScript<ScriptConsoleProps, AdHocScriptState<ScriptConsoleModel>, ScriptConsoleModel> {
    getCustomInputs() {
        return (
            <div>
                <FormSectionHeading title="Script" />
                <ExpandableFormSection
                    errorKey="Body"
                    title="Body"
                    isExpandedByDefault={true}
                    fillCardWidth={CardFill.FillRight}
                    summary={CommonSummaryHelper.scriptSummary(this.state.model!.ScriptBody, this.state.model!.Syntax)}
                    help={"Select the script language and enter the body of the script that will be executed."}
                >
                    <ScriptingLanguageSelector value={this.state.model!.Syntax} onChange={(Syntax) => this.setModelState({ Syntax })} />
                    <CodeEditor language={this.state.model!.Syntax} value={this.state.model!.ScriptBody} allowFullScreen={true} onChange={(ScriptBody) => this.setModelState({ ScriptBody })} />
                </ExpandableFormSection>
            </div>
        );
    }

    getHeading() {
        return (
            <Section>
                Use the <em>Script Console</em> to run one-off scripts remotely on deployment targets. Scripts run this way are not associated with projects or deployments.
            </Section>
        );
    }

    async getInitialPartialModel(taskToRetry?: TaskResource<any>) {
        const model = {
            ScriptBody: taskToRetry ? taskToRetry.Arguments.ScriptBody : "",
            Syntax: taskToRetry ? taskToRetry.Arguments.Syntax : ScriptingLanguage.PowerShell,
        };
        return model;
    }

    getTitle() {
        return "Script Console";
    }

    wrapWithLayout(content: React.ReactNode) {
        return <TaskLayout>{content}</TaskLayout>;
    }

    createAdHocScriptTask(targetTaskArguments: AdHocScriptTargetArguments): Promise<TaskResource<any>> {
        return repository.Tasks.createScriptConsoleTask(targetTaskArguments, this.state.model!.Syntax, this.state.model!.ScriptBody);
    }
}

const mapGlobalStateToProps = (state: GlobalState, props: ScriptConsoleProps): GlobalConnectedProps => {
    return {
        isBuiltInWorkerEnabled: state.configurationArea.features.isBuiltInWorkerEnabled,
    };
};

export default connect(mapGlobalStateToProps)(ScriptConsole);
