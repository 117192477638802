import * as React from "react";
import SvgImage, { SvgImageProps } from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
const styles = require("../../../primitiveComponents/dataDisplay/SvgImage/styles.less");

const LibraryVariable = (props: Partial<SvgImageProps>) => (
    <SvgImage viewBox="0 0 178 148" title="Library Variable Template" {...props}>
        <g id="library-variable" fillRule="evenodd" strokeWidth="1">
            <g className={styles.libraryVariable} fillRule="nonzero">
                <path className={styles.imgDark} d="M178 133.3c-.011 8.102-6.568 14.672-14.67 14.7H14.67C6.568 147.972.011 141.402 0 133.3V14.7A14.67 14.67 0 0 1 14.67 0h148.66A14.67 14.67 0 0 1 178 14.7v118.6z" />
                <path
                    className={styles.imgLight}
                    d="M92.34 55.37A65.561 65.561 0 0 1 136.84 38v54.23A64.28 64.28 0 0 0 112.53 97a66.16 66.16 0 0 0-20.19 13 66.33 66.33 0 0 0-20.16-13 63.84 63.84 0 0 0-24.34-4.74V38a65.441 65.441 0 0 1 44.5 17.32v.05z"
                />
            </g>
        </g>
    </SvgImage>
);
export { LibraryVariable };
