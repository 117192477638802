import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonLibraryLifecycleOverview, CommonLibraryLifecycleTopics } from "../../CommonComponents/CommonLibraryLifecycleViews";

export const LibraryLifecyclesNewRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.library.lifecyclesCreate,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryLifecycleOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryLifecycleTopics />;
    },
};
