import { useProjectContext } from "areas/projects/context";
import { NameOrIdKey } from "components/KeyAccessProvider/types";
import { isVersionControlledProcess } from "../Common/CommonProcessHelpers";
import { useOptionalRunbookContext } from "../../Runbooks/RunbookContext";
import { ProcessType } from "client/resources";
import { useSelector } from "react-redux";

const isConfigurationAsCodeForRunbooksEnabledSelector = (state: GlobalState) => state.configurationArea.features.isConfigurationAsCodeForRunbooksEnabled;

export const useKeyedItemAccessForConfigurationAsCode = (): NameOrIdKey => {
    const projectContext = useProjectContext();
    const runbookContext = useOptionalRunbookContext();
    const isConfigurationAsCodeForRunbooksEnabled = useSelector(isConfigurationAsCodeForRunbooksEnabledSelector);
    const isVersionControlled = isVersionControlledProcess(projectContext.state.model.IsVersionControlled, runbookContext ? ProcessType.Runbook : ProcessType.Deployment, !!isConfigurationAsCodeForRunbooksEnabled);

    return isVersionControlled ? "Name" : "Id";
};

export default useKeyedItemAccessForConfigurationAsCode;
