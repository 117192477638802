import * as React from "react";
import { repository, session } from "clientInstance";
import { FeaturesConfigurationResource, UserResource } from "client/resources";
import Logger from "client/logger";
import { Text } from "components/form";
import { ActionButton, ActionButtonType, NavigationButton } from "components/Button";
import ErrorPanel from "components/ErrorPanel/ErrorPanel";
import BaseComponent from "components/BaseComponent";
import ExternalLink from "components/Navigation/ExternalLink";
const styles = require("./style.less");
import InternalRedirect from "../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../routeLinks";
import { RouteComponentProps } from "react-router";
import Note from "primitiveComponents/form/Note/Note";
import CenteredLayout from "components/CenteredLayout/CenteredLayout";
import AuthenticationError from "client/resources/authenticationError";

interface RegisterState {
    redirectToReferrer: boolean;
    inviteCodeInUri?: boolean;
    inviteCode: string;
    userName: string;
    displayName: string;
    authenticationError?: AuthenticationError;
    emailAddress: string;
    password: string;
    confirmPassword: string;
    busyIndicator?: Promise<void>;
}

interface RegisterProps extends RouteComponentProps<{ inviteCode: string }> {
    onSessionStarted?: (user: UserResource, features: FeaturesConfigurationResource) => Promise<void>;
}

export default class Register extends BaseComponent<RegisterProps, RegisterState> {
    constructor(props: RegisterProps) {
        super(props);
        this.state = {
            redirectToReferrer: false,
            userName: "",
            password: "",
            confirmPassword: "",
            inviteCode: "",
            displayName: "",
            emailAddress: "",
        };
    }

    async componentDidMount() {
        const inviteCode = this.props.match.params.inviteCode;

        this.setState({
            inviteCodeInUri: !!inviteCode,
            inviteCode,
        });
    }

    render() {
        if (this.state.redirectToReferrer) {
            return <InternalRedirect to={routeLinks.root} />;
        }

        return (
            <CenteredLayout>
                <div className={styles.content}>
                    <div className={styles.logo}>
                        <div>
                            <em className="fontoctopus-octopus" />
                        </div>
                        {!this.state.authenticationError && (
                            <div>
                                <h4>Create an account to get started.</h4>
                            </div>
                        )}
                    </div>
                    {this.state.authenticationError && this.showError(this.state.authenticationError)}
                    <form onSubmit={this.register} className={styles.form}>
                        {!this.state.inviteCodeInUri && <Text value={this.state.inviteCode} label="Invitation code" onChange={(inviteCode) => this.setState({ inviteCode })} />}
                        <Text value={this.state.userName} label="Username" onChange={(userName) => this.setState({ userName })} autoFocus={true} />
                        <Text value={this.state.displayName} label="Display name" onChange={(displayName) => this.setState({ displayName })} />
                        <Text value={this.state.emailAddress} label="Email address" onChange={(emailAddress) => this.setState({ emailAddress })} />
                        <Note>
                            This will be used to show your <ExternalLink href="Gravatar">Gravatar</ExternalLink>.
                        </Note>
                        <Text label="Password" value={this.state.password} type="password" onChange={(password) => this.setState({ password })} autoComplete="new-password" />
                        <Text value={this.state.confirmPassword} type={"password"} onChange={(confirmPassword) => this.setState({ confirmPassword })} label="Confirm password" autoComplete="new-password" />
                        <div className={styles.footerActions}>
                            <ActionButton type={ActionButtonType.Primary} label="Create Account" busyLabel="Creating..." onClick={this.register} />
                            <div>
                                or <NavigationButton href={routeLinks.currentUser.signIn} label="sign in" />
                            </div>
                        </div>
                    </form>
                </div>
            </CenteredLayout>
        );
    }

    private register = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const busyIndicator = this.registerUser(this.state.userName, this.state.password);
        this.setState({ busyIndicator });
        await busyIndicator;
    };

    private registerUser = async (userName: string, password: string) => {
        try {
            if (this.state.password !== this.state.confirmPassword) {
                this.setError({
                    ErrorMessage: "There was a problem with your request.",
                    Errors: ["The passwords you entered do not match."],
                });
                return;
            }

            if (userName === null || userName.trim().length === 0) {
                this.setError({
                    ErrorMessage: "There was a problem with your request.",
                    Errors: ["Please supply a username."],
                });
                return;
            }

            const result = await repository.Users.register({
                Username: userName,
                DisplayName: this.state.displayName,
                EmailAddress: this.state.emailAddress,
                Password: password,
                InvitationCode: this.state.inviteCode,
                IsActive: true,
            });

            const user = await repository.Users.signIn({ Username: userName, Password: password });

            await this.authenticationSucceeded(user);
        } catch (error) {
            this.setError(error);
        }
    };

    private async authenticationSucceeded(user: UserResource) {
        await this.startSession(user);
        this.setState({ redirectToReferrer: true });
    }

    private async startSession(user: UserResource) {
        try {
            const features = await repository.FeaturesConfiguration.get();
            session.start(user, features);
            if (this.props.onSessionStarted) {
                await this.props.onSessionStarted(user, features);
            }
        } catch (error) {
            Logger.log(error);

            const message = "The sign in succeeded but we failed to get the resultant permissions for this user account. ";

            const reason = error.StatusCode === 401 ? "This can happen if the Octopus authentication cookie is blocked." : "There was a problem communicating with the Octopus Server: " + error.ErrorMessage;

            this.setError({ ErrorMessage: message + reason });
        }
    }

    private setError(error: { ErrorMessage?: string; Errors?: string[]; DetailLinks?: string[]; message?: string }) {
        if (!error) {
            return;
        }

        Logger.error(error);

        const authenticationError: AuthenticationError = {
            ErrorMessage: error.ErrorMessage || error.message || error.toString(),
            Errors: error.Errors || [],
            DetailLinks: error.DetailLinks || [],
        };

        this.setState({ authenticationError });
    }

    private showError(authenticationError: AuthenticationError) {
        return (
            <div className={styles.authenticationError}>
                <ErrorPanel message={authenticationError.ErrorMessage} errors={authenticationError.Errors} />
            </div>
        );
    }
}
