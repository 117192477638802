import BasicRepository from "./basicRepository";
import Client from "../client";
import { RunbookProcessResource, RunbookSnapshotTemplateResource } from "client/resources";

class RunbookProcessRepository extends BasicRepository<RunbookProcessResource, RunbookProcessResource> {
    constructor(client: Client) {
        super("RunbookProcesses", client);
    }
    getRunbookSnapshotTemplate(runbookProcess: RunbookProcessResource, runbookSnapshotId: string) {
        return this.client.get<RunbookSnapshotTemplateResource>(runbookProcess.Links["RunbookSnapshotTemplate"], { runbookSnapshotId });
    }
}
export default RunbookProcessRepository;
