/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { repository } from "clientInstance";
import { required, Text } from "components/form";
import MarkdownEditor from "../../../../components/form/MarkdownEditor/MarkdownEditor";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { Action, ActionEvent, AnalyticActionDispatcher, AnalyticTrackedActionDispatcher, useAnalyticActionDispatch, useAnalyticTrackedActionDispatch } from "analytics/Analytics";

interface AddProjectGroupProps {
    projectGroupCreated(id: string): void;
}

interface AddProjectGroupPropsInternal extends AddProjectGroupProps {
    dispatchAction: AnalyticActionDispatcher;
    trackAction: AnalyticTrackedActionDispatcher;
}

interface AddProjectGroupState extends DataBaseComponentState {
    name?: string;
    description?: string;
}

class AddProjectGroupInternal extends DataBaseComponent<AddProjectGroupPropsInternal, AddProjectGroupState> {
    constructor(props: AddProjectGroupPropsInternal) {
        super(props);
        this.state = {};
    }

    async save() {
        await this.doBusyTask(async () => {
            const ev: ActionEvent = {
                action: Action.Save,
                resource: "Project Group",
            };

            return await this.props.trackAction("Save Project Group", ev, async () => {
                const result = await repository.ProjectGroups.create(
                    {
                        Id: null!,
                        Name: this.state.name!,
                        Description: this.state.description,
                        EnvironmentIds: [],
                        Links: null!,
                        RetentionPolicyId: null,
                    },
                    {}
                );

                this.props.projectGroupCreated(result.Id);
                return true;
            });
        });

        return false;
    }

    handleDescriptionChanged = async (description: string) => {
        // in dobusy so the dialog resizes if the markdowneditor grows
        await this.doBusyTask(async () => {
            this.setState({ description });
        });
    };

    render() {
        return (
            <SaveDialogLayout
                title="Add New Project Group"
                busy={this.state.busy}
                errors={this.errors}
                onSaveClick={() => this.save()}
                onCancelClick={() => {
                    const ev: ActionEvent = {
                        action: Action.Cancel,
                        resource: "Project Group",
                    };

                    this.props.dispatchAction("Cancel adding Project Group", ev);
                    return true;
                }}
            >
                <Text label="New project group name" value={this.state.name!} onChange={(name) => this.setState({ name })} validate={required("Please enter a project group name")} autoFocus={true} />
                <MarkdownEditor label="Project group description" value={this.state.description} onChange={this.handleDescriptionChanged} />
            </SaveDialogLayout>
        );
    }
}

export function AddProjectGroup(props: AddProjectGroupProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const trackAction = useAnalyticTrackedActionDispatch();

    return <AddProjectGroupInternal {...props} dispatchAction={dispatchAction} trackAction={trackAction} />;
}
