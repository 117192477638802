import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonLibraryVariableSetOverview, CommonLibraryVariableSetTopics } from "../../CommonComponents/CommonLibraryVariableSetViews";

export const LibraryVariableSetsNewRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.library.variableSets.create,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryVariableSetOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryVariableSetTopics />;
    },
};
