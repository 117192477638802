import * as React from "react";
import SvgImage, { SvgImageProps } from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
const styles = require("../../../primitiveComponents/dataDisplay/SvgImage/styles.less");

const AzureCloudRegion = (props: Partial<SvgImageProps>) => (
    <SvgImage viewBox="0 0 58 70" title="Azure Cloud Region" {...props}>
        <g fillRule="nonzero" strokeWidth="1">
            <path
                className={styles.imgDark}
                d="M27.333 45.748V57.5c-1.169.431-2.155 1.04-2.958 1.826-.803.786-1.345 1.673-1.625 2.66H10.312c-.791.341-1.187.846-1.187 1.514s.396 1.165 1.188 1.491H22.75c1.162 3.006 3.141 4.509 5.938 4.509 2.796 0 4.736-1.503 5.819-4.509H46.75c1-.264 1.5-.761 1.5-1.491 0-.73-.5-1.234-1.5-1.514H34.507c-.272-1.098-.735-1.985-1.389-2.66-.653-.675-1.598-1.243-2.833-1.702V45.748h-2.952z"
            />
            <g>
                <rect className={styles.imgCyan} width="57" height="48" x=".5" y=".5" rx="3" />
                <path
                    className={styles.imgWhite}
                    d="M41 19.33a7.85 7.85 0 0 1 3.79 1.27 8.25 8.25 0 0 1 2.73 2.9 7.873 7.873 0 0 1 1 3.9 8.003 8.003 0 0 1-1.09 4.1 8.18 8.18 0 0 1-3 3 7.93 7.93 0 0 1-4.09 1.09H19.25a9.52 9.52 0 0 1-4.89-1.3 9.75 9.75 0 0 1-3.55-3.55 9.592 9.592 0 0 1-1.31-4.9 9.443 9.443 0 0 1 1.15-4.58 9.8 9.8 0 0 1 7.55-5.09 12.33 12.33 0 0 1 4.4-4.8 12.15 12.15 0 0 1 16.07 3A12.25 12.25 0 0 1 41 19.33z"
                />
            </g>
        </g>
    </SvgImage>
);
export { AzureCloudRegion };
