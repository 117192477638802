import * as React from "react";
import SvgImage, { SvgImageProps } from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
const styles = require("../../../primitiveComponents/dataDisplay/SvgImage/styles.less");

const ProjectVariable = (props: Partial<SvgImageProps>) => (
    <SvgImage viewBox="0 0 178 148" title="Project Variable Template" {...props}>
        <g fillRule="nonzero" strokeWidth="1">
            <path className={styles.imgDark} d="M178 133.3c-.011 8.102-6.568 14.672-14.67 14.7H14.67C6.568 147.972.011 141.402 0 133.3V14.7A14.67 14.67 0 0 1 14.67 0h148.66A14.67 14.67 0 0 1 178 14.7v118.6z" />
            <rect className={styles.imgLight} width="35.04" height="35.04" x="24.81" y="48.45" strokeWidth="3" />
            <path
                className={styles.imgLight}
                d="M148.49 99.55H75.93a3.84 3.84 0 1 1 0-7.68h72.56a3.84 3.84 0 0 1 0 7.68zM72.09 74a3.84 3.84 0 0 1 3.84-3.84h56.14a3.84 3.84 0 0 1 0 7.68H75.93A3.84 3.84 0 0 1 72.09 74zm77.26-17.79H75.93a3.84 3.84 0 0 1 0-7.68h73.42a3.84 3.84 0 0 1 0 7.68z"
            />
        </g>
    </SvgImage>
);
export { ProjectVariable };
