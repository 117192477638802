import RetentionDefaultConfigurationResource from "client/resources/retentionDefaultConfigurationResource";
import Client from "../client";
import ConfigurationRepository from "./configurationRepository";

class RetentionDefaultConfigurationRepository extends ConfigurationRepository<RetentionDefaultConfigurationResource> {
    constructor(client: Client) {
        super("RetentionDefaultConfiguration", client);
    }
}

export default RetentionDefaultConfigurationRepository;
