import { PackagingRegistration } from "../../Registry/packagingRegistry";
import AspNetCoreRegistration from "../AspNetCore";

const AspNetCoreOtherRegistration: PackagingRegistration = {
    ...AspNetCoreRegistration,
    displayOrder: 300,
    name: ".NET (Other)",
    id: "AspNetOther",
};

export default AspNetCoreOtherRegistration;
