import * as React from "react";
import ToolTip from "primitiveComponents/dataDisplay/ToolTip/index";
import Chip from "../Chips/Chip";
import { useRequiredContext } from "hooks";
import { OctopusThemeContext } from "components/Theme";
import DeletableChip from "../Chips/DeletableChip";
const styles = require("./styles.less");

interface CommonTagProps {
    description: string;
    tagName: string;
    tagColor: string;
    borderColor?: string;
    small?: boolean;
    showTooltip?: boolean;
    tabIndex?: number;
    onClick?: () => void;
}

interface DeletableTagProps extends CommonTagProps {
    onRequestDelete: () => void;
    deleteButtonAccessibleName: string;
}

type TagProps = CommonTagProps | DeletableTagProps;

function isDeletableTag(tagProps: TagProps): tagProps is DeletableTagProps {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (tagProps as DeletableTagProps).onRequestDelete !== undefined;
}

const Tag: React.FC<TagProps> = (props) => {
    const themeContext = useRequiredContext(OctopusThemeContext, "OctopusThemeContext");

    const { tagName, tagColor, tabIndex, description, onClick, borderColor, small = false } = props;
    const tagDescription = description ? tagName + ": " + description : "Tag: " + tagName;

    if (!small) {
        if (isDeletableTag(props)) {
            return (
                <DeletableChip
                    onRequestDelete={props.onRequestDelete}
                    deleteButtonAccessibleName={props.deleteButtonAccessibleName}
                    backgroundColor={tagColor}
                    borderColor={borderColor}
                    description={tagDescription}
                    labelColor={themeContext.whiteConstant}
                >
                    {tagName}
                </DeletableChip>
            );
        }

        return (
            <Chip tabIndex={tabIndex} onClick={onClick} backgroundColor={tagColor} borderColor={borderColor} description={tagDescription} labelColor={themeContext.whiteConstant}>
                {tagName}
            </Chip>
        );
    }
    return (
        <ToolTip content={tagName}>
            <em className={`${"fa fa-square tag-swatch"}`} style={{ color: tagColor, marginLeft: "0.0625rem", marginRight: "0.0625rem" }} />
        </ToolTip>
    );
};

export default Tag;
