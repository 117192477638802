/* eslint-disable @typescript-eslint/no-explicit-any */

import { TrackJS } from "trackjs";

/*
 * Copied from https://docs.trackjs.com/browser-agent/tips-and-tricks/monitor-resource-load-error/
 * Used to send errors to TrackJS where resource failed to load
 */
declare global {
    interface Window {
        WebKitMutationObserver: any;
    }
}

export default class ResourceLoadError {
    public static monitorResourceLoad() {
        function listenForLoadError(node: any) {
            if (["SCRIPT", "LINK", "IMG"].indexOf(node.tagName) >= 0) {
                const origOnError = node.onerror;

                node.onerror = (evt: any) => {
                    if (!evt || !evt.srcElement) {
                        return;
                    }
                    evt.path = evt.path || [];
                    let path = "";

                    for (let elIdx = 0; elIdx < evt.path.length; elIdx++) {
                        const currentEl = evt.path[elIdx];
                        if (currentEl === window) {
                            path += "Window";
                            continue;
                        }
                        path += currentEl.nodeName;
                        path += currentEl.id ? "#" + currentEl.id : "";
                        path += currentEl.className ? "." + currentEl.className.split(" ").join(".") : "";
                        if (elIdx < evt.path.length) {
                            path += " > ";
                        }
                    }

                    if (TrackJS) {
                        TrackJS.console.info({
                            asset: evt.srcElement.src,
                            integrity: evt.srcElement.integrity,
                            element: evt.srcElement.outerHTML,
                            path,
                        });

                        TrackJS.console.error("Failed to load " + evt.srcElement.tagName + ": " + (evt.srcElement.src || evt.srcElement.href));
                    }

                    if (origOnError) {
                        origOnError.call(node, evt);
                    }
                };
            }
        }

        const Observer = window.MutationObserver || window.WebKitMutationObserver;

        if (!Observer) {
            return;
        }
        new Observer((mutations: any) => {
            [].forEach.call(mutations, (mutation: any) => {
                [].forEach.call(mutation.addedNodes, listenForLoadError);
            });
        }).observe(document, { childList: true, subtree: true });
    }
}
