import React = require("react");
import ExternalLink from "components/Navigation/ExternalLink";
const imageForDeploymentTarget = require("../../Images/Trigger-Deployment-Target.svg");
const imageForScheduledDeployment = require("../../Images/Trigger-Scheduled-Deployment.svg");

import { OverviewContainer, OverviewHighlightText, TermsText } from "./CommonViews";
import { TopicsContainer, LearnMoreResources } from "./CommonViews";
import ImageWithPlaceholder from "components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import { Note } from "components/form";
export const CommonProjectTriggerOverview: React.StatelessComponent<{}> = (props) => {
    return (
        <OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="ProjectTriggers">Triggers</ExternalLink> allow you to automate your deployments by responding to events such as changes in your deployment targets or time-based schedules.
                </p>
                <p>Choose from a subset of events that can occur in Octopus Deploy, apply a filter to those events, and decide on an action you want performed once the trigger fires.</p>
            </OverviewHighlightText>
            <TermsText>
                <Note heading={"Scheduled deployment triggers"}>allow you to define a recurring time-based schedule to automate deploying releases for your project.</Note>
                <ImageWithPlaceholder src={imageForScheduledDeployment} alt={"Deployment process"} />
            </TermsText>
            <TermsText>
                <Note heading={"Deployment target triggers"}>allow you to specify which events on your deployment targets will automatically trigger a release of your project.</Note>
                <ImageWithPlaceholder src={imageForDeploymentTarget} alt={"Deployment process"} />
            </TermsText>
        </OverviewContainer>
    );
};

export const CommonProjectTriggerTopics: React.StatelessComponent<{}> = (props) => {
    return (
        <TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="ProjectTriggers">Project Triggers</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>
    );
};
