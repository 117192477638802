import { HasVersionControlledPersistenceSettings, ProjectResource, VcsBranchResource, VersionControlledPersistenceSettings } from "../../client/resources";
import { Item } from "../../primitiveComponents/form/Select/Select";

const getTextValuePairForBranch = (branch: VcsBranchResource, persistenceSettings: VersionControlledPersistenceSettings): Item => {
    const isDefaultBranch = branch.Name === persistenceSettings.DefaultBranch;
    return {
        text: `${branch.Name} ${isDefaultBranch ? "(default)" : ""}`,
        value: branch.Name,
    };
};

const buildBranchNamesList = (branches: VcsBranchResource[], project: ProjectResource): Item[] => {
    if (!HasVersionControlledPersistenceSettings(project.PersistenceSettings)) throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
    const vcsPersistenceSettings = project.PersistenceSettings;

    return branches.map((branch) => getTextValuePairForBranch(branch, vcsPersistenceSettings));
};

export { getTextValuePairForBranch, buildBranchNamesList };
