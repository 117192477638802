import Permission from "client/resources/permission";
import { client } from "clientInstance";
import { pageId } from "utils/pageId";
import userProfileRouteLinks from "./userProfileRouteLinks";

const userProfileLinks = userProfileRouteLinks(client.spaceId ?? "", "");

export default () => {
    return {
        me: pageId({ area: "User Profile", id: "UserProfile", name: "User Profile", routeLink: userProfileLinks.me }),
        logins: pageId({ area: "User Profile", id: "UserProfile.Logins", name: "User Profile Logins", routeLink: userProfileLinks.logins }),
        details: pageId({ area: "User Profile", id: "UserProfile.Details", name: "User Profile Details", routeLink: userProfileLinks.details }),
        apiKeys: pageId({ area: "User Profile", id: "UserProfile.ApiKeys", name: "User Profile API Keys", routeLink: userProfileLinks.apiKeys }),
        activity: pageId({ area: "User Profile", id: "UserProfile.Activity", name: "User Profile Activity", routeLink: userProfileLinks.activity, routePermission: { permission: Permission.EventView, wildcard: true } }),
        register: pageId({ area: "User Profile", id: "UserRegister", name: "Register", routeLink: null }),
        signIn: pageId({ area: "User Profile", id: "UserSignIn", name: "Sign In", routeLink: null }),
        signOut: pageId({ area: "User Profile", id: "UserSignOut", name: "Sign Out", routeLink: null }),
    };
};
