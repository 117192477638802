/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { repository } from "clientInstance";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";
import PaperLayout from "components/PaperLayout";
import ActionButton, { ActionButtonType } from "components/Button";
import { PackageResource, ResourceCollection, TaskState, Permission, TaskName } from "client/resources";
import { Callout, CalloutType } from "primitiveComponents/dataDisplay/Callout";
import LatestPackagesList from "./LatestPackagesList";
import { PackageUpload } from "./UploadPackage";
import { RouteComponentProps } from "react-router";
import { Section } from "components/Section/Section";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import SidebarLayout from "components/SidebarLayout/SidebarLayout";
import Onboarding from "./Onboarding";
import PermissionCheck, { isAllowed } from "components/PermissionCheck/PermissionCheck";
import routeLinks from "../../../../routeLinks";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import { PackagingInstructionSelector } from "./PackagingOnboarding/PackagingInstructionSelector";
import BuiltInRepositoryRetention from "./BuiltInRepositoryRetention";
import BuiltInRepositoryIndexing from "./BuiltInRepositoryIndexing";
import { BuiltInFeedResource } from "client/resources/feedResource";
import { Action, AnalyticActionDispatcher, useAnalyticActionDispatch } from "analytics/Analytics";

interface BuiltInRepositoryOverviewState extends DataBaseComponentState {
    showPushExample: boolean;
    showPackagingExample: boolean;
    lastRepositorySyncStatus?: string;
    lastRepositorySyncId?: string;
    lastRepositorySyncCompletedTime?: string;
    isBuiltInRepoSyncEnabled?: boolean;
    redirectTo?: string;
    initialPackages?: ResourceCollection<PackageResource>;
    loaded: boolean;
    builtInResource?: BuiltInFeedResource;
    totalPackages: number;
    showOnboardingSidebar: boolean;
}

type BuiltInRepositoryOverviewProps = RouteComponentProps<{}>;

interface BuiltInRepositoryOverviewPropsInternal extends BuiltInRepositoryOverviewProps {
    dispatchAction: AnalyticActionDispatcher;
}

export class BuiltInRepositoryOverviewInternal extends DataBaseComponent<BuiltInRepositoryOverviewPropsInternal, BuiltInRepositoryOverviewState> {
    private defaultTake = 50;
    private canConfigureServer = isAllowed({ permission: Permission.ConfigureServer });

    constructor(props: BuiltInRepositoryOverviewPropsInternal) {
        super(props);
        this.state = {
            showPushExample: false,
            showPackagingExample: false,
            loaded: false,
            showOnboardingSidebar: false,
            totalPackages: 0,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(() => this.initialLoad());
    }

    async initialLoad() {
        const builtInResource = this.canConfigureServer ? await repository.Feeds.getBuiltIn() : null!;
        const getTotalPackages = repository.Feeds.getBuiltInStatus();
        const getinitialPackages = this.getInitialPackages("", this.defaultTake);
        const lastRepositorySyncStatus = await (this.canConfigureServer ? this.getLastRepositorySync() : Promise.resolve(null));

        this.setState({
            totalPackages: (await getTotalPackages).TotalPackages,
            builtInResource,
            lastRepositorySyncStatus: (lastRepositorySyncStatus && lastRepositorySyncStatus.status)!,
            lastRepositorySyncId: (lastRepositorySyncStatus && lastRepositorySyncStatus.id)!,
            lastRepositorySyncCompletedTime: (lastRepositorySyncStatus && lastRepositorySyncStatus.completedTime)!,
            initialPackages: await getinitialPackages,
            loaded: true,
        });
    }

    async getLastRepositorySync() {
        const tasks = await repository.Tasks.list({ name: TaskName.SynchronizeBuiltInPackageRepositoryIndex, take: 1 });

        if (tasks.Items.length === 0) {
            return { status: "Never synced" };
        }

        const task = tasks.Items[0];
        switch (task.State) {
            case TaskState.Success:
                return { id: task.Id, status: "Succeeded", completedTime: task.CompletedTime };
            case TaskState.Executing:
                return { id: task.Id, status: "Running", completedTime: task.CompletedTime };
            default:
                return { id: task.Id, status: "Failed", completedTime: task.CompletedTime };
        }
    }

    async setIsBuiltInRepoSyncEnabled(value: boolean) {
        return this.doBusyTask(async () => {
            const newResource = { ...this.state.builtInResource! };
            newResource.IsBuiltInRepoSyncEnabled = value;
            await repository.Feeds.modify(newResource);
            const updated = await repository.Feeds.getBuiltIn();
            this.setState({ builtInResource: updated });
        });
    }

    async onReindexClick() {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createSynchronizeBuiltInPackageRepositoryTask();

            this.setState({
                redirectTo: routeLinks.task(task).root,
            });
        });
    }

    async afterPackageUpload(packageId: string) {
        this.setState({
            redirectTo: routeLinks.library.builtInRepository.package(packageId),
        });
    }

    async getInitialPackages(filter: string, take: number) {
        return repository.Packages.list({ filter, latest: true, take });
    }

    render() {
        if (this.state && this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true} />;
        }

        const loaded = this.state.loaded;

        const sideBar = loaded && (
            <div>
                {this.state.totalPackages === 0 && !this.state.showOnboardingSidebar ? (
                    <React.Fragment>
                        <br />
                        <ActionButton type={ActionButtonType.Ternary} label="Show Advanced Actions" onClick={() => this.setState({ showOnboardingSidebar: true })} />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <BuiltInRepositoryRetention config={this.state.builtInResource!} afterConfigurationUpdated={(builtInResource) => this.setState({ builtInResource })} />
                        <hr />
                        <BuiltInRepositoryIndexing
                            isBuiltInRepoSyncEnabled={this.state.builtInResource ? this.state.builtInResource.IsBuiltInRepoSyncEnabled : false}
                            lastSyncStatus={this.state.lastRepositorySyncStatus}
                            lastSyncId={this.state.lastRepositorySyncId}
                            lastSyncCompletedTime={this.state.lastRepositorySyncCompletedTime}
                            totalPackages={this.state.totalPackages}
                            onReindexClick={() => this.onReindexClick()}
                            setIsBuiltInRepoSyncEnabled={(value) => this.setIsBuiltInRepoSyncEnabled(value)}
                            busy={this.state.busy!}
                        />
                    </React.Fragment>
                )}
            </div>
        );

        const uploadPackageButton = loaded && (
            <PermissionCheck permission={Permission.BuiltInFeedPush} project="*">
                <OpenDialogButton type={ActionButtonType.Primary} label="Upload package" onClick={() => this.props.dispatchAction("Upload Package", { resource: "Package", action: Action.Add })}>
                    <PackageUpload afterPackageUpload={(id) => this.afterPackageUpload(id)} />
                </OpenDialogButton>
            </PermissionCheck>
        );

        return (
            <LibraryLayout {...this.props}>
                <PaperLayout title="Built-in Package Repository" sectionControl={uploadPackageButton} busy={this.state.busy} errors={this.errors}>
                    {loaded && (
                        <SidebarLayout sideBar={sideBar}>
                            <React.Fragment>
                                {loaded && this.state.totalPackages > 0 && <React.Fragment>{this.state.showPackagingExample && <PackagingInstructionSelector />}</React.Fragment>}
                                {loaded && this.state.totalPackages === 0 && <Onboarding />}
                                {loaded && this.state.totalPackages > 0 && (
                                    <React.Fragment>
                                        <Section>
                                            <Callout type={CalloutType.Information} title={"Octopus is not a full NuGet repository"}>
                                                Octopus does not allow NuGet clients to connect to this feed to retrieve packages; the feed only supports deployable packages, and doesn't allow NuGet packages to be consumed from Visual Studio and
                                                other tools.
                                            </Callout>
                                        </Section>
                                        <LatestPackagesList match={this.props.match} initialPackages={this.state.initialPackages!} />
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        </SidebarLayout>
                    )}
                </PaperLayout>
            </LibraryLayout>
        );
    }
}

export function BuiltInRepositoryOverview(props: BuiltInRepositoryOverviewProps) {
    const dispatchAction = useAnalyticActionDispatch();

    return <BuiltInRepositoryOverviewInternal {...props} dispatchAction={dispatchAction} />;
}
