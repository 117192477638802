/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { Select, ExpandableFormSection, SummaryNode } from "components/form";
import ActionButton from "components/Button";
import DialogOpener from "components/Dialog/DialogOpener";
import OkDialogLayout from "components/DialogLayout/OkDialogLayout";

interface DialogWithFormProps {
    enabled?: boolean;
}

interface DialogWithFormState extends DataBaseComponentState {
    editContainer: boolean;
    selectValue: string;
}

export default class Summary {
    static summary(node: React.ReactNode): SummaryNode {
        return {
            node,
            type: "summary",
        };
    }
}

export class DialogWithForm extends DataBaseComponent<DialogWithFormProps, DialogWithFormState> {
    constructor(props: DialogWithFormProps) {
        super(props);
        this.state = {
            editContainer: false,
            selectValue: "",
        };
    }

    setEditContainer = (e: boolean) => {
        this.setState({ editContainer: e });
    };

    setSelectValue = (e: string) => {
        this.setState({ selectValue: e });
    };

    render() {
        return (
            <div>
                <ActionButton label="Show Dialog with Expandable Form Section and Select" onClick={() => this.setEditContainer(!this.state.editContainer)} />
                <DialogOpener open={this.state.editContainer} onClose={() => this.setEditContainer(!this.state.editContainer)} wideDialog={true}>
                    <OkDialogLayout onOkClick={() => this.setEditContainer(!this.state.editContainer)} busy={this.state.busy} errors={this.errors} title={"Add Container"}>
                        <ExpandableFormSection errorKey={"SomeErrorKey"} summary={Summary.summary(this.state.selectValue)} title={"Select one of the following"}>
                            <Select
                                value={this.state.selectValue}
                                items={[
                                    { text: "", value: "" },
                                    { text: "Mario", value: "Mario" },
                                    { text: "Luigi", value: "Luigi" },
                                    { text: "Bowser", value: "Bowser" },
                                ]}
                                label="Some select in an expander"
                                onChange={(value) => this.setSelectValue(value!)}
                            />
                        </ExpandableFormSection>
                    </OkDialogLayout>
                </DialogOpener>
            </div>
        );
    }
}
