/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-restricted-imports */

import * as React from "react";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { connect } from "react-redux";
import { Drawer } from "@material-ui/core";
import classNames = require("classnames");
import MediaQuery from "react-responsive";
import { ControlledTabsContainer } from "primitiveComponents/navigation/Tabs";
import TabItem from "primitiveComponents/navigation/Tabs/TabItem";
import { pageConfigSelector } from "components/PageLayout/reducers/pageLayoutArea";
import IPageWrapper from "utils/pageId";
import { CommonPageHelpOverviewSelector, CommonPageHelpTopicsSelector } from "./PageHelpRegistry/PageHelpSelector";
import { isDrawerOpen, toggleDrawer } from "components/Drawer/reducers";
import { OverflowMenu, OverflowMenuItems } from "components/Menu";
import configurationSelectors from "areas/configuration/reducers/selectors";
import { Dispatch } from "redux";
import { AnalyticLinkProvider } from "analytics/AnalyticLink";

const styles = require("./DrawerWrapperLayout.less");

export const drawerBreakpointWidth = 900;

interface GlobalConnectedProps {
    isOpen?: boolean;
    page?: IPageWrapper;
    isHelpSidebarEnabled?: boolean;
    helpSidebarSupportLink?: string;
}

interface GlobalDispatchProps {
    toggleDrawer?(): void;
}

enum DrawerTabKey {
    Overview = "overview",
    HelpTopics = "helpTopics",
}

type DrawerWrapperLayoutProps = React.PropsWithChildren<GlobalConnectedProps & GlobalDispatchProps>;

interface DrawerWrapperLayoutState {
    height: number;
    tab: string;
}

class DrawerWrapperLayoutInternal extends BaseComponent<DrawerWrapperLayoutProps, DrawerWrapperLayoutState> {
    private drawerDiv: HTMLElement | null = null;
    private initialTop: number | null = null;

    constructor(props: DrawerWrapperLayoutProps) {
        super(props);
        this.state = {
            height: 0,
            tab: DrawerTabKey.Overview,
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.calculateHeight);
        window.requestAnimationFrame(() => {
            this.calculateTop();
            this.calculateHeight();
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.calculateHeight);
    }

    calculateTop() {
        if (this.drawerDiv !== null) {
            this.initialTop = this.drawerDiv.getBoundingClientRect().top;
        }
    }

    calculateHeight = () => {
        const approxHeightOfPaddingAndOtherJunk = 0;
        const height = window.innerHeight - (this.initialTop ?? 0) - approxHeightOfPaddingAndOtherJunk;
        this.setState({ height });
    };

    notifyToggleDrawer = () => {
        if (this.props.toggleDrawer) {
            this.props.toggleDrawer();
        }
    };

    render() {
        if (!this.props.isHelpSidebarEnabled) {
            return this.props.children;
        }

        const { isOpen } = this.props;
        return (
            <MediaQuery minWidth={drawerBreakpointWidth}>
                {(matches: boolean) => {
                    if (matches) {
                        return (
                            <div id="drawerwrapper">
                                <div className={styles.root}>
                                    <main
                                        className={classNames(styles.mainContent, {
                                            [styles.mainContentShift]: isOpen,
                                        })}
                                    >
                                        {this.props.children}
                                    </main>
                                    <Drawer
                                        className={classNames(styles.drawer, {
                                            [styles.drawerHidden]: !isOpen,
                                        })}
                                        variant="persistent"
                                        anchor="right"
                                        open={isOpen}
                                        classes={{
                                            paper: classNames(styles.drawerPaper),
                                        }}
                                    >
                                        {isOpen && (
                                            <div className={styles.container} ref={(div) => (this.drawerDiv = div)} style={{ height: this.state.height }}>
                                                <AnalyticLinkProvider location="Help sidebar">
                                                    <div className={styles.content}>
                                                        <ControlledTabsContainer
                                                            value={this.state.tab}
                                                            onChange={(tab) => this.setState({ tab })}
                                                            afterTabsElement={<OverflowMenu menuItems={[OverflowMenuItems.item("Close", this.notifyToggleDrawer)]} tabIndex={-1} />}
                                                        >
                                                            <TabItem label="Help" value={DrawerTabKey.Overview}>
                                                                <CommonPageHelpOverviewSelector pageId={this.props.page!} />
                                                            </TabItem>
                                                            <TabItem label="Resources" value={DrawerTabKey.HelpTopics}>
                                                                <CommonPageHelpTopicsSelector pageId={this.props.page!} />
                                                            </TabItem>
                                                        </ControlledTabsContainer>
                                                    </div>
                                                    <a href={this.props.helpSidebarSupportLink ? this.props.helpSidebarSupportLink : "https://g.octopushq.com/HelpGeneral"} target="_blank" rel="noopener noreferrer">
                                                        <div className={styles.footer}>
                                                            <h4>Having issues?</h4>
                                                            <span>
                                                                {this.props.helpSidebarSupportLink ? "Get support" : "Our support team is here to help"}
                                                                <em style={{ marginLeft: "0.25rem" }} className="fa fa-external-link" aria-hidden="true" />
                                                            </span>
                                                        </div>
                                                    </a>
                                                </AnalyticLinkProvider>
                                            </div>
                                        )}
                                    </Drawer>
                                </div>
                            </div>
                        );
                    } else {
                        return this.props.children;
                    }
                }}
            </MediaQuery>
        );
    }
}

const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    const currentOverlayState = {
        isOpen: isDrawerOpen(state),
        ...pageConfigSelector(state),
        isHelpSidebarEnabled: configurationSelectors.createFeatureEnabledSelector((t) => t.isHelpSidebarEnabled)(state),
        helpSidebarSupportLink: state.configurationArea.features && state.configurationArea.features.helpSidebarSupportLink,
    };
    return currentOverlayState;
};

const mapGlobalActionDispatchersToProps = (dispatch: Dispatch): GlobalDispatchProps => {
    return {
        toggleDrawer: () => dispatch(toggleDrawer()),
    };
};

const DrawerWrapperLayout = connect<{}, {}, DrawerWrapperLayoutProps, GlobalState>(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(DrawerWrapperLayoutInternal);

export default DrawerWrapperLayout;
