/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-restricted-imports */

import * as React from "react";
import { UserResource } from "client/resources";
import cn from "classnames";
import Popover from "components/Popover";
import Menu from "material-ui/Menu";
import { Avatar } from "primitiveComponents/dataDisplay/Avatar/Avatar";
import ExternalLink from "../Navigation/ExternalLink";
import MenuItem from "material-ui/MenuItem";
import { repository } from "clientInstance";
import Divider from "../../primitiveComponents/dataDisplay/Divider/Divider";
import InternalLink from "../Navigation/InternalLink/InternalLink";
import routeLinks from "../../routeLinks";
import { isSpaceNotFound, SpaceContext } from "../StandardLayout/SpaceLoader";
import MediaQuery from "react-responsive";
import { drawerBreakpointWidth } from "components/Drawer/DrawerWrapperLayout";
import { CommonNeedHelpInformation } from "components/Drawer/PageHelpRegistry/Registrations/CommonComponents/CommonViews";
import { connect } from "react-redux";
import { Box, Switch, PaletteType } from "@material-ui/core";
import { ThemeStorage } from "components/Theme/ThemeStorage";
import { withTheme, OctopusTheme } from "components/Theme";
const styles = require("./style.less");

interface GlobalConnectedProps {
    isHelpSidebarEnabled?: boolean;
    helpSidebarSupportLink?: string;
}

interface UserAccountMenuState {
    isMenuOpen: boolean;
    version: string;
    isDarkModeEnabled: boolean;
    currentTheme: PaletteType;
}

interface UserAccountMenuProps {
    currentUser: UserResource;
    spaceContext: SpaceContext;
}

type Props = UserAccountMenuProps & GlobalConnectedProps;

class UserAccountMenuInternal extends React.Component<Props, UserAccountMenuState> {
    private menuButton: HTMLElement = undefined!;

    constructor(props: Props) {
        super(props);
        this.state = {
            isMenuOpen: false,
            version: "",
            isDarkModeEnabled: false,
            currentTheme: ThemeStorage.get(),
        };
    }

    componentDidMount() {
        const serverInformation = repository.getServerInformation();
        const version = serverInformation.version;
        this.setState({ version });
        ThemeStorage.addEventListener(this.themeChanged);
    }

    componentWillUnmount() {
        ThemeStorage.removeEventListener(this.themeChanged);
    }

    renderHelpLinks(theme: OctopusTheme) {
        return (
            <>
                <Divider />
                <ExternalLink href="DocumentationHome" showIcon={false}>
                    <MenuItem style={{ color: theme.primary }} onClick={this.handleRequestClose} primaryText="Documentation" />
                </ExternalLink>
                <ExternalLink href={window.location.origin + window.location.pathname + "/../swaggerui/"} showIcon={false}>
                    <MenuItem style={{ color: theme.primary }} onClick={this.handleRequestClose} primaryText="API Documentation" />
                </ExternalLink>
                <ExternalLink href={this.props.helpSidebarSupportLink ? this.props.helpSidebarSupportLink : "HelpGeneral"} showIcon={false}>
                    <MenuItem style={{ color: theme.primary }} onClick={this.handleRequestClose} primaryText="Support" />
                </ExternalLink>
                <ExternalLink href="TrainingVideos" showIcon={false}>
                    <MenuItem style={{ color: theme.primary }} onClick={this.handleRequestClose} primaryText="Training Videos" />
                </ExternalLink>
                <ExternalLink href="HelpMakeSuggestion" showIcon={false}>
                    <MenuItem style={{ color: theme.primary }} onClick={this.handleRequestClose} primaryText="Suggestions" />
                </ExternalLink>
                <ExternalLink href="https://octopus.com/downloads" showIcon={false}>
                    <MenuItem style={{ color: theme.primary }} onClick={this.handleRequestClose} primaryText="Downloads" />
                </ExternalLink>
            </>
        );
    }

    render() {
        return withTheme((theme) => {
            const isDarkModeEnabled = this.state.currentTheme === "dark";
            const meLink = isSpaceNotFound(this.props.spaceContext)
                ? routeLinks.withoutSpace().currentUser.me // Redirect back to `/` (no space prefix) to ensure we get redirected to an appropriate space context first
                : routeLinks.currentUser.me;
            const [major, minor, build] = this.state.version.split(".");

            return (
                <div>
                    <a className={cn(styles.button)} onClick={this.handleTouchTap} href="#" ref={this.setRef}>
                        <Avatar avatarLink={this.props.currentUser && this.props.currentUser.Links && this.props.currentUser.Links.Avatar} isService={this.props.currentUser && this.props.currentUser.IsService} size={24} />
                        <span className={cn(styles.userName, "hide-sm")}>{this.props.currentUser.DisplayName}</span>
                        <em className={cn("fa fa-caret-down", styles.icon)} />
                    </a>
                    <Popover open={this.state.isMenuOpen} anchorEl={this.menuButton} onClose={this.handleRequestClose} anchorOrigin={{ horizontal: "right", vertical: "bottom" }} transformOrigin={{ horizontal: "right", vertical: "top" }}>
                        <Menu>
                            <MenuItem style={{ color: theme.secondaryText, fontSize: "0.875rem" }} disabled={true}>
                                {
                                    <ExternalLink href={"https://octopus.com/downloads/" + this.state.version}>
                                        v{major}.{minor} (Build {build})
                                    </ExternalLink>
                                }
                            </MenuItem>
                            <Divider />

                            <MenuItem onClick={this.toggleTheme}>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                                    <div className={styles.newFeature}>Dark Theme</div>
                                    <Switch size="small" checked={isDarkModeEnabled} />
                                </Box>
                            </MenuItem>
                            <Divider />

                            <InternalLink to={routeLinks.currentUser.signOut} onClick={this.handleRequestClose}>
                                <MenuItem primaryText="Sign Out" />
                            </InternalLink>
                            <InternalLink to={meLink} onClick={this.handleRequestClose}>
                                <MenuItem primaryText="Profile" />
                            </InternalLink>
                            {!this.props.isHelpSidebarEnabled ? (
                                this.renderHelpLinks(theme)
                            ) : (
                                <MediaQuery minWidth={drawerBreakpointWidth}>
                                    {(matches: boolean) => {
                                        if (matches) {
                                            return (
                                                <>
                                                    <Divider />
                                                    <MenuItem style={{ color: theme.secondaryText, fontSize: "0.875rem", whiteSpace: "normal", width: "15rem", lineHeight: "1rem" }} disabled={true}>
                                                        <h4>Need help?</h4>
                                                        <CommonNeedHelpInformation />
                                                    </MenuItem>
                                                </>
                                            );
                                        } else {
                                            return this.renderHelpLinks(theme);
                                        }
                                    }}
                                </MediaQuery>
                            )}
                        </Menu>
                    </Popover>
                </div>
            );
        });
    }

    themeChanged = (newTheme: PaletteType) => {
        this.setState({
            currentTheme: newTheme,
        });
    };

    private toggleTheme = () => {
        const current = ThemeStorage.get();
        const newTheme = current === "light" ? "dark" : "light";
        ThemeStorage.set(newTheme);
    };

    private setRef = (el: HTMLAnchorElement) => {
        this.menuButton = el;
    };

    private handleTouchTap = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();

        this.setState({
            isMenuOpen: !this.state.isMenuOpen,
        });
    };

    private handleRequestClose = () => {
        this.setState({
            isMenuOpen: false,
        });
    };
}

const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    return {
        isHelpSidebarEnabled: state.configurationArea.features.isHelpSidebarEnabled,
        helpSidebarSupportLink: state.configurationArea.features.helpSidebarSupportLink,
    };
};

const UserAccountMenu = connect<{}, {}, UserAccountMenuProps, GlobalState>(mapGlobalStateToProps, null!)(UserAccountMenuInternal);

export default UserAccountMenu;
