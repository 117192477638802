import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonConfigurationRoleOverview, CommonConfigurationRoleTopics } from "../../CommonComponents/CommonConfigurationRoleViews";

export const ConfigurationRolesNewRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.roles.create,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationRoleOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationRoleTopics />;
    },
};
