import * as React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import cn from "classnames";
const styles = require("./styles.less");

export interface SvgImageProps {
    title: string;
    viewBox?: string;
    width?: string;
    height?: string;
    margin?: string;
    className?: string;
}

class SvgImage extends React.PureComponent<SvgImageProps> {
    render() {
        return (
            <div className={cn(styles.imageContainer, this.props.className)} style={{ width: this.props.width, height: this.props.height, margin: this.props.margin }}>
                <SvgIcon className={styles.svgIcon} viewBox={this.props.viewBox} role="img" aria-labelledby="title">
                    <title id="title">{this.props.title}</title>
                    {this.props.children}
                </SvgIcon>
            </div>
        );
    }
}

export default SvgImage;
