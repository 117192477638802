import { MachinesState, default as machinesReducer } from "./machines";
import { AccountsState, default as accountsReducer } from "./accounts";
import { EndpointsState, default as endpointsReducer } from "./endpoints";
import { combineReducers } from "redux";

export * from "./selectors";

export interface InfrastructureAreaState {
    machines: MachinesState;
    accounts: AccountsState;
    endpoints: EndpointsState;
}

export default combineReducers<InfrastructureAreaState>({
    machines: machinesReducer,
    accounts: accountsReducer,
    endpoints: endpointsReducer,
});
