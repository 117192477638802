import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonProjectTopics } from "../../CommonComponents/CommonProjectViews";
import { OverviewContainer, OverviewHighlightText } from "../../CommonComponents/CommonViews";

export const ProjectDeploymentsSettingsRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().deploymentSettings,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return (
            <OverviewContainer>
                <OverviewHighlightText>
                    <p>The settings on this page control how releases are created and deployed.</p>
                </OverviewHighlightText>
            </OverviewContainer>
        );
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectTopics />;
    },
};
