import * as React from "react";
import PaperLayout from "components/PaperLayout";
import Section from "components/Section";

const UxPatternsWhatever: React.FC = () => {
    return (
        <PaperLayout title={"Whatever"} busy={undefined} errors={undefined}>
            <Section>...</Section>
        </PaperLayout>
    );
};

export default UxPatternsWhatever;
