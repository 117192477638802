import * as React from "react";
import cn from "classnames";
const styles = require("./style.less");

interface DashboardLimitSummaryProps {
    matchCount: number;
    projectLimit: number;
    hasFilter: boolean;
    totalProjects: number;
}

const DashboardLimitSummary: React.FC<DashboardLimitSummaryProps> = ({ matchCount, projectLimit, hasFilter, totalProjects }) => {
    if (hasFilter && matchCount >= 0 && matchCount !== null) {
        return <span className={cn(styles.matchCount, styles.info)}>{`${matchCount} of ${totalProjects} projects match`}</span>;
    }

    if (!hasFilter && totalProjects >= projectLimit) {
        return <span className={styles.matchCount}>{`${projectLimit} of ${totalProjects} projects displayed`}</span>;
    }

    return null;
};

export default DashboardLimitSummary;
