import { repository } from "clientInstance";
import { WorkerPoolResource } from "../client/resources";

const getWorkerPoolIndex = async (): Promise<WorkerPoolIndex> => {
    const idx: { [poolId: string]: WorkerPoolResource } = {};
    const pools = await repository.WorkerPools.all();
    if (pools) {
        pools.forEach((p) => (idx[p.Id] = p));
    }
    return idx;
};

interface WorkerPoolIndex {
    [poolId: string]: WorkerPoolResource;
}

export { getWorkerPoolIndex, WorkerPoolIndex };
