/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { repository, session } from "../../clientInstance";
import { Permission } from "../../client/resources";
import { isRunningInAFunctionalTest } from "utils/isRunningInAFunctionalTest";

export interface PermissionCheckProps {
    permission: Permission | Permission[];
    children?: React.ReactNode;
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    alternate?: any;
    project?: string;
    tenant?: string;
    environment?: string;
    projectGroup?: string;
    wildcard?: boolean;
    render?: () => JSX.Element;
}

const PermissionCheck: React.SFC<PermissionCheckProps> = ({ render, ...props }) => {
    const allowed = isAllowed(props);
    const alternate = props.alternate || null;
    const children = props.children || null;

    if (render) {
        return allowed ? render() : alternate;
    }

    const renderChildren = () => (children && typeof children === "function" ? children() : children);

    return <>{allowed ? renderChildren() : alternate}</>;
};

export function hasPermission(permission: Permission) {
    return isAllowed({ permission, wildcard: true });
}

export function isAllowed(props: PermissionCheckProps) {
    return isAllowedForSpace(repository.spaceId!, props);
}

export function isAllowedForSpace(spaceId: string, props: PermissionCheckProps) {
    const pid: string = props.project || (props.wildcard ? "*" : null!);
    const eid: string = props.environment || (props.wildcard ? "*" : null!);
    const gid: string = props.projectGroup || (props.wildcard ? "*" : null!);
    const tid: string = props.tenant || (props.wildcard ? "*" : null!);

    const permissions = props.permission instanceof Array ? props.permission : [props.permission];

    const checks: boolean[] = permissions.map((p) => {
        return getCurrentPermissions().scopeToSpaceAndSystem(spaceId).isAuthorized({
            permission: p,
            projectId: pid,
            environmentId: eid,
            projectGroupId: gid,
            tenantId: tid,
        });
    });

    return checks.some((x) => x);
}

export function firstAuthorized(permissions: Permission[]): Permission {
    return getCurrentPermissions().scopeToSpaceAndSystem(repository.spaceId).firstAuthorized(permissions)!;
}

function getCurrentPermissions() {
    const currentPermissions = session.currentPermissions;
    if (!currentPermissions) {
        const extraContextForFunctionalTests =
            " In your Functional Test, use `setupGlobals` to establish the session and refresh permissions, or call `setupGlobals.all(...)`." +
            " For more information, see https://github.com/OctopusDeploy/OctopusDeploy/blob/master/newportal/docs/functional_tests.md#globalambient-context`";
        const errorMessage = `The session has not been established with permissions.${isRunningInAFunctionalTest() ? extraContextForFunctionalTests : ""}`;
        throw new Error(errorMessage);
    }
    return currentPermissions;
}

export default PermissionCheck;
