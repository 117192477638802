/* eslint-disable @typescript-eslint/consistent-type-assertions */
import * as React from "react";
import { ProjectGroupResource } from "client/resources";
import { MultiSelect } from "components/MultiSelect";
import { FormFieldProps } from "components/form";
import { ChipIcon, MissingChip, ProjectGroupChip } from "components/Chips";
import { SelectItem } from "components/VirtualListWithKeyboard/SelectItem";
import Lookup from "../Lookup";

interface ProjectGroupMultiSelectProps extends FormFieldProps<string[]> {
    items: ProjectGroupResource[];
    label?: string | JSX.Element;
    error?: string;
}

const ProjectGroupTypedMultiSelect = MultiSelect<ProjectGroupResource>();

export const ProjectGroupMultiSelect: React.FC<ProjectGroupMultiSelectProps> = (props) => {
    const chipRenderer = (r: ProjectGroupResource | SelectItem, onRequestDelete: () => void) => {
        return (
            <Lookup
                lookupCollection={props.items}
                lookupId={r.Id}
                getIdFromElement={(element) => element.Id}
                render={(item) => <ProjectGroupChip onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${item.Name}`} projectGroup={item} />}
                renderFallback={<MissingChip type={ChipIcon.ProjectGroup} lookupId={r.Id} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete} />}
            />
        );
    };

    return <ProjectGroupTypedMultiSelect fieldName="project groups" renderChip={chipRenderer} {...props} />;
};
