/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { TaskResource } from "../../../../../client/resources/taskResource";
import { Callout, CalloutType } from "../../../../../primitiveComponents/dataDisplay/Callout/Callout";
import InternalLink from "../../../../../components/Navigation/InternalLink/InternalLink";
import routeLinks from "../../../../../routeLinks";

interface PendingInterruptionsProps {
    pendingInterruptions: Array<TaskResource<any>>;
}
const PendingInterruptions: React.StatelessComponent<PendingInterruptionsProps> = (props) => {
    return (
        <div>
            {props.pendingInterruptions && props.pendingInterruptions.length > 0 && (
                <Callout
                    type={CalloutType.Warning}
                    title={props.pendingInterruptions.length === 1 ? "1 other release still has a pending interruption" : props.pendingInterruptions.length + " other releases still have pending interruptions that should be reviewed."}
                >
                    <ul>
                        {props.pendingInterruptions.map((p, index) => (
                            <li key={index}>
                                <InternalLink to={routeLinks.task(p).root}>{p.Description}</InternalLink>
                            </li>
                        ))}
                    </ul>
                </Callout>
            )}
        </div>
    );
};

export default PendingInterruptions;
