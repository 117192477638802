import { createVisibilityReducer } from "utils/Reducers/visibilityReducer";

const menuSliceSelector = (state: GlobalState) => state.menus;
const { reducer, actions, getSelectors } = createVisibilityReducer("menu");
const { createVisibleSelector } = getSelectors(menuSliceSelector);

const selectors = {
    createMenuOpenSelector: createVisibleSelector,
};

export default reducer;
export { actions, selectors };
