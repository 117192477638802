import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonConfigurationSubscriptionOverview, CommonConfigurationSubscriptionTopics } from "../../CommonComponents/CommonConfigurationSubscriptionViews";

export const ConfigurationSubscriptionRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.subscription,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationSubscriptionOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationSubscriptionTopics />;
    },
};
