import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonTenantOverview, CommonTenantTopics } from "../../CommonComponents/CommonTenantViews";

export const TenantsRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.tenants,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonTenantOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonTenantTopics />;
    },
};
