import BasicRepository from "./basicRepository";
import Client from "../client";
import { TagSetResource } from "../resources/tagSetResource";

class TagSetRepository extends BasicRepository<TagSetResource, TagSetResource> {
    constructor(client: Client) {
        super("TagSets", client);
    }
    sort(ids: string[]) {
        return this.client.put(this.client.getLink("TagSetSortOrder"), ids);
    }
}

export default TagSetRepository;
