import * as React from "react";
import { EventCategoryResource } from "client/resources";
import { MultiSelect } from "components/MultiSelect";
import { FormFieldProps } from "components/form";
import { EventCategoryChip, MissingChip } from "components/Chips";
import { ChipIcon } from "components/Chips";
import Lookup from "../Lookup";
import { ComponentProps } from "../../utils/types";

interface EventCategoryMultiSelectProps extends FormFieldProps<string[]> {
    items: EventCategoryResource[];
    label?: string | JSX.Element;
    error?: string;
}

const EventCategoryTypedMultiSelect = MultiSelect<EventCategoryResource>();
type RenderChip = ComponentProps<typeof EventCategoryTypedMultiSelect>["renderChip"];

const EventCategoryMultiSelect: React.FC<EventCategoryMultiSelectProps> = (props) => {
    const chipRenderer: RenderChip = (r, onRequestDelete: () => void) => {
        return (
            <Lookup
                lookupCollection={props.items}
                lookupId={r.Id}
                getIdFromElement={(element) => element.Id}
                render={(item) => <EventCategoryChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} eventCategory={item} />}
                renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.EventCategory} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete} />}
            />
        );
    };

    return <EventCategoryTypedMultiSelect fieldName="event categories" renderChip={chipRenderer} {...props} />;
};

export default EventCategoryMultiSelect;
