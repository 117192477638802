import * as React from "react";
import { ChannelResource, ProjectResource, TenantedDeploymentMode } from "client/resources";
import { Lifecycles } from "./Channels";
import MarkdownDescription from "components/MarkdownDescription";
import RulesDescriptionList from "./RulesDescriptionList";
import { TagIndex } from "components/tenantTagsets";
const styles = require("./style.less");
import ListTitle from "primitiveComponents/dataDisplay/ListTitle/ListTitle";
import { DefaultOptionChip } from "components/Chips";
import TenantTagsList from "components/TenantTagsList/TenantTagsList";

interface ChannelProps {
    channel: ChannelResource;
    project: ProjectResource;
    lifecycles: Lifecycles;
    tagIndex: TagIndex;
}

const Channel: React.StatelessComponent<ChannelProps> = (props) => {
    const getLifecycleName = () => {
        if (props.channel.LifecycleId) {
            return props.lifecycles[props.channel.LifecycleId].Name;
        }

        return props.lifecycles[props.project.LifecycleId].Name;
    };
    const channel = props.channel;

    return (
        <div>
            <ListTitle>
                {channel.Name}
                {channel.IsDefault && <DefaultOptionChip />}
            </ListTitle>
            <div className={styles.description}>
                <MarkdownDescription markup={channel.Description} />
            </div>
            <div className={styles.grid}>
                <div className={styles.row}>
                    <div className={styles.header}>Lifecycle</div>
                    <div className={styles.value}>{getLifecycleName()}</div>
                </div>
                {channel.TenantTags.length > 0 && props.project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted && (
                    <div className={styles.row}>
                        <div className={styles.header}>Limited to these tenants</div>
                        <div className={styles.value}>
                            <TenantTagsList tags={channel.TenantTags} />
                        </div>
                    </div>
                )}
                {channel.Rules.length > 0 && (
                    <div className={styles.row}>
                        <div className={styles.header}>Version rules</div>
                        <div className={styles.value}>
                            <RulesDescriptionList rules={channel.Rules} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Channel;
