import BasicRepository from "./basicRepository";
import Client from "../client";
import { UserRoleResource } from "../resources/userRoleResource";

class UserRoleRepository extends BasicRepository<UserRoleResource, UserRoleResource> {
    constructor(client: Client) {
        super("UserRoles", client);
    }
}

export default UserRoleRepository;
