/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { groupBy, memoize, compact } from "lodash";
import { FilterableValue } from "areas/variables/VariableFilter/VariableFilter";
import { VariableType } from "client/resources/variableResource";

export interface AllVariableMessages {
    readonly duplicateVariableNames: ReadonlyArray<DuplicatedVariableName>;
    readonly variableMessages: ReadonlyArray<VariableMessages>;
}

export interface VariableMessages {
    readonly hasDuplicateName: boolean;
    readonly valuesMessages: ReadonlyArray<ValueMessages>;
    readonly nameNonPrintableCharacter?: DiscoveredNonPrintableCharacter;

    readonly variableWarningMessages: ReadonlyArray<string>;
    readonly variableNameNonPrintableCharacterWarningMessages: ReadonlyArray<string>;
}

export interface ValueMessages {
    readonly hasEmptyValue: boolean;
    readonly hasVariableSubstitutionSyntax: boolean;
    readonly valueNonPrintableCharacter?: DiscoveredNonPrintableCharacter;

    readonly valueWarningMessages: ReadonlyArray<string>;
    readonly valueNonPrintableCharacterWarningMessages: ReadonlyArray<string>;
    readonly variableSubtitutionSyntaxWarningMessage: string | null;
}

export interface DuplicatedVariableName {
    readonly normalizedName: string;
    readonly originalNames: ReadonlyArray<string>;
}

// We want to skip warnings for some values or variables (eg if they are marked as 'deleted')
type SkipWarningDetection = null;

export default function getVariablesMessages<TVariable>(
    variables: ReadonlyArray<TVariable>,
    getVariableName: (variable: TVariable) => string,
    getFilterableValues: (variable: TVariable) => ReadonlyArray<FilterableValue | SkipWarningDetection>
): AllVariableMessages {
    return new AllVariableMessagesImpl(variables, getVariableName, getFilterableValues);
}

class AllVariableMessagesImpl<TVariable> implements AllVariableMessages {
    private readonly getAllDuplicateVariableNames: () => ReadonlyArray<DuplicatedVariableName>;
    private readonly getVariableMessages: () => ReadonlyArray<VariableMessages>;

    constructor(variables: ReadonlyArray<TVariable>, getVariableName: (variable: TVariable) => string, getFilterableValues: (variable: TVariable) => ReadonlyArray<FilterableValue | SkipWarningDetection>) {
        this.getAllDuplicateVariableNames = memoize<() => ReadonlyArray<DuplicatedVariableName>>(() => getAllDuplicateVariableNames(variables, getVariableName, getFilterableValues));
        this.getVariableMessages = memoize<() => ReadonlyArray<VariableMessages>>(() =>
            variables.map((variable) => {
                return new VariableMessagesImpl<TVariable>(variable, getVariableName, getFilterableValues, () => this.duplicateVariableNames);
            })
        );
    }

    get duplicateVariableNames() {
        return this.getAllDuplicateVariableNames();
    }

    get variableMessages() {
        return this.getVariableMessages();
    }
}

function getAllDuplicateVariableNames<TVariable>(
    variables: ReadonlyArray<TVariable>,
    getVariableName: (variable: TVariable) => string,
    getFilterableValues: (variable: TVariable) => ReadonlyArray<FilterableValue | SkipWarningDetection>
): ReadonlyArray<DuplicatedVariableName> {
    const nonSkippedVariables = variables.filter((g) => getFilterableValues(g).some((v) => !!v));
    const variableNamesLower = groupBy(nonSkippedVariables.map(getVariableName), (n) => n.toLowerCase());
    return Object.keys(variableNamesLower)
        .filter((n) => variableNamesLower[n].length > 1)
        .map((n) => ({ normalizedName: n, originalNames: variableNamesLower[n] }));
}

class VariableMessagesImpl<TVariable> implements VariableMessages {
    private readonly getVariableName: () => string;
    private readonly innerHasDuplicateName: () => boolean;
    private readonly getFilterableVariables: () => ReadonlyArray<FilterableValue | SkipWarningDetection>;
    private readonly getNonSkippedVariables: () => ReadonlyArray<FilterableValue>;
    private readonly getVariableMessages: () => ReadonlyArray<ValueMessages>;
    private readonly getNonPrintableCharacter: () => DiscoveredNonPrintableCharacter | null;

    constructor(
        variable: TVariable,
        getVariableName: (variable: TVariable) => string,
        getFilterableVariables: (variable: TVariable) => ReadonlyArray<FilterableValue | SkipWarningDetection>,
        getAllDuplicateVariableNamesLower: () => ReadonlyArray<DuplicatedVariableName>
    ) {
        this.getVariableName = memoize(() => getVariableName(variable));
        this.innerHasDuplicateName = memoize(() =>
            getAllDuplicateVariableNamesLower()
                .map((dn) => dn.normalizedName)
                .includes(this.getVariableName().toLowerCase())
        );
        this.getFilterableVariables = memoize(() => getFilterableVariables(variable));
        this.getNonSkippedVariables = memoize<() => ReadonlyArray<FilterableValue>>(() => compact(this.getFilterableVariables()));
        this.getVariableMessages = memoize<() => ReadonlyArray<ValueMessages>>(() => this.getFilterableVariables().map((v) => new ValueMessagesImpl(this.getVariableName(), v)));
        this.getNonPrintableCharacter = memoize(() => getNonPrintableCharacter(this.getVariableName()));
    }

    get variableWarningMessages(): ReadonlyArray<string> {
        return [...this.getDuplicateNameWarnings(), ...this.variableNameNonPrintableCharacterWarningMessages];
    }

    private getDuplicateNameWarnings(): ReadonlyArray<string> {
        // There is a different form of this warning that is shown on save
        return this.hasDuplicateName ? ["duplicate name"] : [];
    }

    get hasDuplicateName() {
        if (this.shouldIgnoreWarnings) {
            return false;
        }
        return this.innerHasDuplicateName();
    }

    get variableNameNonPrintableCharacterWarningMessages(): ReadonlyArray<string> {
        return this.nameNonPrintableCharacter ? [createNonPrintableCharacterWarning("name", this.getVariableName(), this.nameNonPrintableCharacter)] : [];
    }

    get nameNonPrintableCharacter() {
        if (this.shouldIgnoreWarnings) {
            return undefined;
        }
        return this.getNonPrintableCharacter()!;
    }

    get valuesMessages() {
        return this.getVariableMessages();
    }

    private get shouldIgnoreWarnings() {
        return !this.getNonSkippedVariables().length;
    }
}

class ValueMessagesImpl implements ValueMessages {
    private readonly getNonPrintableCharacter: () => DiscoveredNonPrintableCharacter | null;

    constructor(private readonly variableGroupName: string, private readonly variable: FilterableValue | SkipWarningDetection) {
        this.getNonPrintableCharacter = memoize(() => getNonPrintableCharacter(variable?.value || ""));
    }

    get valueWarningMessages(): ReadonlyArray<string> {
        const warnings = [...this.valueNonPrintableCharacterWarningMessages];
        const subtitutionSyntaxWarning = this.variableSubtitutionSyntaxWarningMessage;

        if (subtitutionSyntaxWarning) {
            warnings.push(subtitutionSyntaxWarning);
        }

        return warnings;
    }

    get hasEmptyValue() {
        if (this.shouldIgnoreWarnings) {
            return false;
        }

        if (
            this.variable!.isPrompted || // Prompted variables don't need to have a value specified
            this.variable!.type === VariableType.Sensitive
        ) {
            // Sensitive variables always have a value
            return false;
        }

        return !this.variable!.value;
    }

    get hasVariableSubstitutionSyntax() {
        if (this.shouldIgnoreWarnings) {
            return false;
        }

        return this.variableSubtitutionSyntaxWarningMessage !== null;
    }

    get variableSubtitutionSyntaxWarningMessage(): string | null {
        return createVariableSubstitutionWarning(this.variableGroupName, this.variable!);
    }

    get valueNonPrintableCharacterWarningMessages(): ReadonlyArray<string> {
        return this.valueNonPrintableCharacter ? [createNonPrintableCharacterWarning("value", this.variableGroupName, this.valueNonPrintableCharacter)] : [];
    }

    get valueNonPrintableCharacter() {
        if (this.shouldIgnoreWarnings) {
            return undefined;
        }
        return this.getNonPrintableCharacter()!;
    }

    private get shouldIgnoreWarnings() {
        return !this.variable;
    }
}

function createNonPrintableCharacterWarning(property: string, variableGroupName: string, nonPrintableCharacter: DiscoveredNonPrintableCharacter) {
    return `${property} of variable '${variableGroupName}' contains characters which may not be visible: ` + `(Position: ${nonPrintableCharacter.position}, Unicode value: U+${nonPrintableCharacter.unicode})`;
}

export interface DiscoveredNonPrintableCharacter {
    position: number;
    unicode: string;
}

function createVariableSubstitutionWarning(variableGroupName: string, variable: FilterableValue): string | null {
    if (!variable || variable.type !== VariableType.Sensitive) {
        return null;
    }

    return variableSubstitutionSyntaxWarning(variableGroupName, variable.value!);
}

const variableSubstitutionSyntaxWarning = (variableGroupName: string, value: string) => {
    if (value && value.includes("##{")) {
        return variableGroupName;
    }

    return null;
};

function getNonPrintableCharacter(checkText: string): DiscoveredNonPrintableCharacter | null {
    // Match Zero Width and Left/Right marks
    // https://en.wikibooks.org/wiki/Unicode/Character_reference/2000-2FFF
    const match = /[\u200b-\u200f]/.exec(checkText);

    return !!match && match.index !== -1
        ? {
              position: match.index,
              unicode: unicodeAtPosition(checkText, match.index),
          }
        : null;

    function unicodeAtPosition(txt: string, index: number) {
        const leadingZeros = "0000";
        return (leadingZeros + txt.charCodeAt(index)).substr(-4, 4);
    }
}
