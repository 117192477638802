import * as React from "react";
import { useReducer } from "react";
import StyleGuideEntry from "../StyleGuideEntry";
import ActionButton from "components/Button";
import ConfirmationDialog from "components/Dialog/ConfirmationDialog";
import NoActionDialog from "components/Dialog/NoActionDialog";
import { sleep1s } from "../helpers/sleep";
import { createVisibilityReducer } from "utils/Reducers/visibilityReducer";
import { identity } from "lodash";

const { reducer, actions, getSelectors } = createVisibilityReducer("dialog");
type DialogState = ReturnType<typeof reducer>;
const selectors = getSelectors<DialogState>(identity);
const INITIAL_STATE: DialogState = {};

const DialogInlineExample: React.FC = () => {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    enum KnownDialogs {
        ShowConfirmationDialog = "showConfirmationDialog",
        ShowNoActionDialog = "showNoActionDialog",
    }

    return (
        <React.Fragment>
            <ActionButton label="Do some process that may need confirmation" onClick={() => dispatch(actions.open(KnownDialogs.ShowConfirmationDialog))} />
            <ConfirmationDialog
                title="Confirm Something"
                continueButtonLabel="Continue"
                continueButtonBusyLabel="Saving..."
                open={selectors.createVisibleSelector(KnownDialogs.ShowConfirmationDialog)(state)}
                onClose={() => dispatch(actions.close(KnownDialogs.ShowConfirmationDialog))}
                onContinueClick={() => sleep1s()}
            >
                Are you sure?
            </ConfirmationDialog>
            <ActionButton
                label="Do some process that can't continue"
                onClick={() => {
                    dispatch(actions.open(KnownDialogs.ShowNoActionDialog));
                }}
            />

            <NoActionDialog title="Stop! Hammertime" open={selectors.createVisibleSelector(KnownDialogs.ShowNoActionDialog)(state)} onCloseClick={() => dispatch(actions.close(KnownDialogs.ShowNoActionDialog))}>
                Sorry some sort of precondition has not been met to continue with this action.
            </NoActionDialog>
        </React.Fragment>
    );
};

const DialogsInlineEntry = () => {
    return (
        <StyleGuideEntry
            heading="Dialogs - Inline"
            context={
                <div>
                    Inline dialogs are used when none of the criteria for standard dialogs are met. The dialog takes its contents completely from the parent's state and does not mutate any state directly. If an action is to be taken, the parent
                    registers a callback.
                    <span>
                        The dialog is opened via the state on the parent and the contents of the dialog are set inside the parent's render method. See <code>ConfigureLetsEncrypt</code> for some examples of these dialogs.
                    </span>
                </div>
            }
        >
            <DialogInlineExample />
        </StyleGuideEntry>
    );
};

export default DialogsInlineEntry;
