/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-imports */

import * as React from "react";
import ActionButton, { ActionButtonType, IconPosition } from "components/Button/ActionButton";
import { PopoverProps, Origin } from "components/Popover";
import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "theme";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem, { MenuItemProps as MuiMenuItemProps } from "@material-ui/core/MenuItem";
import callAll from "utils/callAll";
import Navigate from "components/Navigation/Navigate";
import { useOctopusTheme } from "components/Theme";
import { SpaceAwareNavigation } from "../Navigation/SpaceAwareNavigation/SpaceAwareNavigation";

interface DropdownButtonProps {
    className?: string;
    open: boolean;
    onClose: () => void;
    label: string;
    icon?: React.ReactElement<any>;
    caretStyle?: React.CSSProperties;
    type?: DropdownButtonType;
    children: (props: RenderMenuProps) => React.ReactNode;
    onClick: React.MouseEventHandler;
}

type MenuItemProps = Pick<MuiMenuItemProps, "classes" | "onClick">;

interface RenderMenuProps {
    open: boolean;
    history: SpaceAwareNavigation;
    getLinkClass: () => string;
    getMenuItemProps: (props?: Partial<MenuItemProps>) => MenuItemProps;
    getMenuProps: (props?: Partial<MenuProps>) => MenuProps;
}

export const DropdownButtonMenu = Menu;
type DropdownButtonType = ActionButtonType;

export const DropdownButtonMenuItem = MenuItem;

interface ActionPopoverProps extends PopoverProps {
    type: ActionButtonType;
}

type ActionButtonTypeProp = Pick<ActionPopoverProps, "type">;

const useLinkStyles = makeStyles((theme: Theme) => ({
    root: (props: ActionButtonTypeProp) => ({
        textDecoration: "none",
        color: "inherit",
        "&:hover": {
            color: "inherit",
        },
        "&:visited": {
            color: "inherit",
        },
    }),
}));

const getDefaultAnchorPosition = (): { transformOrigin: Origin; anchorOrigin: Origin } => {
    return {
        transformOrigin: { horizontal: "right", vertical: "top" },
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
    };
};

export const DropdownButton: React.FC<DropdownButtonProps> = React.forwardRef((props, ref) => {
    const themeContext = useOctopusTheme();

    const [anchor, setAnchor] = React.useState<any>(null);
    const linkStyles = useLinkStyles({ type: props.type! });

    const handleButtonTouchTab = (event: React.SyntheticEvent<any>) => {
        event.preventDefault();
        setAnchor(event.currentTarget);
    };

    const getMenuItemProps = (menuItemProps: MenuItemProps = {}): MenuItemProps => {
        const { onClick, ...rest } = menuItemProps;

        return {
            onClick: callAll(onClick),
            ...rest,
        };
    };

    const handleBackdropClick: React.MouseEventHandler = (e) => {
        props.onClose();
    };

    const getCaretColorForTheme = () => {
        if (props.type === ActionButtonType.Ternary) {
            return {}; // When using the ternary type, use the color provided by the actionbutton
        }

        return { color: themeContext.primaryButtonText };
    };

    const getMenuProps = ({ onClose, onBackdropClick, ...rest }: Partial<MenuProps> = {}): MenuProps => {
        return {
            open: props.open,
            onBackdropClick: callAll(handleBackdropClick, onBackdropClick),
            ...getDefaultAnchorPosition(),
            ...(anchor ? { anchorEl: anchor } : {}),
            onClose: callAll(onClose),
            getContentAnchorEl: null,
            ...rest,
        };
    };

    const getLinkClass = () => linkStyles.root;

    const { icon = <i className="fa fa-caret-down" style={props.caretStyle ?? { marginLeft: "0.3rem", ...getCaretColorForTheme() }} /> } = props;

    return (
        <Navigate
            render={(history) => (
                <React.Fragment>
                    <ActionButton innerRef={ref} type={props.type} icon={icon} iconPosition={IconPosition.Right} label={props.label} onClick={callAll(handleButtonTouchTab, props.onClick)} />
                    {props.children({
                        history,
                        open: props.open,
                        getMenuItemProps,
                        getMenuProps,
                        getLinkClass,
                    })}
                </React.Fragment>
            )}
        />
    );
});

export default DropdownButton;
