import * as React from "react";
const styles = require("./style.less");

interface IconButtonListProps {
    buttons?: JSX.Element[];
}

const IconButtonList: React.StatelessComponent<IconButtonListProps> = ({ buttons = [] }) => {
    return <div className={styles.buttonList}>{buttons.map((button, index) => (!button ? null : React.cloneElement(button, { ...button.props, key: index })))}</div>;
};

IconButtonList.displayName = "IconButtonList";

export default IconButtonList;
