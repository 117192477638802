import { Dictionary, groupBy } from "lodash";

export default function groupVariablesByName<TVariable>(variableResources: ReadonlyArray<TVariable>, getName: (variable: TVariable) => string): Dictionary<TVariable[]> {
    const variablesByNormalizedName = groupBy([...variableResources], (v) => getName(v).toLowerCase());
    return Object.keys(variablesByNormalizedName)
        .map((normalizedName) => {
            const variables = variablesByNormalizedName[normalizedName];
            // This allows us to preserve original name for variables with single value
            const name = getName(variables[0]);

            return { name, variables };
        })
        .reduce((dictionary, groupedVariables) => ({ ...dictionary, [groupedVariables.name]: groupedVariables.variables }), {});
}
