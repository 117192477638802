/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable no-restricted-imports */

import { PaletteType, Theme as MuiTheme } from "@material-ui/core";
import { PaletteOptions as MuiPaletteOptions, Palette as MuiPalette } from "@material-ui/core/styles/createPalette";
import muiCreateMuiTheme, { ThemeOptions as MuiThemeOptions } from "@material-ui/core/styles/createMuiTheme";
import * as base from "./base";
import { createLegacyMuiTheme } from "./legacy";
import { useTheme as useMuiTheme } from "@material-ui/core/styles";
import { OctopusTheme } from "components/Theme";

interface StatusColors {
    danger: string;
    info: string;
}

interface ExtraPaletteOptions {
    status: StatusColors;
}

export type OctopusPaletteOptions = ExtraPaletteOptions & MuiPaletteOptions;

const baseColors = {
    primary: base.octopusBlue,
    secondary: {
        main: base.octopusBlue["500"],
        dark: base.octopusBlue["700"],
        light: base.octopusBlue["A200"],
        contrastText: base.white,
    },
};

function createLightPalette(theme: OctopusTheme): OctopusPaletteOptions {
    return {
        ...baseColors,
        type: "light",
        text: {
            // The most important text.
            primary: theme.primaryText, //This controls the remove icon on some chips
            // Secondary text.
            secondary: theme.secondaryText,
            // Disabled text have even lower visual prominence.
            disabled: theme.disabledButtonText,
            // Text hints.
            hint: theme.ternaryText,
        },
        background: {
            paper: theme.paper1,
            default: theme.paper1,
        },
        divider: theme.divider,
        // The colors used to style the action elements.
        action: {
            // The color of an active action like an icon button.
            active: theme.primaryText,
            // The color of an hovered action.
            hover: theme.hover,
            hoverOpacity: 0.08,
            // The color of a selected action.
            selected: theme.hover,
            // The color of a disabled action.
            disabled: theme.disabledButtonText,
            // The background color of a disabled action.
            disabledBackground: theme.disabledBackground,
        },
        error: {
            main: theme.danger,
        },
        status: {
            danger: theme.alert,
            info: theme.info,
        },
    };
}

function createDarkPalette(theme: OctopusTheme): OctopusPaletteOptions {
    return {
        type: "dark",
        ...baseColors,
        background: {
            paper: theme.paper1,
            default: theme.paper1,
        },
        text: {
            // The most important text.
            primary: theme.primaryText, //This controls the remove icon on some chips
            // Secondary text.
            secondary: theme.secondaryText,
            // Disabled text have even lower visual prominence.
            disabled: theme.disabledButtonText,
            // Text hints.
            hint: theme.ternaryText,
        },
        divider: theme.divider,
        error: {
            main: theme.danger,
        },
        status: {
            danger: theme.alert,
            info: theme.info,
        },
    };
}

export const zIndexDialogs = 900;
export const zIndexPopovers = 800;
export const zIndexStickies = 10;

function createMuiTheme(type: PaletteType, octopusTheme: OctopusTheme): MuiTheme {
    const palette = type === "dark" ? createDarkPalette(octopusTheme) : createLightPalette(octopusTheme);

    const options = {
        palette,
        typography: {
            useNextVariants: true,
        },
    };

    return muiCreateMuiTheme(options as MuiThemeOptions) as Theme;
}

type ThemePaletteType = PaletteType;

interface OctopusPalette extends MuiPalette {
    status: StatusColors;
}
export interface Theme extends MuiTheme {
    palette: OctopusPalette;
}

export const useTheme = () => {
    return useMuiTheme<Theme>();
};

export default createMuiTheme;
export { createMuiTheme, ThemePaletteType, createLegacyMuiTheme };
