import React = require("react");
import { DefaultTopics } from "./DefaultViews";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";

export const CommonTaskOverview: React.StatelessComponent<{}> = (props) => {
    return (
        <OverviewContainer>
            <OverviewHighlightText>
                <p>
                    The <b>tasks</b> section is where you can check on the status and history of the background tasks your Octopus Server is managing.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>
    );
};

export const CommonTaskTopics: React.StatelessComponent<{}> = (props) => {
    return <DefaultTopics />;
};
