import releasePageIds from "../Releases/ReleasesRoutes/releasePageIds";
import { pageId } from "utils/pageId";
import runbookSnapshotPageIds from "../Runbooks/RunbookSnapshots/runbookSnapshotPageIds";
import projectRouteLinks from "./projectRouteLinks";
import { client } from "clientInstance";
import Permission from "client/resources/permission";

const projectLinks = projectRouteLinks(client.spaceId ?? "");

export default () => ({
    projects: {
        root: pageId({ area: "Project", id: "Projects", name: "Projects", routeLink: projectLinks.projects.root, routePermission: { permission: Permission.ProjectView, wildcard: true } }),
    },
    importExport: {
        root: pageId({ area: "Project", id: "ImportExportTasks", name: "Import/Export", routeLink: projectLinks.importExport.root, routePermission: { permission: Permission.ProjectView, wildcard: true } }),
        export: pageId({ area: "Project", id: "ExportProjects", name: "Export", routeLink: projectLinks.import.root, routePermission: { permission: Permission.ProjectView, wildcard: true } }),
        exportTask: pageId({ area: "Project", id: "ExportTask", name: "Export Task", routeLink: null }),
        import: pageId({ area: "Project", id: "ImportProjects", name: "Import", routeLink: projectLinks.export.root, routePermission: { permission: Permission.ProjectView, wildcard: true } }),
        importTask: pageId({ area: "Project", id: "ImportTask", name: "Import Task", routeLink: null }),
    },
    project: () => {
        return {
            root: pageId({ area: "Project", id: "Project", name: "Project", routeLink: null }),
            ...releasePageIds(),
            overview: pageId({ area: "Project", id: "Project.Overview", name: "Overview", routeLink: null }),
            tasks: pageId({ area: "Project", id: "Project.Tasks", name: "Tasks", routeLink: null }),
            settings: {
                root: pageId({ area: "Project", id: "Project.Settings", name: "Settings", routeLink: null }),
                versionControl: pageId({ area: "Project", id: "Project.VersionControlSettings", name: "Version Control Settings", routeLink: null }),
            },
            channels: pageId({ area: "Project", id: "Project.Channels", name: "Channels", routeLink: null }),
            channel: pageId({ area: "Project", id: "Project.Channel", name: "Channel", routeLink: null }),
            channelNew: pageId({ area: "Project", id: "Project.ChannelNew", name: "Add New Channels", routeLink: null }),
            triggers: pageId({ area: "Project", id: "Project.Triggers", name: "Triggers", routeLink: null }),
            deploymentSettings: pageId({ area: "Project", id: "Project.DeploymentSettings", name: "Settings", routeLink: null }),
            deploymentTargetTrigger: pageId({ area: "Project", id: "Project.DeploymentTargetTrigger", name: "Deployment Target Trigger", routeLink: null }),
            deploymentTargetTriggerNew: pageId({ area: "Project", id: "Project.DeploymentTargetTriggerNew", name: "Add New Deployment Target Trigger", routeLink: null }),
            scheduledTrigger: pageId({ area: "Project", id: "Project.ScheduledTrigger", name: "Scheduled Trigger", routeLink: null }),
            scheduledTriggerNew: pageId({ area: "Project", id: "Project.ScheduledTriggerNew", name: "Add New Scheduled Trigger", routeLink: null }),
            variables: {
                root: pageId({ area: "Project", id: "Project.Variables", name: "Variables", routeLink: null }),
                projectTemplates: pageId({ area: "Project", id: "Project.VariableTemplates", name: "Variable Templates", routeLink: null }),
                commonTemplates: pageId({ area: "Project", id: "Project.VariableCommonTemplates", name: "Common Variable Templates", routeLink: null }),
                library: pageId({ area: "Project", id: "Project.VariableLibrary", name: "Variables Library", routeLink: null }),
                preview: pageId({ area: "Project", id: "Project.VariablePreview", name: "Variables Preview", routeLink: null }),
                all: pageId({ area: "Project", id: "Project.VariableAll", name: "All Variables", routeLink: null }),
            },
            process: {
                root: pageId({ area: "Project", id: "Project.Process", name: "Deployment Process", routeLink: null }),
                overview: pageId({ area: "Project", id: "Project.Process", name: "Deployment Overview", routeLink: null }),
                stepTemplates: pageId({ area: "Project", id: "Project.ProcessStepTemplates", name: "Deployment Process Step Templates", routeLink: null }),
                childStepTemplates: pageId({ area: "Project", id: "Project.ProcessChildStepTemplates", name: "Deployment Process Child Step Templates", routeLink: null }),
                stepNew: pageId({ area: "Project", id: "Project.ProcessStepNew", name: "Add New Deployment Process Step", routeLink: null }),
                step: pageId({ area: "Project", id: "Project.ProcessStep", name: "Deployment Process Step", routeLink: null }),
            },
            childStepTemplates: pageId({ area: "Project", id: "Project.ChildStepTemplates", name: "Child Step Templates", routeLink: null }),
            steptemplates: pageId({ area: "Project", id: "Project.StepTemplates", name: "Step Templates", routeLink: null }),
            operations: {
                root: pageId({ area: "Project", id: "Project.Operations", name: "Operations", routeLink: null }),
                runbooks: pageId({ area: "Project", id: "Project.Operations.Runbooks", name: "Runbooks", routeLink: null }),
                triggers: pageId({ area: "Project", id: "Project.Operations.Triggers", name: "Runbook Triggers", routeLink: null }),
                scheduledTrigger: pageId({ area: "Project", id: "Project.Operations.Triggers.ScheduledTrigger", name: "Runbook Scheduled Trigger", routeLink: null }),
                scheduledTriggerNew: pageId({ area: "Project", id: "Project.Operations.Triggers.ScheduledTriggerNew", name: "Add New Runbook Scheduled Trigger", routeLink: null }),
            },
            runbook: {
                root: pageId({ area: "Project", id: "Project.Runbook.Overview", name: "Runbook Overview", routeLink: null }),
                runsList: pageId({ area: "Project", id: "Project.Runbook.RunsList", name: "Runbook Runs List", routeLink: null }),
                process: {
                    root: pageId({ area: "Project", id: "Project.Runbook.Process", name: "Runbook Process", routeLink: null }),
                    stepTemplates: pageId({ area: "Project", id: "Project.Runbook.ProcessStepTemplates", name: "Runbook Process Step Templates", routeLink: null }),
                    childStepTemplates: pageId({ area: "Project", id: "Project.Runbook.ProcessChildStepTemplates", name: "Runbook Process Child Step Templates", routeLink: null }),
                    stepNew: pageId({ area: "Project", id: "Project.Runbook.ProcessStepNew", name: "Add New Runbook Process Step", routeLink: null }),
                    step: pageId({ area: "Project", id: "Project.Runbook.ProcessStep", name: "Runbook Process Step", routeLink: null }),
                },
                runNow: pageId({ area: "Project", id: "Project.Runbook.Run", name: "Runbook Run", routeLink: null }),
                settings: pageId({ area: "Project", id: "Project.Runbook.Settings", name: "Runbook Settings", routeLink: null }),
                ...runbookSnapshotPageIds(),
                triggers: pageId({ area: "Project", id: "Project.Runbook.Triggers", name: "Runbook Triggers", routeLink: null }),
                scheduledTrigger: pageId({ area: "Project", id: "Project.Runbook.ScheduledTrigger", name: "Runbook Scheduled Trigger", routeLink: null }),
                scheduledTriggerNew: pageId({ area: "Project", id: "Project.Runbook.ScheduledTriggerNew", name: "Add New Runbook Scheduled Trigger", routeLink: null }),
            },
        };
    },
});
