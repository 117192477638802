import * as React from "react";
import InternalLink from "components/Navigation/InternalLink";
import { ActionTemplateUsageResource, ProcessType } from "client/resources";
import routeLinks from "routeLinks";
import { exhaustiveCheck } from "utils/exhaustiveCheck";

export const ActionTemplateUsageProcessLink: React.FC<{ usage: ActionTemplateUsageResource }> = ({ usage }) => {
    switch (usage.ProcessType) {
        case ProcessType.Runbook:
            return <InternalLink to={routeLinks.project(usage.ProjectSlug).operations.runbook(usage.RunbookId).runbookProcess.runbookProcess(usage.ProcessId).process.root}>{`Runbook: ${usage.RunbookName}`}</InternalLink>;
        case ProcessType.Deployment:
            return <InternalLink to={routeLinks.project(usage.ProjectSlug).deployments.process.root}>{usage.ProcessType}</InternalLink>;
    }

    exhaustiveCheck(usage.ProcessType, "Not all process types have been handled.");
};

export const ActionTemplateUsageStepLink: React.FC<{ usage: ActionTemplateUsageResource }> = ({ usage }) => (
    <InternalLink
        to={
            usage.ProcessType === ProcessType.Deployment
                ? routeLinks.project(usage.ProjectSlug).deployments.process.step(usage.ActionId)
                : routeLinks.project(usage.ProjectSlug).operations.runbook(usage.RunbookId).runbookProcess.runbookProcess(usage.ProcessId).process.step(usage.ActionId)
        }
        openInSelf={false}
    >
        {usage.ActionName}
    </InternalLink>
);
