import React = require("react");
import ExternalLink from "components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
import { TopicsContainer, LearnMoreResources } from "./CommonViews";

export const CommonConfigurationSubscriptionOverview: React.StatelessComponent<{}> = (props) => {
    return (
        <OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="Subscriptions">Subscriptions</ExternalLink> allow you to subscribe to events that are happening within Octopus, so you can be notified when events have occurred and react accordingly.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>
    );
};

export const CommonConfigurationSubscriptionTopics: React.StatelessComponent<{}> = (props) => {
    return (
        <TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="Subscriptions">Subscriptions</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>
    );
};
