import * as React from "react";
import { Popper, PopperProps } from "@material-ui/core";
import { PropsWithChildren } from "react";

//eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SimplePopperProps extends PopperProps {}

const SimplePopper: React.FC<PropsWithChildren<SimplePopperProps>> = ({ children, ...props }) => {
    return (
        <Popper {...props}>
            <>{children}</>
        </Popper>
    );
};

export default SimplePopper;
