/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import BusyIndicator from "components/BusyIndicator/BusyIndicator";

type PossiblyLoadedComponent = React.ComponentType | "not yet loaded" | "load failed";

interface AsyncComponentProps {
    component: PossiblyLoadedComponent;
}

//https://medium.com/@apostolos/server-side-rendering-code-splitting-and-hot-reloading-with-react-router-v4-87239cfc172c
function importAsync(getArea: () => Promise<any>, getComponent = (m: any) => m.default, deferLoading = false) {
    const asyncComponent = class AsyncComponent extends React.Component<{}, AsyncComponentProps> {
        static Component: PossiblyLoadedComponent = "not yet loaded";

        static async loadInBackground() {
            await AsyncComponent.load();
        }

        static componentAccessorToString(accessor: any) {
            return accessor.toString().replace("function", "");
        }

        static async load() {
            if (AsyncComponent.Component !== "not yet loaded") {
                return;
            }

            try {
                console.log(`Loading dynamic chunk: ${AsyncComponent.componentAccessorToString(getComponent)}`);
                const area = await getArea();
                const component = getComponent(area);
                if (!component) {
                    AsyncComponent.handleError(new Error(`Imported the ${AsyncComponent.componentAccessorToString(getComponent)} module, but failed to find the expected component on the module.`));
                } else {
                    AsyncComponent.Component = component;
                    console.log(`Loaded dynamic chunk: ${AsyncComponent.componentAccessorToString(getComponent)}`);
                }
            } catch (error) {
                AsyncComponent.Component = "load failed";
                AsyncComponent.handleError(error);
            }
        }

        private static handleError(error: any) {
            console.log(`Failed to load a dynamic chunk: ${AsyncComponent.componentAccessorToString(getComponent)}`);
            console.error(error);
            console.log("Reloading application...");
            location.reload(true);
        }

        mounted = false;

        state = {
            component: AsyncComponent.Component,
        };

        async componentDidMount() {
            this.mounted = true;
            if (this.state.component === "not yet loaded") {
                if (AsyncComponent.Component === "not yet loaded") {
                    await AsyncComponent.load();
                }

                if (this.mounted) {
                    this.setState({ component: AsyncComponent.Component });
                }
            }
        }

        componentWillUnmount() {
            this.mounted = false;
        }

        render() {
            const { component: Component } = this.state;
            if (Component === "not yet loaded") {
                return <BusyIndicator show={true} />;
            }
            if (Component === "load failed") {
                throw new Error(`The dynamic component from ${AsyncComponent.componentAccessorToString(getComponent)} could not be loaded.`);
            }
            return <Component {...this.props} />;
        }
    };

    if (!deferLoading) {
        // eslint-disable-next-line: no-floating-promises
        asyncComponent.loadInBackground();
    }

    return asyncComponent;
}

export default importAsync;
