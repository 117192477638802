import { PermissionCheckProps } from "components/PermissionCheck/PermissionCheck";

interface PageIdOptions {
    area: string;
    id: string;
    name: string | null;
    routeLink: string | (() => string) | null;
    routePermission?: PermissionCheckProps;
}

export function pageId(options: PageIdOptions): IPageWrapper {
    const { area, id, name, routeLink, routePermission } = options;
    return {
        Area: area,
        Id: id,
        Name: name ? name : id,
        RouteLink: routeLink,
        RoutePermission: routePermission,
    };
}

// This helps us identify each page in a human-friendly way for our reporting/customer-feedback tools.
export default interface IPageWrapper {
    Area: string; // Name describing the area of this page. E.g. Library.
    Id: string; // Short ID representing a given page. E.g. LibraryTenantTagSetCreate"
    Name: string; // Human-readable name describing the page. E.g. "Add Tenant Tag Set"
    RouteLink?: string | (() => string) | null; // The internal routelink to navigate to this page.
    RoutePermission?: PermissionCheckProps;
}
