import * as React from "react";
import { useReducer, useState } from "react";
import StyleGuideEntry from "../StyleGuideEntry";
import ActionButton from "components/Button";
import ConfirmationDialog from "components/Dialog/ConfirmationDialog";
import { sleep1s } from "../helpers/sleep";
import CustomDialog from "components/Dialog/CustomDialog";
import { CustomInfoDialogLayout, SmallDialogFrame, MediumDialogFrame, LargeDialogFrame, FullScreenDialogFrame } from "components/DialogLayout/Custom";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import RadioButton from "primitiveComponents/form/RadioButton/RadioButton";
import RadioButtonGroup from "primitiveComponents/form/RadioButton/RadioButtonGroup";
import { LongLoremText, ShortLoremText } from "../helpers/Lorem";
import CopyValueToClipboard from "components/CopyToClipboardButton/CopyValueToClipboard";
import { createVisibilityReducer } from "utils/Reducers/visibilityReducer";
import { identity } from "lodash";
import { DialogWithForm } from "./DialogWithForm";

const { reducer, actions, getSelectors } = createVisibilityReducer("dialog");
type DialogState = ReturnType<typeof reducer>;
const selectors = getSelectors<DialogState>(identity);
const INITIAL_STATE: DialogState = {};

const DialogsContentTestsEntry = () => {
    return (
        <StyleGuideEntry
            heading="Dialog Layouts - Content Tests"
            context={
                <div>
                    It is important to test the dialogs scale in terms of content, that <strong>headers and footer/actions remain visible</strong> and and that <strong>overflow/scrollbars appear when necessary</strong>. Dialogs should scale with
                    overflow/scrollbars for both short and long content. The following actions can be tested in various browsers to ensure compatibility.
                </div>
            }
        >
            <DialogsContentTestsExample />
        </StyleGuideEntry>
    );
};

enum CustomDialogFrameSize {
    Small = "Small",
    Medium = "Medium",
    Large = "Large",
    FullScreen = "FullScreen",
}

function assertUnreachable(x: never): never {
    throw new Error("Not all cases have been handled.");
}

const getCustomDialogSize = (size: CustomDialogFrameSize) => {
    switch (size) {
        case CustomDialogFrameSize.Small:
            return SmallDialogFrame;
        case CustomDialogFrameSize.Medium:
            return MediumDialogFrame;
        case CustomDialogFrameSize.Large:
            return LargeDialogFrame;
        case CustomDialogFrameSize.FullScreen:
            return FullScreenDialogFrame;
    }

    assertUnreachable(size);
};

const DialogsContentTestsExample: React.FC = () => {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const [customDialogFrame, setCustomDialogFrame] = useState(CustomDialogFrameSize.Small);
    const [showDialogLongContent, setShowDialogLongContent] = useState(false);

    enum KnownDialogs {
        ConfirmationDialog = "ConfirmationDialog",
    }

    return (
        <React.Fragment>
            <h4>Regular Dialogs</h4>
            <div>
                <ActionButton label="Show Regular Content Dialog" onClick={() => dispatch(actions.open(KnownDialogs.ConfirmationDialog))} />
                <ConfirmationDialog
                    title="Some Heading"
                    continueButtonLabel="Continue"
                    continueButtonBusyLabel="Saving..."
                    open={selectors.createVisibleSelector(KnownDialogs.ConfirmationDialog)(state)}
                    onClose={() => dispatch(actions.close(KnownDialogs.ConfirmationDialog))}
                    onContinueClick={() => sleep1s()}
                >
                    <CopyValueToClipboard value={"Regular Dialog Copied Content"} />
                    <ActionButton label="Toggle Content Size" onClick={() => setShowDialogLongContent(!showDialogLongContent)} />
                    {showDialogLongContent ? <LongLoremText /> : <ShortLoremText />}
                </ConfirmationDialog>
            </div>

            <h4>Custom Dialogs (used by the variable editor, code editors etc.)</h4>
            <div>
                <OpenDialogButton
                    label={"Show Custom Content Dialog"}
                    wideDialog={true}
                    renderDialog={(renderProps) => (
                        <CustomDialog
                            open={renderProps.open}
                            close={renderProps.closeDialog}
                            render={(dialogProps) => (
                                <CustomInfoDialogLayout {...dialogProps} title={"Some Heading"} frame={getCustomDialogSize(customDialogFrame)} additionalActions={<CopyValueToClipboard value={"Custom Dialog Copied Content"} />}>
                                    <div>
                                        <ActionButton label="Toggle Content Size" onClick={() => setShowDialogLongContent(!showDialogLongContent)} />
                                    </div>
                                    <div>
                                        <RadioButtonGroup label="Frame Size" value={customDialogFrame} onChange={(customDialogFrameSize: CustomDialogFrameSize) => setCustomDialogFrame(customDialogFrameSize)} autoFocus>
                                            <RadioButton value={CustomDialogFrameSize.Small} label="Small Size" />
                                            <RadioButton value={CustomDialogFrameSize.Medium} label="Medium Size" />
                                            <RadioButton value={CustomDialogFrameSize.Large} label="Large Size" />
                                            <RadioButton value={CustomDialogFrameSize.FullScreen} label="Full Screen" />
                                        </RadioButtonGroup>
                                    </div>
                                    {showDialogLongContent ? <LongLoremText /> : <ShortLoremText />}
                                </CustomInfoDialogLayout>
                            )}
                        />
                    )}
                />
            </div>

            <h4>Custom Dialog with Expandable Form Section and Select</h4>
            <DialogWithForm />
        </React.Fragment>
    );
};

export default DialogsContentTestsEntry;
